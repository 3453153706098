import moment from 'moment';
import { compress } from './JIC';


export function ConvertIsoDate(date) {
    if (!date) return null;
    if (typeof date === 'date')
        return date.toISOString().substring(0, 10);
    else return date.substring(0, 10);
}

export async function getBase64(name) {
    var img = document.getElementById(name)
    var mime = document.getElementById('actual-btn')?.files[0].type
    if (mime) mime = mime.split('/')[1]
    console.log('mime', mime)
    var placeholder = img.src.includes('profile-has-confident-expression-wears-casual-white-t-shirt');
    if (img && !placeholder) {
        var base64 = await compress(img, 30, mime || "jpg")
        return base64;
    } else {
        console.log('Masih Placeholder')
        return null;
    }
}
function currency(text) {
    return 'Rp. ' + parseInt(text.toFixed(0)).toLocaleString();
}

export function reportLawyer(datas) {
    const xl = require("excel4node");
    var wb = new xl.Workbook();
    var ws = wb.addWorksheet('Sheet 1');
    var style = wb.createStyle({
        alignment: { horizontal: 'left' },
        font: {
            size: 12,
        },
        // numberFormat: '#,##0; (Rp. #,##); -',
    });
    var header = wb.createStyle({ alignment: { horizontal: 'center' }, font: { bold: true } });
    var row = 1;
    ws.cell(row, 1, row, 7, true).string('Laporan Lawyer Tgl ' + moment(new Date()).format('LLL')).style(header);
    row = 2;
    var cell = 0;
    ws.cell(row, ++cell).string('Nama').style(header);
    ws.cell(row, ++cell).string('Email').style(header);
    ws.cell(row, ++cell).string('Peradi Number').style(header);
    ws.cell(row, ++cell).string('Phone').style(header);
    ws.cell(row, ++cell).string('Harga').style(header);
    ws.cell(row, ++cell).string('Saldo').style(header);
    ws.cell(row, ++cell).string('Tanggal Register').style(header);
    ws.cell(row, ++cell).string('Rating').style(header);
    var start = 3;
    datas.forEach((element, index) => {
        var cell = 0;
        ws.cell(index + start, ++cell).string(element.name);
        ws.cell(index + start, ++cell).string(element.email);
        ws.cell(index + start, ++cell).string(element.nia);
        ws.cell(index + start, ++cell).string(element.phone);
        ws.cell(index + start, ++cell).string(currency(element.price)).style(style);
        ws.cell(index + start, ++cell).string(currency(element.saldo)).style(style);
        ws.cell(index + start, ++cell).date(new Date(element.createdAt)).style({ numberFormat: 'dd-mm-yyyy', alignment: { horizontal: 'center' } });
        ws.cell(index + start, ++cell).number(element.rating);
    });
    wb.writeToBuffer().then((buffer) => {
        downloadFile(buffer, 'Report_Lawyer.xlsx')
    });
}
const borderThin = {
    border: {
        left: {
            style: 'thin',
            color: 'black',
        },
        right: {
            style: 'thin',
            color: 'black',
        },
        top: {
            style: 'thin',
            color: 'black',
        },
        bottom: {
            style: 'thin',
            color: 'black',
        },
    }
}
const disc = (data)=>{
    if(!data.promo) return 0
    var disc = 0;
    if(data.promo.type=='fixed'){
        // var price = data.price 
        if(data.price < data.promo.value) disc = data.price -1
        else disc = data.promo.value;
    }else{
        disc = data.price * data.promo.value /100;
        if(disc > data.promo.maxValue) disc = data.promo.maxValue
    }
    return (disc)
}
const finalPrice = (data)=>{
    if(!data.promo) return data.price
    var disc = 0;
    if(data.promo.type=='fixed'){
        // var price = data.price 
        if(data.price < data.promo.value) disc = data.price -1
        else disc = data.promo.value;
    }else{
        disc = data.price * data.promo.value /100;
        if(disc > data.promo.maxValue) disc = data.promo.maxValue
    }
    return (data.price - disc)
}

export function reportTransaksi(datas,param) {
    const xl = require("excel4node");
    var wb = new xl.Workbook();
    var total = 0;
    var totalNominal = 0;
    var totalDisc = 0;
    var lawyer = 0;
    var ws = wb.addWorksheet('Sheet 1');
    var style = wb.createStyle({
        alignment: { horizontal: 'left' },
        font: {
            size: 12,
        },
        // numberFormat: '#,##0; (Rp. #,##); -',
    });
    var header = wb.createStyle({ alignment: { horizontal: 'center' }, font: { bold: true } });
    var row = 1;
    ws.cell(row, 1, row, 7, true).string('Laporan Transaksi Tgl ' + moment(new Date(param.date.start)).format('L')+' Sampai '+moment(new Date(param.date.end)).format('L')).style(header);
    row = 3;
    var cell = 0;
    ws.cell(row, ++cell).string('Invoice').style(header);
    ws.cell(row, ++cell).string('Payment').style(header);
    ws.cell(row, ++cell).string('Nominal').style(header);
    ws.cell(row, ++cell).string('Disc').style(header);
    ws.cell(row, ++cell).string('Total').style(header);
    ws.cell(row, ++cell).string('Type').style(header);
    ws.cell(row, ++cell).string('Validasi By').style(header);
    ws.cell(row, ++cell).string('Divider').style(header);
    ws.cell(row, ++cell).string('Lawyer').style(header);
    ws.cell(row, ++cell).string('User').style(header);
    ws.cell(row, ++cell).string('Payment Date').style(header);
    var start = 4;
    datas.forEach((element, index) => {
        var cell = 0;
        if(element.status=='success'){
            var keuntungan = element.price - (element.price * element.currentDivider / 100)
            total += keuntungan;
            totalDisc += disc(element);
            totalNominal += finalPrice(element)
            lawyer +=(element.price * element.currentDivider / 100);
        }
        ws.cell(index + start, ++cell).string(element.invoice);
        ws.cell(index + start, ++cell).string(element.payment_type.name);
        ws.cell(index + start, ++cell).string(currency(element.price));
        ws.cell(index + start, ++cell).string(currency(disc(element)));
        ws.cell(index + start, ++cell).string(currency(finalPrice(element)));
        ws.cell(index + start, ++cell).string(element.midtrans ? 'Midtrans' : 'Xendit');
        ws.cell(index + start, ++cell).string(element.validateBy||'');
        ws.cell(index + start, ++cell).string(element.currentDivider + '%');
        ws.cell(index + start, ++cell).string(element.id_lawyer?.email||element.id_user?.phone||'Unknow');
        ws.cell(index + start, ++cell).string(element.id_user?.email||element.id_user?.phone||'Unknow');
        ws.cell(index + start, ++cell).date(new Date(element.payment_date)).style({ numberFormat: 'dd-mm-yyyy', alignment: { horizontal: 'center' } });
        ws.cell(index + start, 1, index + start, cell).style(borderThin)
        if (element.status == 'failed') {
            ws.cell(index + start, 1, index + start, cell).style({font:{color:'white'}, fill: { type: 'pattern', patternType: 'solid', bgColor: '#eb4034', fgColor: "#eb4034" } })
        }else if(element.status == 'pending'){
            ws.cell(index + start, 1, index + start, cell).style({font:{color:'white'}, fill: { type: 'pattern', patternType: 'solid', bgColor: '#f79c25', fgColor: "#f79c25" } })
        }
    });
    ws.cell(2, 1, 2, 7, true).string(
        'Total Transaksi : '+datas.length+'\n'+
        'Total Nominal Transaksi : Rp. '+currency(totalNominal)+'\n'+
        'Total Pemasukan : Rp. '+currency(total)+'\n'+
        'Total Pemasukan Lawyer : Rp. '+currency(lawyer)+'\n'+
        'Total Diskon : Rp. '+currency(totalDisc)
        // 'Penghasilan Bersih : Rp. '+currency(total-totalDisc)
        ).style(header);
    wb.writeToBuffer().then((buffer) => {
        downloadFile(buffer, 'Report_Transaksi.xlsx')
    });
}

export function reportSettlement(datas,param) {
    const xl = require("excel4node");
    var wb = new xl.Workbook();
    var total = 0;
    var totalNominal = 0;
    var totalDisc = 0;
    var lawyer = 0;
    var ws = wb.addWorksheet('Sheet 1');
    var style = wb.createStyle({
        alignment: { horizontal: 'left' },
        font: {
            size: 12,
        },
        // numberFormat: '#,##0; (Rp. #,##); -',
    });
    var header = wb.createStyle({ alignment: { horizontal: 'center' }, font: { bold: true } });
    var row = 1;
    ws.cell(row, 1, row, 7, true).string('Laporan Withdrawal Lawyer Tgl ' + moment(new Date(param.date.start)).format('L')+' Sampai '+moment(new Date(param.date.end)).format('L')).style(header);
    row = 3;
    var cell = 0;
    ws.cell(row, ++cell).string('Request Date').style(header);
    ws.cell(row, ++cell).string('Name').style(header);
    ws.cell(row, ++cell).string('Email').style(header);
    ws.cell(row, ++cell).string('Phone').style(header);
    ws.cell(row, ++cell).string('Nominal').style(header);
    ws.cell(row, ++cell).string('Rekening').style(header);
    ws.cell(row, ++cell).string('Approve By').style(header);
    ws.cell(row, ++cell).string('Dari Rekening').style(header);
    ws.cell(row, ++cell).string('No Referensi').style(header);
    var start = 4;
    datas.forEach((element, index) => {
        var cell = 0;
        ws.cell(index + start, ++cell).date(new Date(element.createdAt)).style({ numberFormat: 'dd-mm-yyyy', alignment: { horizontal: 'center' } });
        ws.cell(index + start, ++cell).string(element.lawyerName);
        ws.cell(index + start, ++cell).string(element.lawyer.email);
        ws.cell(index + start, ++cell).string(element.lawyer.phone);
        ws.cell(index + start, ++cell).string(currency(element.nominal));
        ws.cell(index + start, ++cell).string(element.rekening.bank_name+' - '+element.rekening.account_number+' ( '+element.rekening.account_name+' )');
        ws.cell(index + start, ++cell).string(element.approveBy?.email||'');
        ws.cell(index + start, ++cell).string(element.fromRekening && element.fromRekening.bank_name + ' - '+element.fromRekening.account_number+' ( '+element.fromRekening.account_name+' )');
        ws.cell(index + start, ++cell).string(element.struck||'-');
        ws.cell(index + start, 1, index + start, cell).style(borderThin)
        if (element.status == 'decline') {
            ws.cell(index + start, 1, index + start, cell).style({font:{color:'white'}, fill: { type: 'pattern', patternType: 'solid', bgColor: '#eb4034', fgColor: "#eb4034" } })
        }else if(element.status == 'waiting'){
            ws.cell(index + start, 1, index + start, cell).style({font:{color:'white'}, fill: { type: 'pattern', patternType: 'solid', bgColor: '#f79c25', fgColor: "#f79c25" } })
        }
    });
    wb.writeToBuffer().then((buffer) => {
        downloadFile(buffer, 'Report_Withdrawal.xlsx')
    });
}
function downloadFile(buffer, filename) {
    console.log('Download File')
    const binaryBuffer = Buffer.from(buffer);
    var strBase64 = Buffer.from(binaryBuffer).toString('base64');
    const linkSource = `data:application/xlsx;base64,${strBase64}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = filename;
    downloadLink.click();
    downloadLink.remove()
}