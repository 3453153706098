import { useState, useEffect } from 'react'
import { setSelectValue, loadScript, emit, validation, debounce, showNotify } from '../context/socket'
import Pagination from './Pagination';
import Switch from './Switch';
import { Link } from "react-router-dom";
import AHrefJavascript from './AHrefJavascript';
import moment from 'moment';
import SVG from './SVG';
import $ from 'jquery';
const TransaksiMetode = ({ setPath }) => {
    const [docsCounts, setDocsCounts] = useState(0)
    const [metode, setMetode] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState({
        number:0,
        time:new Date().getTime()
    })
    const [text, setText] = useState('')
    const [refresh, setRefresh] = useState(true)
    useEffect(() => {
        const getUser = async () => {
            var reqData = {
                'text': text,
                'page': currentPage.number,
                'size': pageSize,
            }
            emit('get_payment_method_admin', reqData, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setMetode(response.data.docs)
                setDocsCounts(response.data.counts)
            });
        }
        if(currentPage.number!=0)
        getUser()
    }, [currentPage])
    useEffect(() => {
        setCurrentPage({number:1})
    }, [pageSize, text, refresh])
    useEffect(() => {
        setPath({title:'Metode Transaksi',path:'/transactions/metode'});
        loadScript();
    }, [])
    const toggle = (data, option) => {
        console.log(data)
        if (option)
            data.status = option.status == 'active' ? 'inactive' : 'active'
        else
            data.status = data.status == 'active' ? 'inactive' : 'active'
        var reqData = {
            idPayment: data._id,
            status: data.status,
            idOption: option?._id,
        }
        console.log(reqData);
        emit('config_payment_method_status', reqData, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            setRefresh(!refresh)

        });
    }
    const collapse = (e) => {
        var icon = $(e.target).html();
        icon = icon == 'expand_more' ? 'expand_less' : 'expand_more'
        $(e.target).html(icon)
    }
    const search = (data) => {
        console.log(data.target.value)
        debounce(() => setText(data.target.value), 2000)
    }
    return (
        <div className="intro-y">
            {/* <!-- END: Top Bar --> */}
            <h2 className="intro-y text-lg font-medium mt-10">
                Metode Pembayaran
            </h2>
            <div className="grid grid-cols-12 gap-6 mt-5">
                <div className="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
                    <Link to={'/transactions/metode-add/'} className="btn btn-primary shadow-md mr-2">Tambah Metode</Link>
                    <div className="hidden md:block mx-auto text-gray-600">Showing {((currentPage.number * pageSize) - pageSize) + 1} to {(currentPage.number * pageSize)} of {docsCounts} entries</div>
                    <div className="w-full sm:w-auto flex mt-4 sm:mt-0">
                        {/* <div className="dropdown mr-2 w-56 relative text-gray-700 dark:text-gray-300">
                            <select className="tom-select sm:mr-2" placeholder="Urut Berdasarkan">
                                <option>Urut Berdasarkan</option>
                                <option>Nama</option>
                                <option>Tanggal Registrasi</option>
                            </select>
                        </div> */}
                        <div className="w-56 relative text-gray-700 dark:text-gray-300">
                            <input type="text" onChange={search} className="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Search..." />
                            <i className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" data-feather="search"></i>
                        </div>
                    </div>
                </div>
                {/* <!-- BEGIN: Data List --> */}
                <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
                    <table className="table table-fixed table-report mt-2">
                        <thead>
                            <tr>
                                {/* <th className="whitespace-nowrap">No.</th> */}
                                <th className="text-left whitespace-nowrap">Metode Pembayaran</th>
                                <th className="text-center whitespace-nowrap w-16"></th>
                                <th className="text-center whitespace-nowrap w-10"></th>
                                <th className="text-left whitespace-nowrap">Deskripsi</th>
                                <th className="text-center whitespace-nowrap">Status</th>
                                <th className="text-center whitespace-nowrap">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {metode.map((d, i) => {
                                i *= 1
                                return (
                                    <tr key={i} className="accordion">
                                        <td colSpan="6" className="accordion-item" style={{ padding: '0px' }}>
                                            <div id="faq-accordion-content-2" className="accordion-header">
                                                <table className="table table-fixed table-report" >
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: '2.5rem' }} onClick={collapse} className="cursor-pointer whitespace-nowrap accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#faq-accordion-collapse-2" aria-expanded="false" aria-controls="faq-accordion-collapse-2">
                                                                <span id={d._id} className="material-icons">
                                                                    expand_more
                                                                </span>
                                                            </th>
                                                            <th className="text-left w-16">
                                                                <div className="w-10 h-10 image-fit zoom-in">
                                                                    <img className="tooltip" style={{ aspectRatio: '1 / 1', objectFit: 'contain' }} src={d.fullIcon || "https://cdn-icons-png.flaticon.com/512/149/149071.png"} title="Foto" />
                                                                </div>
                                                            </th>
                                                            <th className="text-left whitespace-nowrap">
                                                                <div className="text-gray-600 text-xs whitespace-nowrap mt-0.5" key={d.name}>
                                                                    {d.name}
                                                                </div>
                                                            </th>
                                                            <th className="text-left text-gray-600 text-xs whitespace-nowrap">{d.description.id}</th>
                                                            <th className="text-center whitespace-nowrap">
                                                                <div onClick={()=>toggle(d,null)} className="flex justify-center form-check">
                                                                    <Switch
                                                                        isOn={d.status == 'active'}
                                                                        onColor="#1be708"
                                                                        data={d}
                                                                        handleToggle={() =>console.log('aaa')}
                                                                    />
                                                                </div>
                                                            </th>
                                                            <th className="text-center whitespace-nowrap">
                                                                <div className="flex justify-center items-center ">
                                                                    <Link to={'/transactions/metode-option-add/' + d._id}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                                                            <g id="Group_493" data-name="Group 493" transform="translate(-1596 -333)">
                                                                                <rect id="Rectangle_1010" data-name="Rectangle 1010" width="36" height="36" rx="5" transform="translate(1596 333)" fill="#7fbe1d" />
                                                                                <g id="svgexport-227" transform="translate(1605 342)">
                                                                                    <path id="Rectangle_805" data-name="Rectangle 805" d="M1.848-.75H15.076a2.6,2.6,0,0,1,2.6,2.6V15.076a2.6,2.6,0,0,1-2.6,2.6H1.848a2.6,2.6,0,0,1-2.6-2.6V1.848A2.6,2.6,0,0,1,1.848-.75ZM15.076,16.257a1.182,1.182,0,0,0,1.181-1.181V1.848A1.182,1.182,0,0,0,15.076.667H1.848A1.182,1.182,0,0,0,.667,1.848V15.076a1.182,1.182,0,0,0,1.181,1.181Z" transform="translate(0.75 0.75)" fill="#fff" />
                                                                                    <path id="Line_873" data-name="Line 873" d="M-.041,8.226A.709.709,0,0,1-.75,7.517V-.041A.709.709,0,0,1-.041-.75a.709.709,0,0,1,.709.709V7.517A.709.709,0,0,1-.041,8.226Z" transform="translate(9.253 5.474)" fill="#fff" />
                                                                                    <path id="Line_874" data-name="Line 874" d="M7.517.667H-.041A.709.709,0,0,1-.75-.041.709.709,0,0,1-.041-.75H7.517a.709.709,0,0,1,.709.709A.709.709,0,0,1,7.517.667Z" transform="translate(5.474 9.253)" fill="#fff" />
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </Link>
                                                                    <div className='mx-1'></div>
                                                                    <Link to={'/transactions/metode-edit/' + d._id}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                                                            <g id="Group_496" data-name="Group 496" transform="translate(-1648 -339)">
                                                                                <path id="Path_1759" data-name="Path 1759" d="M5,0H31a5,5,0,0,1,5,5V31a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V5A5,5,0,0,1,5,0Z" transform="translate(1648 339)" fill="#2b54e6" />
                                                                                <g id="edit" transform="translate(1655.6 346.721)">
                                                                                    <path id="Path_103" data-name="Path 103" d="M14.785,19.054H3.669A2.272,2.272,0,0,1,1.4,16.785V5.669A2.272,2.272,0,0,1,3.669,3.4H9.227a.682.682,0,1,1,0,1.363H3.669a.907.907,0,0,0-.906.906V16.785a.907.907,0,0,0,.906.906H14.785a.907.907,0,0,0,.906-.906V11.227a.681.681,0,0,1,1.363,0v5.558A2.272,2.272,0,0,1,14.785,19.054Z" transform="translate(0 -0.437)" fill="#fff" />
                                                                                    <path id="Path_104" data-name="Path 104" d="M17.609,1.279a2.366,2.366,0,0,1,1.673,4.039L11.739,12.86a.681.681,0,0,1-.317.179l-3.176.794a.682.682,0,0,1-.826-.826l.794-3.176a.681.681,0,0,1,.179-.317l7.543-7.543A2.35,2.35,0,0,1,17.609,1.279Zm-6.7,10.484,7.409-7.409A1,1,0,0,0,16.9,2.935L9.491,10.345l-.473,1.891Z" transform="translate(-1.236 0)" fill="#fff" />
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </Link>
                                                                    {/* <div className='mx-1'></div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                                                                        <g id="Group_497" data-name="Group 497" transform="translate(-1701 -339)">
                                                                            <rect id="Rectangle_1011" data-name="Rectangle 1011" width="36" height="36" rx="5" transform="translate(1701 339)" fill="#f51903" />
                                                                            <g id="trash-2" transform="translate(1708 346)">
                                                                                <path id="Path_249" data-name="Path 249" d="M3,6H18.6" transform="translate(0 -0.532)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
                                                                                <path id="Path_250" data-name="Path 250" d="M17.137,5.468V17.6A1.734,1.734,0,0,1,15.4,19.338H6.734A1.734,1.734,0,0,1,5,17.6V5.468m2.6,0V3.734A1.734,1.734,0,0,1,9.335,2H12.8a1.734,1.734,0,0,1,1.734,1.734V5.468" transform="translate(-0.266)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
                                                                                <line id="Line_268" data-name="Line 268" y2="5.202" transform="translate(9.068 9.802)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
                                                                                <line id="Line_269" data-name="Line 269" y2="5.202" transform="translate(12.536 9.802)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
                                                                            </g>
                                                                        </g>
                                                                    </svg> */}

                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div id="faq-accordion-collapse-2" style={{ marginTop: '0px' }} className="accordion-collapse collapse" aria-labelledby="faq-accordion-content-2" data-bs-parent="#faq-accordion-1">
                                                <div className="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
                                                    <table style={{ backgroundColor: '#F4F4F4' }} className="collapsed table table-fixed table-report">
                                                        <thead>
                                                        </thead>
                                                        <tbody style={{ backgroundColor: '#F4F4F4' }}>
                                                            {
                                                                d.options.map((d2, i) => {
                                                                    return (
                                                                        <tr key={d2._id}>
                                                                            <th className="whitespace-nowrap w-10">
                                                                            </th>
                                                                            <th className="text-left w-16">
                                                                            </th>
                                                                            <th className="text-left whitespace-nowrap flex">
                                                                                <div className='border border-l'></div>
                                                                                <div className="w-10 h-10 image-fit zoom-in mx-2">
                                                                                    <img className="tooltip" style={{ aspectRatio: '1 / 1', objectFit: 'contain' }} src={d2.fullIcon || "https://cdn-icons-png.flaticon.com/512/149/149071.png"} title="Foto" />
                                                                                </div>
                                                                                <div className="text-gray-600 my-auto text-xs whitespace-nowrap" key={d2.name}>
                                                                                    {d2.name}
                                                                                </div>
                                                                            </th>
                                                                            <th className="text-left text-gray-600 text-xs whitespace-nowrap">{d2.description.id}</th>
                                                                            <th className="text-center whitespace-nowrap">
                                                                                <div className="flex justify-center form-check">
                                                                                    <Switch
                                                                                        isOn={d2.status == 'active'}
                                                                                        onColor="#1be708"
                                                                                        data={d2}
                                                                                        handleToggle={() => toggle(d, d2)}
                                                                                    />
                                                                                </div>
                                                                            </th>
                                                                            <th className="text-center whitespace-nowrap">
                                                                                <div className="flex justify-center items-center">
                                                                                    <Link to={'/transactions/metode-option-edit/' + d2._id} className="flex items-center mr-3"> <SVG name="edit" /> Edit </Link>
                                                                                    {/* <AHrefJavascript href="#!" onClick={(e) => {
                                                                                        e.preventDefault()
                                                                                        console.log(e)
                                                                                    }} className="flex items-center text-theme-6" data-toggle="modal" data-target="#delete-confirmation-modal"> <span className="material-icons">delete_outline</span> Delete </AHrefJavascript> */}
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {/* <!-- BEGIN: Pagination --> */}
                <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage.number}
                        totalCount={docsCounts}
                        pageSize={pageSize}
                        onPageChange={page => setCurrentPage({number:page})}
                    />
                    <div className='w-full'></div>
                    <select defaultValue={10} onChange={(e) => setPageSize(parseInt(e.target.value))} style={{ width: '100px' }} className="form-select box mt-3 sm:mt-0">
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={35}>35</option>
                        <option value={50}>50</option>
                    </select>
                </div>
                {/* <!-- END: Pagination --> */}

            </div>
        </div>
    )
}

export default TransaksiMetode

