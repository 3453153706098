import React, { useState, createContext } from "react";

export const AppStateContext = createContext();

const AppStateContextProvider = props => {
  const [appState, setAppState] = useState({
    cartOpen: false
  });
  const [toggle, setToggle] = useState(true);
  const [notify, setNotify] = useState({title:'',msg:''});
  const [page, setPage] = useState({title:'Dashboard',path:'/home'});
  
  return <AppStateContext.Provider value={{ toggle, setToggle,notify,setNotify,page,setPage }}>{props.children}</AppStateContext.Provider>;
};

export default AppStateContextProvider;