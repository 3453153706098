import { useState, useEffect } from 'react'
import { setSelectValue, loadScript, emit, validation, getFormData, showNotify } from '../context/socket'
import { ConvertIsoDate, getBase64 } from '../context/common_function'
import { useHistory } from "react-router-dom";
import Switch from './Switch';
import AHrefJavascript from './AHrefJavascript';
import $ from 'jquery';

const TransaksiMetodeAdd = ({setPath,location}) => {
    const history = useHistory();
    const [changePhoto, setChangePhoto] = useState(false)
    const [isAvailable, setIsAvailable] = useState(true)
    useEffect(() => {
        setPath({title:'Metode Transaksi',path:'/transactions/metode'});
        loadScript();
    }, [])

    const addMetode =async (data)=>{
        var required = $('input,textarea,select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });

        var form2 = getFormData($('#addMetode'))
        if (!validation(form2, required)) return;
        if (changePhoto)
            form2['icon'] = await getBase64('photo-user')
        form2.description ={
            id:form2.id,
            en:form2.en,
        }
        form2.status = isAvailable?'active':'inactive';
        delete form2.id
        delete form2.en
        console.log(form2);
        emit('create_payment_method', form2, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            history.push('/transactions/metode')
        });
    }
    const handleChange = (e) => {
        var uploadField = document.getElementById("actual-btn");
        if(!uploadField.files[0])return;
        console.log(uploadField.files[0].size);
        if (uploadField.files[0].size > 6552000) {
            alert("File is too big!");
            uploadField.value = "";
        } else {
            var url = URL.createObjectURL(uploadField.files[0]);
            $('#photo-user').attr("src", url);
            setChangePhoto(true);
        };
    }
  return (
    <>
            <div className="intro-y flex items-center mt-8">
                <h2 className="text-lg font-medium mr-auto">
                    Edit Metode Pembayaran
                </h2>
            </div>
            <form id="addMetode">
                <div className="grid grid-cols-12 gap-6 mt-5">
                    <div className="col-span-12 lg:col-span-4 2xl:col-span-3 flex lg:block flex-col-reverse">
                        <div className="intro-y box mt-5 lg:mt-0">
                            <div className="relative flex items-center p-5">
                                <div className="mr-auto">
                                    <div className="font-medium text-base">Gambar</div>
                                </div>
                            </div>
                            <div className="border-t border-slate-200/60 dark:border-darkmode-400">
                                <div id="single-file-upload" className="p-5">
                                    <div className="text-slate-500 mb-5">Gambar Metode Pembayaran *</div>
                                    <div className="preview">
                                        <div className="fallback bg-gray-200 ">
                                            <input name="file" type="file" id="actual-btn" onChange={(e) => { handleChange(e) }} accept="image/png, image/gif, image/jpeg" hidden />
                                            <img htmlFor="actual-btn" id="photo-user" className="mx-auto rounded-md" src={"https://fontawesomeicons.com/images/svg/cloud-upload-outline.svg"} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-5 border-t border-slate-200/60 dark:border-darkmode-400 flex justify-center">
                                <label htmlFor="actual-btn" type="button" className="btn py-1 px-10 mx-auto">Unggah Foto</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-12 lg:col-span-8 2xl:col-span-9">
                        <div className="grid ">
                            <div className="intro-y box col-span-12 2xl:col-span-6">
                                <div className="flex items-center px-5 py-5 sm:py-3 border-b border-slate-200/60 dark:border-darkmode-400">
                                    <h2 className="font-medium text-base mr-auto">Detail Metode Pembayaran</h2>
                                    <Switch
                                        isOn={isAvailable}
                                        onColor="#1be708"
                                        handleToggle={() => setIsAvailable(!isAvailable)}
                                    />
                                </div>
                                <div className="p-5">
                                    <div className="mb-5">
                                        <label htmlFor="regular-form-1" className="form-label">Nama Metode Pembayaran <b className="wajib">*</b></label>
                                        <input id="regular-form-1" name="name" required type="text" className="form-control" placeholder="" />
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="regular-form-1" className="form-label">Payment Type (Midtrans Setting) <b className="wajib">*</b></label>
                                        <input id="regular-form-1" name="payment_type" required type="text" className="form-control" placeholder="" />
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="regular-form-1" className="form-label">Deskripsi Metode Pembayaran</label>
                                        <textarea name='id' required  className="form-control h-28" placeholder=""></textarea>
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="regular-form-1" className="form-label">Deskripsi Metode Pembayaran <span className='text-red-500 font-bold'>(ENG)</span> <b className="wajib">*</b></label>
                                        <textarea name='en' className="form-control h-28" placeholder=""></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-right mt-8">
                            <button type="button" onClick={addMetode} className="btn btn-success shadow-md">Simpan Metode</button>
                        </div>
                    </div>

                </div>
            </form>
        </>
  )
}

export default TransaksiMetodeAdd