import { data } from 'jquery';
import React from 'react';
import './Switch.css';

const Switch = ({ isOn, handleToggle, data, onColor }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={() => handleToggle()}
        className="react-switch-checkbox"
        id={data?._id ?? ('id' + (new Date()).getTime())}
        type="checkbox"
      />
      <label
        style={{ background: isOn ? onColor : '#d32121' }}
        className="react-switch-label"
        htmlFor={data?._id ?? ('id' + (new Date()).getTime())}
      >
        <span className={`react-switch-button`} />
      </label>
    </>
  );
};

export default Switch;