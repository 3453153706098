import React, { useState, useEffect, useContext } from 'react'
import { AppStateContext } from "../context/AppStateContext";
import { socket, loadScript, emit, validation, getFormData ,showNotify} from '../context/socket'
import AHrefJavascript from './AHrefJavascript';
import { ConvertIsoDate, getBase64 } from '../context/common_function'
import $ from 'jquery';
import { Link, useHistory } from "react-router-dom";
const UserEdit = ({ setPath, location }) => {
    const { notify, setNotify } = useContext(AppStateContext);
    const [changePhoto, setChangePhoto] = useState(false)
    const [user, setUser] = useState({})
    const history = useHistory();
    useEffect(() => {
        const getUser = async (id) => {
            emit('get_detail_user', { idUser: id }, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setUser(response.data[0])
            });
        }
        loadScript()
        getUser(location.pathname.split('/').reverse()[0])
        setPath({title:'User',path:'/users'});
    }, [])
    const handleChange = (e) => {
        var uploadField = document.getElementById("actual-btn");
        if(!uploadField.files[0])return;
        console.log(uploadField.files[0].size);
        if (uploadField.files[0].size > 6552000) {
            alert("File is too big!");
            uploadField.value = "";
        } else {
            var url = URL.createObjectURL(uploadField.files[0]);
            $('#photo-user').attr("src", url);
            setChangePhoto(true);
        };
    }
    const editUser = async () => {
        var required = $('input,textarea,select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });
        var form2 = getFormData($('#editUser'))
        if (!validation(form2, required)) return;
        if (form2['password'] != form2['re-password']) {
            $('input[name="password"]').focus();
            return alert('Password Belum Sesuai')
        }
        if (changePhoto)
            form2['photo'] = await getBase64('photo-user')
        delete form2['re-password'];
        if(form2['password']=='') delete form2['password'];
        console.log(form2);

        emit('edit_user', form2, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            history.push('/users/profile/'+form2.id)
        });
    }
    return (
        <>
            <div className="intro-y">
                {/* <!-- END: Top Bar --> */}
                <h2 className="intro-y text-lg font-medium mt-10">
                    Edit User
                </h2>
                <form id="editUser">
                    <input type="hidden" name="id" value={user._id || ''} />
                    <div className="grid grid-cols-24 gap-6 mt-5">
                        {/* <!-- BEGIN: Input --> */}
                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                                <h2 className="font-medium text-base mr-auto">
                                    Display Information
                                </h2>
                            </div>
                            <div className="flex flex-col-reverse xl:flex-row flex-col">
                                <div className="w-1/4 m-6">
                                <div className="border w-full flex-col flex shadow-sm border-gray-200 rounded-md p-5">
                                    <div style={{ aspectRatio: '1 / 1', objectFit: 'contain' }} className="h-40 relative image-fit cursor-pointer zoom-in mx-auto">
                                        <img style={{ aspectRatio: '1 / 1', objectFit: 'contain' }} id="photo-user" src={user.userPhoto||'https://cdn-icons-png.flaticon.com/512/149/149071.png'} className="rounded-md" alt="Photo User" />
                                        {/* <img className="rounded-md" src="https://cdn-icons-png.flaticon.com/512/149/149071.png"/> */}
                                        {/* <div title="Remove this profile photo?" className="w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 top-0 -mr-2 -mt-2"> x </div> */}
                                    </div>
                                        <div className="w-1/2 mx-auto cursor-pointer relative mt-5">
                                            <input type="file" id="actual-btn" onChange={(e) => { handleChange(e) }} accept="image/png, image/gif, image/jpeg" hidden />
                                            <label htmlFor="actual-btn" className="btn w-full hover:bg-blue-800 hover:text-white cursor-pointer">Change Photo</label>
                                        </div>
                                    </div>
                                </div>
                                <div id="input" className="flex-1 mt-6 xl:mt-0 p-5">
                                    <div className="preview">
                                        <div>
                                            <label htmlFor="regular-form-1" className="form-label">Full Name</label><span className="text-red-500"> *</span>
                                            <input id="regular-form-1" name="name" required type="text" className="form-control" placeholder="Fill with Full Name" defaultValue={user.name} />
                                        </div>
                                        <div className="mt-3">
                                            <label htmlFor="regular-form-2" className="form-label">Email</label><span className="text-red-500"> *</span>
                                            <input id="regular-form-2" name="email" readOnly type="text" className="form-control" placeholder="Example: lorem@gmail.com" defaultValue={user.email} />
                                        </div>
                                        <div className="mt-3">
                                            <div className="grid grid-cols-2 gap-2">
                                                <label htmlFor="regular-form-3" className="form-label">Password <span className="text-red-500"> *</span></label>
                                                <label htmlFor="regular-form-4" className="form-label">Confirm Password <span className="text-red-500"> *</span></label>
                                            </div>
                                            <div className="grid grid-cols-8 gap-2">
                                                <input id="regular-form-3" name="password" type="password" className="form-control col-span-4" placeholder="Make a strong password" />
                                                <input id="regular-form-4" name="re-password" type="password" className="form-control col-span-4" placeholder="Confirm a strong password" />
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <label htmlFor="regular-form-5" className="form-label">Nomor HP</label><span className="text-red-500"> *</span>
                                            <input id="regular-form-5" name="phone" readOnly type="text" className="form-control" placeholder="" defaultValue={user.phone} />
                                        </div>
                                        {user._id&& <div className="mt-3">
                                            <label htmlFor="regular-form-6" className="form-label">Tanggal Lahir</label><span className="text-red-500"> *</span>
                                            <input id="regular-form-6" type="date" name="birth_date" required defaultValue={ConvertIsoDate(user.birth_date)} className="datepicker form-control col-span-4" data-single-mode="true" data-format="YYYY-MM-DD" placeholder="Tanggal Lahir" />
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-between'>
                            <div className="float-left text-left">
                                <Link to="/lawyers/edit">
                                    <div className="pl-5 text-red-500"><svg xmlns="http://www.w3.org/2000/svg" className="feather pr-1 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>Delete Account</div>
                                </Link>
                                <Link to={"/users/profile/"+user._id} id="toView" className="hidden">
                                </Link>
                            </div>
                            <div className="text-right">
                                <button type="button" onClick={editUser} className="btn btn-dark shadow-md">Edit</button>
                            </div>
                        </div>
                        {/* <!-- END: Input --> */}
                    </div>
                </form>
            </div>
        </>
    )
}

export default UserEdit