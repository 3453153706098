import { useState, useEffect, useRef } from 'react'
import Pagination from './Pagination';
import SVG from './SVG';
import { setSelectValue, loadScript, initPicker, emit, getFormData, validation, debounce, formatDate, showNotify } from '../context/socket'
import { LoremIpsum, Avatar, fullname, username } from "react-lorem-ipsum";
import Litepicker from 'litepicker';
import { reportTransaksi } from '../context/common_function';
import $ from 'jquery';
import { Link } from "react-router-dom";
import TomSelect from 'tom-select';
import moment from 'moment';
import AHrefJavascript from './AHrefJavascript';

const TransaksiAlter = ({ setPath }) => {
    const [transactions, setTransactions] = useState([])
    const [transaction, setTransaction] = useState({})
    const [docsCounts, setDocsCounts] = useState(0)
    const [currentPage, setCurrentPage] = useState({
        number: 0,
        time: new Date().getTime()
    })
    const [pageSize, setPageSize] = useState(10)
    const [date, setDate] = useState(null)
    const [text, setText] = useState('')
    const [sort, setSort] = useState('createdAt')
    const [type, setType] = useState(-1)
    const [refresh, setRefresh] = useState({})
    const [lite, setLite] = useState(null)
    const [filter, setFilter] = useState({
        status: 'semua',
        payment_type: 'semua',
        provider: 'semua',
    })
    useEffect(() => {
        loadScript().then(() => {
            getListMethod();
            initPicker('datepicker2', (data) => setLite(data), (date1, date2) => {
                setDate({ start: formatDate(date1), end: formatDate(date2) })
            })
        });
        setPath({ title: 'Transaksi', path: '/transactions' });
        $(() => {
            $('#approveTransaction').on('click', () => {
                accTransaction()
            })
        })
        $(() => {
            $('#refundTransaction').on('click', () => {
                refundTransaction()
            })
        })
    }, [])
    useEffect(() => {
        setCurrentPage({ number: 1 })
    }, [pageSize, refresh, filter, sort, text, type, date])
    useEffect(() => {
        if (currentPage.number != 0)
            getTransaction()
    }, [currentPage])
    const getTransaction = async () => {
        var endDate = new Date();
        var firstDay = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 7);
        var reqData = {
            'date':
            {
                'start': date ? date.start : formatDate(firstDay),
                'end': date ? date.end : formatDate(endDate),
            }
            ,
            'text': text,
            'page': currentPage.number,
            'size': pageSize,
            'sort': sort,
            'filter': filter,
            'type': parseInt(type.toString()),
        }
        emit('all_transaction_admin', reqData, (response) => {
            if (response.response < 200 || response.response >= 300) return showNotify(response)
            setTransactions(response.data.docs);
            setDocsCounts(response.data.counts)
        });
    }
    const getListMethod = () => {
        var reqData = {
            'text': '',
            'page': 1,
            'size': 10000,
        }
        emit('get_payment_method_admin', reqData, (response) => {
            if (response.response < 200 || response.response >= 300) return showNotify(response)
            var select = document.getElementById('payment')
            var control = select.tomselect;
            if (control) return;
            var control = new TomSelect('#payment', {
                valueField: '_id',
                labelField: 'name',
                searchField: ['name'],
                plugins: {
                    dropdown_input: {}
                }
            })
            control.addOption({ name: 'Semua', _id: 'semua' });
            control.addItem('semua', true);
            response.data.docs.forEach(element => {
                if (element.status != 'active') return;
                if (element.options.length == 0) {
                    control.addOption({ name: element.name, _id: element._id });
                } else {
                    element.options.forEach(element => {
                        if (element.status != 'active') return;
                        control.addOption({ name: element.name, _id: element._id });
                    });
                }
            });

        });
    }

    const handleFilter = (name, value) => {
        console.log(name, value)
        setFilter({ ...filter, [name]: value })
    }

    const getStatus = (status) => {
        var label;
        switch (status) {
            case 'success':
                label = <label className="text-green-500">Selesai</label>;
                break;
            case 'pending':
                label = <label className="text-yellow-500">Belum Bayar</label>
                break
            case 'failed':
                label = <label className="text-red-500">Dibatalkan</label>
                break
            case 'refund':
                label = <label className="text-red-500">Refunded</label>
                break
            default:
                label = <label className="text-grey-500">Unknown</label>
                break;
        }
        return label;
    }
    const search = (data) => {
        console.log(data.target.value)
        debounce(() => setText(data.target.value), 2000)
    }
    const reset = () => {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        setSelectValue('payment', 'semua');
        setSelectValue('status', 'semua');
        setSelectValue('provider', 'semua');
        setSelectValue('type', -1);
        setSelectValue('sort', 'semua');
        lite?.setDateRange(firstDay, date, true);
        $('#search').val('');
        setText('')
        setSort('createdAt')
        setType(-1)
        setFilter({
            status: 'semua',
            payment_type: 'semua',
            provider: 'semua',
        })
        setDate({ start: formatDate(firstDay), end: formatDate(date) })
    }
    const accTransaction = () => {
        var required = $('#admin input,#admin textarea,#admin select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });
        var form2 = getFormData($('#admin'))
        console.log(form2.nominal, form2.price)
        if (form2.nominal != form2.price) return showNotify({ status: 'error', msg: 'Nominal Tidak Sesuai' });
        if (!validation(form2, required)) return;
        form2.noref = form2['No Reference']
        delete form2['No Reference'];
        delete form2['nominal'];
        delete form2['price'];
        console.log(form2)
        emit('acc_manual_transaction', form2, (response) => {
            showNotify(response);
            if (response.response < 200 || response.response >= 300) return;
            $('#closeModal').trigger('click');
            setRefresh(new Date().getTime())
        });
    }
    const exportTransaksi = () => {
        // var xl = excel4node();
        var endDate = new Date();
        var firstDay = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 7);
        var reqData = {
            'date':
            {
                'start': date ? date.start : formatDate(firstDay),
                'end': date ? date.end : formatDate(endDate),
            }
            ,
            'text': text,
            'page': 1,
            'size': 10000,
            'sort': sort,
            'filter': filter,
            'type': parseInt(type.toString()),
        }
        emit('all_transaction_admin', reqData, (response) => {
            if (response.response < 200 || response.response >= 300) return showNotify(response)
            reportTransaksi(response.data.docs, reqData);
        });
    }
    const finalPrice = (data) => {
        if (!data.promo) return 'Rp. ' + (data.price).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
        var disc = 0;
        if (data.promo.type == 'fixed') {
            // var price = data.price 
            if (data.price < data.promo.value) disc = data.price - 1
            else disc = data.promo.value;
        } else {
            disc = data.price * data.promo.value / 100;
            if (disc > data.promo.maxValue) disc = data.promo.maxValue
        }
        return <><strike style={{ color: 'red' }}><span style={{ textDecoration: 'line-through' }}>{'Rp. ' + (data.price).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</span></strike><span>{' Rp. ' + (data.price - disc).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}</span></>
    }
    const createBankSelect = () => {
        var select = document.getElementById('banks')
        if (!select) return;
        var control = select.tomselect;
        if (control) control.destroy();
        new TomSelect('#banks', {
            valueField: '_id',
            labelField: 'name',
            searchField: ['name'],
            plugins: {
                dropdown_input: {}
            },
            // fetch remote data
            load: function (query, callback) {
                var reqData = {
                    'text': query,
                    'page': 1,
                    'size': 1000,
                }
                emit('get_app_banks', reqData, (response) => {
                    if (response.response < 200 || response.response >= 300) return showNotify(response)
                    var result = response.data.docs.map((e) => {
                        return { name: `${e.bank_name} - ${e.account_name} -${e.account_number}`, _id: e._id }
                    })
                    callback(result);
                });
            },
        });
    }
    const refundTransaction = ()=>{
        var required = $('#adminRefund input,#adminRefund textarea,#adminRefund select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });
        var form2 = getFormData($('#adminRefund'))
        console.log(form2.nominal, form2.price)
        if (form2.nominal != form2.price) return showNotify({ status: 'error', msg: 'Nominal Tidak Sesuai' });
        if (!validation(form2, required)) return;
        form2.noref = form2['No Reference']
        delete form2['No Reference'];
        delete form2['nominal'];
        delete form2['price'];
        console.log(form2)
        emit('refund_transaction', form2, (response) => {
            showNotify(response);
            if (response.response < 200 || response.response >= 300) return;
            $('#closeModalRefund').trigger('click');
            setRefresh(new Date().getTime())
        });
    }
    const getRealPrice = (trans)=>{
        if(!trans.promo) return trans.price;
        if(trans.promo.type =='fixed')
            if(trans.promo.value > trans.price) return trans.price - (trans.price-1)
            else trans.price - trans.promo.value
        else 
            return trans.price - (trans.price * trans.promo.value/100)
    }
    return (
        <div className="intro-y">
            {/* <!-- END: Top Bar --> */}
            <div className='flex justify-between mt-5'>
                <h2 className="intro-y text-lg font-medium ">
                    Daftar Transaksi
                </h2>
                <button onClick={exportTransaksi} className="btn shadow-md btn-success">Export</button>
            </div>
            <div className="grid grid-cols-12 gap-6 mt-5">
                <div className="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
                    <div className='mr-3'>Range Tanggal</div>
                    <div className="mr-auto mt-3 sm:mt-0 relative text-gray-700 dark:text-gray-300">
                        <i data-feather="calendar" className="w-4 h-4 z-10 absolute my-auto inset-y-0 ml-3 left-0"></i>
                        <input style={{ width: '115%' }} type="text" onChange={(e) => alert(e.target.value)} id="datepicker2" className="form-control box pl-10" />
                    </div>
                    <div className="w-full sm:w-auto flex mt-4 sm:mt-0">
                        <div className="w-56 relative text-gray-700 dark:text-gray-300">
                            <input id="search" type="text" onChange={search} className="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Search..." />
                            <i className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" data-feather="search"></i>
                        </div>
                    </div>
                </div>
                <div className="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
                    <div onChange={(e) => handleFilter('status', e.target.value)} className="dropdown mr-2 w-48 relative text-gray-700 dark:text-gray-300">
                        <select id="status" className="tom-select sm:mr-2" defaultValue={filter.status} placeholder="Status">
                            <option value={'semua'} label="Semua">Semua</option>
                            <option value={'success'}>Berhasil</option>
                            <option value={'pending'}>Menunggu Pembayaran</option>
                            <option value={'failed'}>Batal</option>
                        </select>
                    </div>
                    <div onChange={(e) => handleFilter('payment_type', e.target.value)} className="dropdown mr-2 w-48 relative text-gray-700 dark:text-gray-300">
                        <select id="payment" className="tom-select sm:mr-2" defaultValue={filter.payment_type} placeholder="Payment Type">

                        </select>
                    </div>
                    <div onChange={(e) => handleFilter('provider', e.target.value)} className="dropdown mr-2 w-48 relative text-gray-700 dark:text-gray-300">
                        <select id="provider" className="tom-select sm:mr-2" defaultValue={filter.provider} placeholder="Status">
                            <option value={'semua'} label="Semua">Semua</option>
                            <option value={'midtrans'}>Midtrans</option>
                            <option value={'xendit'}>Xendit</option>
                        </select>
                    </div>
                    <div onChange={(e) => setSort(e.target.value)} className="dropdown ml-auto mr-2 w-48 relative text-gray-700 dark:text-gray-300">
                        <select id="sort" className="tom-select sm:mr-2" defaultValue={sort} placeholder="Urut Berdasarkan">
                            <option value={'createdAt'}>Tanggal Transaksi</option>
                        </select>
                    </div>
                    <div onChange={(e) => setType(e.target.value)} className="dropdown relative text-gray-700 dark:text-gray-300">
                        <select id="type" className="tom-select sm:mr-2" defaultValue={type} placeholder="Urutan">
                            <option value={1}>A to Z</option>
                            <option value={-1}>Z to A</option>
                        </select>
                    </div>
                    <AHrefJavascript onClick={reset} href="#!" className="btn btn-primary shadow-md mr-2">Reset</AHrefJavascript>
                </div>
                {/* <!-- BEGIN: Users Layout --> */}
                {transactions.length == 0 ? <div className='col-span-12'>Tidak Ada Record Transaksi</div> : <></>}

                {transactions.map((d, i) => {
                    return (
                        <div key={i} className="intro-y col-span-12 lg:col-span-6">
                            <div className="box">
                                <div className="flex p-2 flex-col lg:flex-row items-center border-b-2 border-gray-500 dark:border-dark-5">
                                    <div className="flex flex-col sm:flex-row w-full">
                                        <div className="form-check ml-5 mr-2 font-bold"> <input id="checkbox-switch-4" className="form-check-input" type="checkbox" value="" /> <label className="form-check-label" htmlFor="checkbox-switch-4">{d.midtrans?.order_id ?? '-'}</label> </div>
                                        <div className="ml-2 font-bold">{getStatus(d.status)}</div>
                                    </div>
                                    <div className="flex mr-5 justify-end">
                                        <div className="flex justify-center items-center">
                                            {d.status == 'pending' || d.status == 'failed' ? <AHrefJavascript href="#!" onClick={() => setTransaction(d)} className="flex items-center mr-3" data-toggle="modal" data-target="#pendingBayar"> <SVG name="edit" /> Approve </AHrefJavascript> : <></>}
                                            {/* {d.status == 'success' && d.id_conversation.status=='waiting' ? <AHrefJavascript href="#!" onClick={() =>{createBankSelect(); setTransaction(d)}} className="flex items-center mr-3" data-toggle="modal" data-target="#refundBayar"> <SVG name="edit" /> Refund </AHrefJavascript> : <></>} */}

                                            <Link className="flex items-center mr-3" to={"/transactions/view/" + d._id}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-external-link w-4 h-4 mr-1"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg> View </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-nowrap items-center justify-center p-2 w-full">
                                    <a href={"/users/profile/" + d.id_user?._id}
                                        style={{ backgroundImage: `url(${d.id_user?.fullPhoto})`, backgroundSize: 'cover', backgroundColor: 'red', aspectRatio: '1 / 1', objectFit: 'cover' }}
                                        className="h-24 w-24 lg:w-12 lg:h-12 image-fit rounded-full">
                                        {/* <Avatar gender="male" className="rounded-full">
                                        </Avatar> */}
                                    </a>
                                    <div className="1/4 ml-5 mr-auto text-left">
                                        <a href={"/users/profile/" + d.id_user?._id} className="font-medium"><div className="fullname">{d.id_user?.name || 'Unknow User'}</div></a>
                                        <div className="text-gray-600 text-xs mt-0.5">{d.id_user?.phone}</div>
                                    </div>
                                    <div className="w-1/4">
                                        <div className="ml-5 text-left">
                                            <a href="/transactions/metode" className="font-medium">Metode</a>
                                            <div className="text-gray-600 text-xs mt-0.5">{d.payment_type.name}</div>
                                        </div>
                                    </div>
                                    <div className="w-1/4">
                                        <div className="ml-2 lg:text-left">
                                            <div className="font-medium">Request Date</div>
                                            <div className="text-gray-600 text-xs mt-0.5">{moment(d.createdAt).format('L LT')}</div>
                                        </div>
                                    </div>
                                    <div className="w-1/4">
                                        <div className="ml-2 text-left">
                                            <div className="font-medium">Payment Date</div>
                                            <div className="text-gray-600 text-xs mt-0.5">{d.payment_date ? moment(d.payment_date).format('L LT') : '-'}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-nowrap items-center justify-center p-2">
                                    <div className="flex justify-between bg-clip-padding p-2 bg-gray-300 rounded font-bold w-full text-base">
                                        <div className="pl-5 text-left">
                                            Nominal
                                        </div>
                                        <div className=" pr-5 text-right">
                                            {finalPrice(d)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}


                {/* <!-- END: Users Layout -->
                    <!-- BEGIN: Pagination --> */}
                <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage.number}
                        totalCount={docsCounts}
                        pageSize={pageSize}
                        onPageChange={page => setCurrentPage({ number: page })}
                    />
                    <div className='w-full'>
                        <div className="hidden md:block mx-auto text-gray-600">Showing {((currentPage.number * pageSize) - pageSize) + 1} to {(currentPage.number * pageSize)} of {docsCounts} entries</div>
                    </div>
                    <select defaultValue={10} onChange={(e) => setPageSize(parseInt(e.target.value))} style={{ width: '100px' }} className="form-select box mt-3 sm:mt-0">
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={35}>35</option>
                        <option value={50}>50</option>
                    </select>
                </div>
                {/* <!-- END: Pagination --> */}
            </div>

            <div id="pendingBayar" className="modal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        {/* <!-- BEGIN: Modal Header --> */}
                        <div className="modal-header">
                            <h2 className="font-medium text-base mr-auto">Approve Transaction</h2>
                        </div>
                        {/* <!-- END: Modal Header --> */}
                        {/* <!-- BEGIN: Modal Body --> */}
                        <div className={`px-5 pt-5`}>
                            {transaction.invoice}
                            <form id='admin'>
                                <input type="hidden" name='idTransaction' required value={transaction._id ?? ''} />
                                <input type="hidden" name='price' required value={getRealPrice(transaction) ?? ''} />
                                <label htmlFor="modal-form-1" className="form-label">Nominal</label><label className='text-red-500'> *</label>
                                <input id="modal-form-1" type="number" name='nominal' className="form-control mb-4" required placeholder="" />

                                <label htmlFor="modal-form-1" className="form-label">No. Reference</label><label className='text-red-500'> *</label>
                                <input id="modal-form-1" type="text" name='No Reference' className="form-control mb-4" required minLength="10" maxLength="40" placeholder="Max 40 Character" />
                            </form>
                        </div>
                        {/* <!-- END: Modal Body --> */}
                        {/* <!-- BEGIN: Modal Footer --> */}
                        <div className="modal-footer">
                            <button id="closeModal" type="button" data-dismiss="modal" className="btn btn-outline-secondary w-20 mr-1">Cancel</button>
                            <button id="approveTransaction" type="button" className="btn btn-success w-20">Approve</button>
                        </div>
                        {/* <!-- END: Modal Footer --> */}
                    </div>
                </div>
            </div>
            <div id="refundBayar" className="modal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        {/* <!-- BEGIN: Modal Header --> */}
                        <div className="modal-header">
                            <h2 className="font-medium text-base mr-auto">Refund Transaction</h2>
                        </div>
                        {/* <!-- END: Modal Header --> */}
                        {/* <!-- BEGIN: Modal Body --> */}
                        <div className={`px-5 pt-5`}>
                            {transaction.invoice}
                            <form id='adminRefund'>
                                <input type="hidden" name='idTransaction' required value={transaction._id ?? ''} />
                                <input type="hidden" name='price' required value={getRealPrice(transaction) ?? ''} />
                                <label htmlFor="modal-form-1" className="form-label">Nominal</label><label className='text-red-500'> *</label>
                                <input id="modal-form-1" type="number" name='nominal' className="form-control mb-4" required placeholder="" />

                                <label htmlFor="modal-form-1" className="form-label">No. Reference</label><label className='text-red-500'> *</label>
                                <input id="modal-form-1" type="text" name='No Reference' className="form-control mb-4" required minLength="10" maxLength="40" placeholder="Max 40 Character" />
                                <label htmlFor="modal-form-10" className="form-label">Rekening</label><label className='text-red-500'> *</label>
                                <div className="dropdown mr-2 relative text-gray-700 dark:text-gray-300">
                                    <select id="banks" name="bank" className="tom-select sm:mr-2" placeholder="Nomor Rekening">
                                        {/* <option value={'fixed'} label="Fixed">Fixed</option>
                                            <option value={'percentage'} label="Persentase">Persentase</option> */}
                                    </select>
                                </div>
                            </form>
                        </div>
                        {/* <!-- END: Modal Body --> */}
                        {/* <!-- BEGIN: Modal Footer --> */}
                        <div className="modal-footer">
                            <button id="closeModalRefund" type="button" data-dismiss="modal" className="btn btn-outline-secondary w-20 mr-1">Cancel</button>
                            <button id="refundTransaction" type="button" className="btn btn-danger w-20">Refund</button>
                        </div>
                        {/* <!-- END: Modal Footer --> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransaksiAlter