import { useState, useEffect } from 'react'
import { socket, loadScript, emit,showNotify } from '../context/socket'
import AHrefJavascript from './AHrefJavascript';
import { LoremIpsum } from "react-lorem-ipsum";
import moment from 'moment';
import { Link } from "react-router-dom";
import Pagination from './Pagination';
const Pengaduan = ({ setPath }) => {
    const [reports, setReports] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState({
        number:0,
        time:new Date().getTime()
    })
    const [text, setText] = useState('')
    const [status, setStatus] = useState('active')
    const [docsCounts, setDocsCounts] = useState(0)
    useEffect(() => {
        setPath({title:'Pengaduan',path:'/pengaduan'});
        loadScript();
    }, [])
    useEffect(() => {
        setCurrentPage({number:1})
    }, [pageSize, text,status])

    useEffect(() => {
        const getUser = async () => {
            var reqData = {
                'name': text,
                'page': currentPage.number,
                'size': pageSize,
                "status": status
            }
            emit('list_reports', reqData, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setReports(response.data.docs);
                setDocsCounts(response.data.counts)
            });
        }
        if(currentPage.number!=0)
        getUser()
    }, [currentPage])
    const updateStatus = (data) => {
        console.log(data)
        setStatus(data)
    }
    const newTo = (data) => {
        return { pathname: "/pengaduan/view/" + data._id }
    };
    const randomDate = (start, end) => {
        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())).toISOString().slice(0, 10);
    }
    const randomOnline = (start, end) => {
        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())).toISOString().slice(0, 16).replace('T', ' ');
    }
    Array.prototype.randomStatus = function () {
        return this[Math.floor(Math.random() * this.length)];
    }

    return (
        <div className="intro-y">
            {/* <!-- END: Top Bar --> */}
            <h2 className="intro-y text-lg font-medium mt-10">
                List Pengaduan
            </h2>
            <div className="grid grid-cols-12 gap-6 mt-5">
                <div className="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
                    {/* <button className="btn btn-primary shadow-md mr-2">Tambah User</button> */}
                    {/* <div className="dropdown">
                        <button className="dropdown-toggle btn px-2 box text-gray-700 dark:text-gray-300" aria-expanded="false">
                            <span className="w-5 h-5 flex items-center justify-center"> <i className="w-4 h-4" data-feather="plus"></i> </span>
                        </button>
                        <div className="dropdown-menu w-40">
                            <div className="dropdown-menu__content box dark:bg-dark-1 p-2">
                                <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="printer" className="w-4 h-4 mr-2"></i> Print </a>
                                <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="file-text" className="w-4 h-4 mr-2"></i> Export to Excel </a>
                                <a href="" className="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"> <i data-feather="file-text" className="w-4 h-4 mr-2"></i> Export to PDF </a>
                            </div>
                        </div>
                    </div> */}
                    <div className="intro-y pr-1">
                        <div className="boxed-tabs nav nav-tabs justify-center bg-theme-8 bg-opacity-50 text-gray-600 dark:bg-dark-2 dark:text-gray-500 rounded-md p-1 mx-auto" role="tablist">
                            <AHrefJavascript onClick={()=>updateStatus('active')} data-toggle="tab" data-target="#active-users"  className="btn flex-1 w-80 border-0 shadow-none py-1.5 px-2 active" id="active-users-tab" role="tab" aria-controls="active-users" aria-selected="true">Berlangsung</AHrefJavascript>
                            <AHrefJavascript onClick={()=>updateStatus('done')} data-toggle="tab" data-target="#inactive-users"  className="btn flex-1 border-0 shadow-none py-1.5 px-2" id="inactive-users-tab" role="tab" aria-selected="false">Selesai</AHrefJavascript>
                        </div>
                    </div>
                    <div className="hidden md:block mx-auto text-gray-600">Showing {((currentPage.number * pageSize) - pageSize) + 1} to {(currentPage.number * pageSize)} of {docsCounts} entries</div>
                    <div className="w-full sm:w-auto flex mt-4 sm:mt-0">
                        <div className="w-56 relative text-gray-700 dark:text-gray-300">
                            <input type="text" className="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Search..." />
                            <i className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" data-feather="search"></i>
                        </div>
                    </div>
                </div>
                {/* <!-- BEGIN: Data List --> */}
                <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
                    <table className="table table-report -mt-2">
                        <thead>
                            <tr>
                                <th className="whitespace-nowrap">No.</th>
                                <th className="whitespace-nowrap" colSpan="2">Nama Pelapor</th>
                                <th className="text-center whitespace-nowrap">Keluhan</th>
                                <th className="whitespace-nowrap" colSpan="2">Nama Terlapor</th>
                                <th className="text-center whitespace-nowrap">Waktu</th>
                                <th className="text-center whitespace-nowrap">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reports.map((d, i) => {
                                i *= 1
                                var data = d.reportedLawyer? d.reportedLawyer:d.reportedUser;
                                var data2 = d.reporterLawyer? d.reporterLawyer:d.reporterUser;
                                return (
                                    <tr key={i} className="intro-x">
                                        <td className="w-1">
                                            <div className="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                                                {++i}
                                            </div>
                                        </td>
                                        <td className="w-1">
                                            <div className="flex">
                                                <div className="w-10 h-10 image-fit zoom-in">
                                                    <img className="tooltip rounded-full" src={data2.fullPhoto||"https://cdn-icons-png.flaticon.com/512/149/149071.png"} title="Foto" />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="text-gray-600 text-xs whitespace-nowrap mt-0.5" key={data2._id}>
                                                {data2.name}
                                            </div>
                                        </td>
                                        <td className="text-justify w-1/3">
                                            <div className="font-bold">{d.keluhan}</div>
                                            <div className="pl-2">{d.description}</div>
                                        </td>
                                        <td className="w-1">
                                            <div className="flex">
                                                <div className="w-10 h-10 image-fit zoom-in">
                                                    <img className="tooltip rounded-full" src={data.fullPhoto||"https://cdn-icons-png.flaticon.com/512/149/149071.png"} title="Foto" />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="text-gray-600 text-xs whitespace-nowrap mt-0.5" key={data._id}>
                                                {data.name}
                                            </div>
                                        </td>
                                        <td className="text-center">{moment(new Date(d.createdAt)).format('L')}</td>
                                        <td className="table-report__action w-56">
                                            <div className="flex justify-center items-center">
                                                <Link to={newTo(d)} className="flex items-center mr-3" > <i data-feather="check-square" className="w-4 h-4 mr-1"></i> View </Link>
                                                <a className="flex items-center text-theme-6"  data-toggle="modal" data-target="#delete-confirmation-modal"> <i data-feather="trash-2" className="w-4 h-4 mr-1"></i> Delete </a>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {/* <!-- BEGIN: Pagination --> */}
                <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage.number}
                        totalCount={docsCounts}
                        pageSize={pageSize}
                        onPageChange={page => setCurrentPage({number:page})}
                    />
                    <div className='w-full'></div>
                    <select defaultValue={10} onChange={(e) => setPageSize(parseInt(e.target.value))} style={{width:'100px'}} className="w-20 form-select box mt-3 sm:mt-0">
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={35}>35</option>
                        <option value={50}>50</option>
                    </select>
                </div>
                {/* <!-- END: Pagination --> */}

            </div>
        </div>
    )
}

export default Pengaduan

