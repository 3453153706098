import { socket, loadScript, emit, session, validation, getFormData, showNotify } from '../context/socket'
import { useState, useEffect } from 'react'
import TomSelect from 'tom-select';
import $, { map } from 'jquery';

const ListCategori = ({ value }) => {
    const [categories, setCategories] = useState([])
    const getCategories = async () => {
        emit('get_categories', '', (response) => {
            if (response.response < 200 || response.response >= 300) return showNotify(response)
            setCategories(response.data)
        });
    }
    useEffect(() => {
        var select = document.getElementById('categories')
        if(!select)return;
        console.log(select.tomselect)
        var control = select.tomselect;
        if (!select.tomselect && categories.length!=0)
            control = new TomSelect('#categories', {
                "plugins": {
                    "dropdown_input": {},
                    "remove_button": {
                        "title": "Remove this item"
                    }
                },
                "placeholder": "Pilih minimal satu",
                "persist": false,
                "create": true,
            });
        if (value) {
            $(select).next().addClass('tom-select')
            control.addItems(value);
        }

    }, [categories])
    if (value && categories.length == 0) getCategories()
    return (
        <>
            {   categories.length!=0?
                <select data-placeholder="Pilih minimal satu" id='categories' placeholder="Pilih Minimal Satu Kategori" name="categories" className="w-full" multiple>
                {categories.map(e =>
                    <option key={e._id} value={e._id}>{e.name}</option>
                )}
                </select>:<>Loading...</>
            }
        </>
    )
}

export default ListCategori
