import React, { Component, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import Routes from './routes/routes';
import { SocketContext, socket } from './context/socket';
import SideBar from './components/SideBar';
import NavigationBar from './components/NavigationBar';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import $ from 'jquery';
import "zoom-vanilla.js/dist/zoom.css"
import "zoom-vanilla.js/dist/zoom-vanilla.min.js"
import "tom-select/dist/css/tom-select.min.css"
import moment from 'moment';
import 'moment/locale/id';
import AppStateContext from "./context/AppStateContext";
import { useCookies } from 'react-cookie';
import CustomDialog from './components/CustomDialog';

export const App = () => {
  const [cookies, setCookie] = useCookies(['token']);
  const [path, setPath] = useState('/home')
  
  const userIsLogged = cookies.token;
  moment.locale('id')
  if (userIsLogged) {
    return (
      <View>
        <Router>
          <AppStateContext>
            <div className="flex">
              <SideBar />
              <div className={userIsLogged ? "content mt-5 mr-5 mb-5" : ""}>
                <NavigationBar />
                <Routes setPath={setPath} />
              </div>
            </div>
            <CustomDialog/>
          </AppStateContext>
        </Router>
       
      </View>
      
    )
  } else {
    return (
      <View>
        <Router>
          {/* <SideBar path={path}/> */}
          {/* <div className={userIsLogged?"content":""}> */}
          {/* <NavigationBar /> */}
          <AppStateContext>
          <Routes setPath={setPath} />
          </AppStateContext>
          {/* </div> */}
        </Router>
      </View>
    )
  }
}
export default App;
