import { useState, useEffect } from 'react'
import { socket, loadScript, emit, validation, getFormData, showNotify } from '../context/socket'
import AHrefJavascript from './AHrefJavascript';
import $ from 'jquery';
import { Link } from "react-router-dom";
import moment from 'moment';

const LawyerHistory = ({ setPath, location }) => {
    const [lawyer, setLawyer] = useState({})
    const [saldo, setSaldo] = useState(0)
    const [refresh, setRefresh] = useState({})
    useEffect(() => {
        const getUser = async (id) => {
            emit('history_lawyer', { id: id }, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setLawyer(response.data)
                setSaldo(response.data.saldo);
            });
        }
        getUser(location.pathname.split('/').reverse()[0])
    }, [refresh])
    useEffect(() => {
        setPath({title:'Lawyer',path:'/lawyers'});
        loadScript();
    }, [])
    const correctionSaldo = () => {
        var res = confirm('Anda Yakin Sudah Mengecek Riwayat Transaksi Lawyer dan ingin memperbaiki saldo lawyer?')
        console.log(res);
        if (res) {
            emit('correction_saldo_lawyer', { idLawyer: location.pathname.split('/').reverse()[0] }, (response) => {
                showNotify(response);
                if (response.response < 200 || response.response >= 300) return;
                setRefresh(new Date().getTime())
            })
        }
    }
    return (
        <>
            <div className="intro-y">
                {/* <!-- END: Top Bar --> */}
                <h2 className="intro-y text-lg font-medium mt-10">
                    History Transaction
                </h2>
                <div className='flex justify-between'>
                    <div>{lawyer.name} <br></br>Saldo Saat Ini : Rp. {saldo.toLocaleString()}</div>
                    <AHrefJavascript onClick={correctionSaldo} href="#!" id="correctionBtn" className="btn btn-danger mx-2 hidden">Perbaiki Saldo</AHrefJavascript>
                </div>
                <div className="grid grid-cols-12 gap-6 ">
                    {/* <!-- BEGIN: Data List --> */}

                    <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
                        <table className="table table-auto table-report mt-2">
                            <thead>
                                <tr>
                                    <th className="whitespace-nowrap">No.</th>
                                    <th className="text-center whitespace-nowrap">Tanggal Transaksi</th>
                                    <th className="text-center whitespace-nowrap">Tipe</th>
                                    <th className="text-center whitespace-nowrap">Nominal Transaksi</th>
                                    <th className="text-center whitespace-nowrap">Saldo Saat Ini</th>
                                    <th className="text-center whitespace-nowrap">Balance</th>
                                    <th className="text-center whitespace-nowrap">ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(() => {
                                    var saldo = lawyer.saldo;
                                    var correctSaldo = 0;
                                    var saldos = [];
                                    if (lawyer.history)
                                        for (let index = lawyer.history.length - 1; index >= 0; index--) {
                                            if (lawyer.history[index].invoice)
                                                correctSaldo += lawyer.history[index].lawyer_profit
                                            else
                                                correctSaldo -= lawyer.history[index].lawyer_profit
                                            saldos.push(correctSaldo)
                                        }
                                    saldos.reverse()
                                    // saldos[0] -= saldo;
                                    const listData = []
                                    lawyer.history?.map((e, i) => {
                                        var current = saldo;
                                        var url = e.invoice?'/transactions/view/'+e._id:'/transactions/settlement/view/'+e._id
                                        listData.push(
                                            <tr key={++i} className="intro-x">
                                                <td className="w-1">
                                                    <div className="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                                                        {i + 1}
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    {moment(e.createdAt).format('L LT')}
                                                </td>
                                                <td className='text-center' style={{ color: e.invoice ? '#7FBE1D' : '#1868EF' }}>
                                                    {e.invoice || 'Withdraw'}
                                                </td>
                                                <td className="text-center">
                                                    Rp. {e.lawyer_profit?.toLocaleString()}
                                                </td>
                                                <td className="text-center">
                                                    Rp. {current.toLocaleString()}
                                                </td>
                                                <td className={"status text-center "} style={{ color: (saldos[i] == current ? '#7FBE1D' : '') }}>
                                                    Rp. {saldos[i].toLocaleString()}
                                                </td>
                                                <td className="table-report__action">
                                                    <div className="flex justify-center items-center">
                                                        <Link className="flex items-center mr-3" to={url}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-external-link w-4 h-4 mr-1"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg> Detail </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                        if (e.invoice)
                                            saldo = saldo - e.lawyer_profit;
                                        else
                                            saldo = saldo + e.lawyer_profit;
                                    })
                                    console.log(saldo)
                                    if(saldo && saldo!=0){
                                        $('#correctionBtn').removeClass('hidden');
                                    }
                                    // listData.splice(0, 0, <div key={new Date().getTime()} className='flex items-center'>
                                    //     <div>Saldo Kelebihan {saldo}</div>
                                    //     {/* <AHrefJavascript onClick={correctionSaldo} href="#!" className="btn btn-success mx-2">Perbaiki Sekarang</AHrefJavascript> */}
                                    // </div>);
                                    return listData;
                                })()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LawyerHistory