import { useState, useEffect } from 'react'
import { socket, loadScript, emit, validation, getFormData, showNotify } from '../context/socket'
import { useHistory } from "react-router-dom";
import Switch from './Switch';
import $ from 'jquery';
function EditKategoriKasus({ setPath }) {
    const [categories, setCategories] = useState({})
    const history = useHistory();
    useEffect(() => {
        const getCategories = async (id) => {
            var reqData = {
                'id': id,
                'name': '',
                'page': 1,
                'size': 1,
                'status': null,
            }
            emit('list_categories', reqData, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setCategories(response.data.docs[0])
            });
        }
        getCategories(location.pathname.split('/').reverse()[0]);
        setPath({title:'Kategori Kasus',path:'/kategorikasus'});
        loadScript()
    }, [])

    const editKategoriKasus = () => {
        var required = $('input,textarea,select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });

        var form2 = getFormData($('#editKategoriKasus'))
        if (!validation(form2, required)) return;
        form2.pinned = categories.pinned
        console.log(form2)
        emit('edit_category', form2, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return showNotify(response)
            history.push('/kategorikasus')
        });
    }

    const toggle = (data) => {
        setCategories({ ...data, pinned: !data.pinned })
    }
    return (
        <div className="intro-y">
            {/* <!-- END: Top Bar --> */}
            <h2 className="intro-y text-lg font-medium mt-10">
                Edit Kategori Kasus
            </h2>
            <form id="editKategoriKasus">
                <input type='hidden' name='idCategory' value={categories._id || ''} />
                <div className="grid grid-cols-24 gap-6 mt-5">
                    {/* <!-- BEGIN: Input --> */}
                    <div className="intro-y box">
                        <div className="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                            <h2 className="font-medium text-base mr-auto">
                                Detail Kategori Kasus
                            </h2>
                            <h2 className='text-right'>
                                Pin Kasus
                                <div className="flex items-center justify-center">
                                    {categories.pinned != null && <Switch
                                        isOn={categories.pinned}
                                        onColor="#1be708"
                                        data={categories}
                                        handleToggle={() => toggle(categories)}
                                    />}
                                </div>
                            </h2>
                        </div>
                        <div className="flex flex-col-reverse xl:flex-row flex-col">
                            <div id="input" className="flex-1 mt-6 xl:mt-0 p-5">
                                <div className="preview">
                                    <div>
                                        <label htmlFor="regular-form-1" className="form-label">Nama Kategori Kasus</label><span className="text-red-500"> *</span>
                                        <input id="regular-form-1" name="name" defaultValue={categories.name} required type="text" className="form-control" />
                                    </div>
                                    <div className="mt-3">
                                        <label htmlFor="regular-form-2" className="form-label">Nama Kategori Kasus (ENG)</label><span className="text-red-500"> *</span>
                                        <input id="regular-form-2" name="english" required defaultValue={categories.english} type="text" className="form-control" />
                                    </div>
                                    {/* <div className="text-left mt-8">
                                        <button type='button' onClick={deleteCategory}  className='btn btn-danger'><i data-feather="trash-2" className="w-4 h-4 mr-1"></i></button>
                                    </div> */}
                                    <div className="text-right mt-8">
                                        <button type="button" onClick={editKategoriKasus} className="btn btn-dark shadow-md">Edit Kategori Kasus</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <!-- END: Input --> */}
                </div>

            </form>
        </div>
    )
}

export default EditKategoriKasus