import React, { useContext, useState, useEffect } from 'react';
import { View, Text, Image, FlatList } from 'react-native';
import { capitalizeFirstLetter, emit, showNotify, socket } from '../context/socket';
import io from "socket.io-client";
import { useCookies } from 'react-cookie';
import ProfileDropdown from './ProfileDropdown';
import { Avatar, fullname, username } from "react-lorem-ipsum";
import { AppStateContext } from "../context/AppStateContext";
import moment from 'moment';
import { Link } from "react-router-dom";
import $ from 'jquery';

const NavigationBar = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['token','user']);
    const { page } = useContext(AppStateContext);
    const [notify, setNotify] = useState([])
    const [lastMsg, setLastMsg] = useState(cookies.notify)
    const onLogout = () => {
        console.log('Logout');
        emit('admin_logout', {}, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            removeCookie('user', { path: '/' });
            removeCookie('token', { path: '/' });
        });
        // location.href="/login";
    };
    useEffect(() => {
        getNotify()
        socket.on('new_log_admin', (res) => {
            console.log(res);
            getNotify();
        })
        return () => {
            socket.off('new_log_admin')
        }
    }, [])
    useEffect(() => {
        setCookie('notify', lastMsg)
    }, [lastMsg])
    useEffect(()=>{
        $(()=>{
            $('#logoutBtn').on('click',onLogout)
        })
        return $('#logoutBtn').off('click')
    },[])
    const getNotify = () => {
        emit('get_web_notify', { full: false }, (response) => {
            if (response.response < 200 || response.response >= 300) return showNotify(response);
            setNotify(response.data)
        })
    }
    const userIsLogged = cookies.token;
    const user = cookies.user;
    if (!userIsLogged) return <></>
    return (
        <div className="top-bar">
            <div className="-intro-x breadcrumb mr-auto hidden sm:flex"> <Link to="/Home">Home</Link> <i data-feather="chevron-right" className="breadcrumb__icon"></i> <Link to={page.path} className="breadcrumb--active">{page.title}</Link> </div>
            <div className="intro-x relative mr-3 sm:mr-6">
                {/* <div className="search hidden sm:block">
                    <input type="text" className="search__input form-control border-transparent placeholder-theme-13" placeholder="Search..." />
                    <i data-feather="search" className="search__icon dark:text-gray-300"></i>
                </div> */}
                <a className="notification sm:hidden" href=""> <i data-feather="search" className="notification__icon dark:text-gray-300"></i> </a>
                <div className="search-result">
                    <div className="search-result__content">
                        <div className="search-result__content__title">Pages</div>
                        <div className="mb-5">
                            <a href="" className="flex items-center">
                                <div className="w-8 h-8 bg-theme-18 text-theme-9 flex items-center justify-center rounded-full"> <i className="w-4 h-4" data-feather="inbox"></i> </div>
                                <div className="ml-3">Mail Settings</div>
                            </a>
                            <a href="" className="flex items-center mt-2">
                                <div className="w-8 h-8 bg-theme-17 text-theme-11 flex items-center justify-center rounded-full"> <i className="w-4 h-4" data-feather="users"></i> </div>
                                <div className="ml-3">Users & Permissions</div>
                            </a>
                            <a href="" className="flex items-center mt-2">
                                <div className="w-8 h-8 bg-theme-14 text-theme-10 flex items-center justify-center rounded-full"> <i className="w-4 h-4" data-feather="credit-card"></i> </div>
                                <div className="ml-3">Transactions Report</div>
                            </a>
                        </div>
                        <div className="search-result__content__title">Users</div>
                        <div className="mb-5">
                            <a href="" className="flex items-center mt-2">
                                <div className="w-8 h-8 image-fit">
                                    <Avatar className="rounded-full" gender="male" />
                                </div>
                                <div className="ml-3">{fullname('male')}</div>
                                <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">{`${username()}@gmail.com`}</div>
                            </a>
                            <a href="" className="flex items-center mt-2">
                                <div className="w-8 h-8 image-fit">
                                    <Image alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/profile-5.jpg" />
                                </div>
                                <div className="ml-3">Kevin Spacey</div>
                                <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">kevinspacey@left4code.com</div>
                            </a>
                            <a href="" className="flex items-center mt-2">
                                <div className="w-8 h-8 image-fit">
                                    <Image alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/profile-6.jpg" />
                                </div>
                                <div className="ml-3">Brad Pitt</div>
                                <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">bradpitt@left4code.com</div>
                            </a>
                            <a href="" className="flex items-center mt-2">
                                <div className="w-8 h-8 image-fit">
                                    <Image alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/profile-6.jpg" />
                                </div>
                                <div className="ml-3">Denzel Washington</div>
                                <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">denzelwashington@left4code.com</div>
                            </a>
                        </div>
                        <div className="search-result__content__title">Products</div>
                        <a href="" className="flex items-center mt-2">
                            <div className="w-8 h-8 image-fit">
                                <Image alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/preview-14.jpg" />
                            </div>
                            <div className="ml-3">Dell XPS 13</div>
                            <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">PC &amp; Laptop</div>
                        </a>
                        <a href="" className="flex items-center mt-2">
                            <div className="w-8 h-8 image-fit">
                                <Image alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/preview-10.jpg" />
                            </div>
                            <div className="ml-3">Dell XPS 13</div>
                            <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">PC &amp; Laptop</div>
                        </a>
                        <a href="" className="flex items-center mt-2">
                            <div className="w-8 h-8 image-fit">
                                <Image alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/preview-15.jpg" />
                            </div>
                            <div className="ml-3">Nike Air Max 270</div>
                            <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">Sport &amp; Outdoor</div>
                        </a>
                        <a href="" className="flex items-center mt-2">
                            <div className="w-8 h-8 image-fit">
                                <Image alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src="dist/images/preview-5.jpg" />
                            </div>
                            <div className="ml-3">Nikon Z6</div>
                            <div className="ml-auto w-48 truncate text-gray-600 text-xs text-right">Photography</div>
                        </a>
                    </div>
                </div>
            </div>

            {/* <button onClick={onLogout} className="btn btn-primary shadow-md mr-2">Logout</button> */}
            <div className="intro-x dropdown mr-auto sm:mr-6">
                <div onClick={() => setLastMsg(notify[0]._id)} className={"dropdown-toggle notification cursor-pointer " + (notify && lastMsg != notify[0]?._id ? 'notification--bullet' : '')} role="button" aria-expanded="false"> <i data-feather="bell" className="notification__icon dark:text-gray-300"></i> </div>
                <div className="notification-content pt-2 dropdown-menu">
                    <div className="notification-content__box dropdown-menu__content box dark:bg-dark-6">
                        <div className="notification-content__title">Notifications</div>
                        {notify.map((e) => {
                            return (
                                <div key={e._id} className="cursor-pointer w-full relative flex items-center mt-5">
                                    <div className="w-12 h-12 flex-none image-fit mr-1">
                                        <img style={{ aspectRatio: '1 / 1', objectFit: 'cover' }} className="w-full rounded-full" src={e.admin?.fullPhoto || "https://cdn-icons-png.flaticon.com/512/149/149071.png"} title="Foto" />
                                        {/* <div className="w-3 h-3 bg-theme-9 absolute right-0 bottom-0 rounded-full border-2 border-white"></div> */}
                                    </div>
                                    <div className="ml-2 w-full overflow-hidden">
                                        <div className="flex items-center">
                                            <a href="{void(0)}" className="font-medium truncate mr-5">{e.admin?.name||'System'}</a>
                                            <div className="text-xs text-gray-500 ml-auto whitespace-nowrap">{moment(e.createdAt).format('LT')}</div>
                                        </div>
                                        <div className="w-full truncate text-gray-600 mt-0.5">{e.keterangan}</div>
                                    </div>
                                </div>
                            )
                        })}
                        <br></br>
                        <div className='flex justify-end'>
                            <Link to="/users/logs" className='text-right'>Selengkapnya</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="intro-x dropdown w-8 h-8">
                <div className="w-8 h-8 image-fit cursor-pointer dropdown-toggle">
                    <img className="rounded-full" src={user?.fullPhoto || "https://cdn-icons-png.flaticon.com/512/149/149071.png"} />
                </div>
                <div className="dropdown-menu w-56">
                    <div style={{backgroundColor:"#1B3FAA"}} className="dropdown-menu__content box text-white">
                        <div className="p-4 border-b border-theme-27 dark:border-dark-3">
                            <div className="font-medium">{capitalizeFirstLetter(user?.name)}</div>
                            <div className="font-medium">{user?.email}</div>
                            <div className="text-xs text-theme-28 mt-0.5 dark:text-gray-600">{capitalizeFirstLetter(user?.role)}</div>
                        </div>
                        <div className="p-2">
                            <Link to={"/admins/edit/"+user?._id} className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"> <i data-feather="user" className="w-4 h-4 mr-2"></i> Profile </Link>
                            <Link to="" className="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"> <i data-feather="help-circle" className="w-4 h-4 mr-2"></i> Help </Link>
                        </div>
                        <div className="p-2 border-t border-theme-27 dark:border-dark-3">
                            <button id="logoutBtn" type="submit" className="flex items-center w-full block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md"> <i data-feather="toggle-right" className="w-4 h-4 mr-2"></i> Logout </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // <View>
        //     <FlatList
        //         keyExtractor={users => users._id}
        //         data={this.state.users}
        //         renderItem={({ item }) => <Text>{item.email}</Text>}
        //     />
        // </View>
    );
};
export default NavigationBar;