import socketio from "socket.io-client";
import React, { useState, useContext, useCallback, useEffect } from 'react';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import Litepicker from 'litepicker';

// export const socket = socketio.connect('wss://apilab.laporhukum.com/admin', { transports: ['websocket'] });
export const socket = socketio.connect('wss://api.laporhukum.com/admin', { transports: ['websocket'] });
// export const socket = socketio.connect('ws://54.169.75.245:3000/admin', { transports: ['websocket'] });
// export const socket = socketio.connect('ws://localhost:3000/admin', { transports: ['websocket'] });
export const SocketContext = React.createContext();
// const [cookies, setCookie] = useCookies(['token']);
export const emit = async function (socketName, data, cb) {
    var complete = false;
    var emitData = {
        'header': {
            'access-token': getCookie('token'),
        },
        'data': data
    }
    socket.emit(socketName, emitData);
    socket.on(socketName, (data) => {
        complete = true;
        if (data.msg == 'Token Tidak Valid') return window.location.href = "/login"
        console.log('EMITTER [' + socketName + '] = ', data);
        socket.off(socketName);
        cb(data);
    });
    setTimeout(() => {
        if (complete) return;
        console.log('ERROR EMITTER [' + socketName + '] = Request Timeout');
        socket.off(socketName);
        cb({
            "response": 500,
            "data": null,
            "status": "error",
            "msg": "Request Timeout"
        });
    }, 10000);
}
function getCookie(name) {
    try {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    } catch (error) {
        console.log('getCookie', error)
        return null;
    }

}
export const setSelectValue = (id, value) => {
    var select = document.getElementById(id)
    var control = select.tomselect;
    if (control)
        control.addItems(value);
}
export const debounce = (func, timeout = 300) => {
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => { func() }, timeout);
}
export const loadScript = ()=>{
    var load =  new Promise((resolve, reject) => {
        try {
            var scr = $('#rubic_script');
            $('body > div:gt(0)').remove();
            // console.log($('body > div:gt(0)'))
            if (scr[0]) {
                // $('.content > script').remove()
                cash("body").off("click")
                $('#rubic_script').remove();
                // var old_element = document.getElementById("root");
                // var new_element = old_element.cloneNode(true);
                // old_element.parentNode.replaceChild(new_element, old_element);
            }
            var tag = document.createElement('script');
            tag.async = true;
            tag.src = '/lh-script.js'
            tag.id = 'rubic_script';
            document.body.appendChild(tag);
            tag.onload = () => {
                console.log('Load Script')
                resolve('Load Script')
            }
        } catch (e) {
            console.log(e);
            reject(e)
        }
    })
    return load;
}
//    function () {

//     try {
//         var scr = $('#rubic_script');
//         $('body > div:gt(0)').remove();
//         // console.log($('body > div:gt(0)'))
//         if (scr) {
//             // $('.content > script').remove()
//             $('body > script#rubic_script').remove();
//             // var old_element = document.getElementById("root");
//             // var new_element = old_element.cloneNode(true);
//             // old_element.parentNode.replaceChild(new_element, old_element);
//         }
//         var tag = document.createElement('script');
//         tag.async = true;
//         tag.src = '/lh-script.js'
//         tag.id = 'rubic_script';
//         document.body.appendChild(tag);
//         // $('.content').append(tag)
//         tag.onload = () => {
//             console.log('Load Script')
//         }

//     } catch (e) {
//         console.log(e);
//     }
// };
export const showNotify = (res) => {
    $('.modal').css("z-index",'5000')
    if (res.status == 'success')
        toast.success(res.msg, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
        });
    else
        toast.error(res.msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
        });
}
export const validation = (form, required) => {
    var valid = true;
    for (let i = 0; i < required.length; i++) {
        const element = required[i];
        console.log(element);
        if (form[element] == '' || !form[element]) {
            valid = false;
            alert('* ' + element + ' Wajib di Isi!');
            $('input[name="' + element + '"]').focus();
            break;
        }
    }
    // for (let i = 0; i < form.length; i++) {
    //     const element = form[i];
    //     if(required.includes(element['name']))
    //         if(element['value']=='') {
    //             valid = false;
    //             alert('* '+element['name']+' Wajib di Isi!');
    //             $('input[name="'+element['name']+'"]').focus();
    //             break;
    //         };
    // }
    return valid;
}
export const formatDate = (date) => {
    if (!date) return null;
    var d = date,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}
export function getFormData($form) {
    var unindexed_array = $form.serializeArray();
    var indexed_array = {};

    $.map(unindexed_array, function (n, i) {
        if (indexed_array[n['name']] != null) {
            if (!$.isArray(indexed_array[n['name']]))
                indexed_array[n['name']] = [indexed_array[n['name']]];
            indexed_array[n['name']][indexed_array[n['name']].length] = n['value']
        }
        else
            indexed_array[n['name']] = n['value'];
    });

    return indexed_array;
}
export const session = {
    saveSession(key, obj) {
        if (!obj) return false;
        sessionStorage.setItem(key, JSON.stringify(obj));
        return true;
    },
    getSession(key) {
        var obj = {};
        if (sessionStorage[key]) {
            obj = JSON.parse(sessionStorage[key]);
        }
        return obj;
    }
}
export const capitalizeFirstLetter = (string) => {
    if(!string) return '';
    return string.split(' ').map((e)=>e.charAt(0).toUpperCase() + e.slice(1)).join(' ');
}

export const initPicker = (name,cb,cb2,bulan =0) => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth()-bulan, date.getDate()-7);
    var lite = new Litepicker({
        element: document.getElementById(name),
        autoApply: false,
        singleMode: false,
        numberOfColumns: 2,
        numberOfMonths: 2,

        showWeekNumbers: true,
        format: "D MMM, YYYY",
        dropdowns: {
            minYear: 1990,
            maxYear: null,
            months: true,
            years: true,
        },
        setup: (picker) => {
            picker.on('button:apply',cb2);
        },

        tooltipNumber: (totalDays) => {
            return totalDays - 1;
        }
    })
    lite.setDateRange(firstDay, date, true);
    cb(lite)
}

