import { useState, useEffect } from 'react'
import { initPicker, loadScript, emit, debounce, formatDate, showNotify, setSelectValue } from '../context/socket'
import { LoremIpsum } from "react-lorem-ipsum";
import Litepicker from 'litepicker';
import TomSelect from 'tom-select';
import { reportSettlement } from '../context/common_function';
import moment from 'moment';
import Pagination from './Pagination';
import $ from 'jquery';
import { Link } from "react-router-dom";
import AHrefJavascript from './AHrefJavascript';
const Transaksi = ({ setPath }) => {
    const [transactions, setTransaction] = useState([])
    const [docsCounts, setDocsCounts] = useState(0)
    const [currentPage, setCurrentPage] = useState({
        number: 0,
        time: new Date().getTime()
    })
    const [pageSize, setPageSize] = useState(10)
    const [date, setDate] = useState(null)
    const [text, setText] = useState('')
    const [sort, setSort] = useState('createdAt')
    const [type, setType] = useState(-1)
    const [lite, setLite] = useState(null)
    const [filter, setFilter] = useState({
        status: 'semua',
        valid: 'semua',
    })
    useEffect(() => {
        setPath({ title: 'Tarik Dana', path: '/settlement' });
        loadScript().then(() => {
            initPicker('datepicker2', (data) => setLite(data), (date1, date2) => {
                setDate({ start: formatDate(date1), end: formatDate(date2) })
            }, 1)
        });
    }, [])
    useEffect(() => {
        const getUser = async () => {
            var endDate = new Date();
            var firstDay = new Date(endDate.getFullYear(), endDate.getMonth() - 1, endDate.getDate() - 7);
            var reqData = {
                'date': {
                    'start': date ? date.start : formatDate(firstDay),
                    'end': date ? date.end : formatDate(endDate),
                },
                'text': text,
                'page': currentPage.number,
                'size': pageSize,
                'sort': sort,
                'filter': filter,
                'type': parseInt(type.toString()),
            }
            emit('list_withdraw_admin', reqData, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setTransaction(response.data.docs)
                setDocsCounts(response.data.counts)
            });
        }
        if (currentPage.number != 0)
            getUser()
    }, [currentPage])
    useEffect(() => {
        setCurrentPage({ number: 1 })
    }, [pageSize, sort, type, filter, text, date])
    Array.prototype.randomStatus = function () {
        return this[Math.floor(Math.random() * this.length)];
    }
    const reset = () => {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        setSelectValue('valid', 'semua');
        setSelectValue('status', 'semua');
        setSelectValue('type', -1);
        setSelectValue('sort', 'semua');
        lite?.setDateRange(firstDay, date, true);
        $('#search').val('');
        setText('')
        setSort('createdAt')
        setType(-1)
        setFilter({
            status: 'semua',
            valid: 'semua',
        })
        setDate({ start: formatDate(firstDay), end: formatDate(date) })
    }
    const getStatus = (data) => {
        var label = '';
        switch (data) {
            case 'waiting':
                label = <label className="text-yellow-500">Menunggu Pembayaran</label>
                break;
            case 'success':
                label = <label className="text-green-500">Selesai</label>
                break;
            case 'decline':
                label = <label className="text-red-500">Di Tolak</label>
                break;
            default:
                break;
        }
        return label;
    }
    const search = (data) => {
        console.log(data.target.value)
        debounce(() => setText(data.target.value), 2000)
    }

    const handleFilter = (name, value) => {
        console.log(name, value)
        setFilter({ ...filter, [name]: value })
    }
    const exportSettlement = () => {
        var endDate = new Date();
        var firstDay = new Date(endDate.getFullYear(), endDate.getMonth() - 1, endDate.getDate() - 7);
        var reqData = {
            'date': {
                'start': date ? date.start : formatDate(firstDay),
                'end': date ? date.end : formatDate(endDate),
            },
            'text': text,
            'page': 1,
            'size': 10000,
            'sort': sort,
            'filter': filter,
            'type': parseInt(type.toString()),
        }
        emit('list_withdraw_admin', reqData, (response) => {
            if (response.response < 200 || response.response >= 300) return showNotify(response)
            reportSettlement(response.data.docs,reqData)
        });
    }
    return (
        <div className="intro-y">
            {/* <!-- END: Top Bar --> */}
            <div className='flex justify-between mt-5'>
                <h2 className="intro-y text-lg font-medium ">
                    Daftar Settlement
                </h2>
                <button onClick={exportSettlement} className="btn shadow-md btn-success">Export</button>
            </div>
            <div className="grid grid-cols-12 gap-6">
                <div className="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
                    <div className='mr-3'>Range Tanggal</div>
                    <div className="mr-auto mt-3 sm:mt-0 relative text-gray-700 dark:text-gray-300">
                        <i data-feather="calendar" className="w-4 h-4 z-10 absolute my-auto inset-y-0 ml-3 left-0"></i>
                        <input style={{ width: '115%' }} type="text" onChange={(e) => alert(e.target.value)} id="datepicker2" className="form-control box pl-10" />
                    </div>
                    <div className="w-full sm:w-auto flex mt-4 sm:mt-0">
                        <div className="w-56 relative text-gray-700 dark:text-gray-300">
                            <input id="search" type="text" onChange={search} className="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Search..." />
                            <i className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" data-feather="search"></i>
                        </div>
                    </div>
                </div>
                <div className="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center">
                    <div onChange={(e) => handleFilter('status', e.target.value)} className="dropdown mr-2 w-48 relative text-gray-700 dark:text-gray-300">
                        <select id="status" className="tom-select sm:mr-2" defaultValue={filter.status} placeholder="Status">
                            <option value={'semua'} label="Semua">Semua</option>
                            <option value={'success'}>Selesai</option>
                            <option value={'waiting'}>Menunggu Pembayaran</option>
                            <option value={'decline'}>Di Tolak</option>
                        </select>
                    </div>
                    <div onChange={(e) => handleFilter('valid', e.target.value)} className="dropdown mr-2 w-48 relative text-gray-700 dark:text-gray-300">
                        <select id="valid" className="tom-select sm:mr-2" defaultValue={filter.valid} placeholder="Status">
                            <option value={'semua'} label="Semua">Semua</option>
                            <option value={true}>Valid</option>
                            <option value={false}>Tidak Valid</option>
                        </select>
                    </div>
                    <div onChange={(e) => setSort(e.target.value)} className="dropdown ml-auto mr-2 w-48 relative text-gray-700 dark:text-gray-300">
                        <select id="sort" className="tom-select sm:mr-2" defaultValue={sort} placeholder="Urut Berdasarkan">
                            <option value={'createdAt'}>Tanggal Request</option>
                        </select>
                    </div>
                    <div onChange={(e) => setType(e.target.value)} className="dropdown relative text-gray-700 dark:text-gray-300">
                        <select id="type" className="tom-select sm:mr-2" defaultValue={type} placeholder="Urutan">
                            <option value={1}>A to Z</option>
                            <option value={-1}>Z to A</option>
                        </select>
                    </div>
                    <AHrefJavascript onClick={reset} href="#!" className="btn btn-primary shadow-md mr-2">Reset</AHrefJavascript>
                </div>
                {/* <!-- BEGIN: Data List --> */}
                <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
                    <table className="table table-auto table-report">
                        <thead>
                            <tr>
                                <th className="whitespace-nowrap">No.</th>
                                <th className="text-center whitespace-nowrap">Waktu Request</th>
                                <th className="text-center whitespace-nowrap">Nama Lawyer</th>
                                <th className="text-center whitespace-nowrap">Jumlah Penarikan</th>
                                <th className="text-center whitespace-nowrap">Nama Bank</th>
                                <th className="text-center whitespace-nowrap">Status Penarikan</th>
                                <th className="text-center whitespace-nowrap">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.map((d, i) => {
                                i *= 1
                                return (
                                    <tr key={++i} className="intro-x">
                                        <td className="w-1">
                                            <div className="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                                                {++i}
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            {moment(new Date(d.createdAt)).format('L HH:mm')}
                                        </td>
                                        <td className="w-1">
                                            <div className="flex">
                                                <Link to={'/lawyers/view/' + d.lawyer._id} className="w-10 h-10 image-fit zoom-in">
                                                    <img data-placement="right" className="rounded-full" src={d.lawyer.fullPhoto || "https://cdn-icons-png.flaticon.com/512/149/149071.png"} title="Foto" />
                                                </Link>
                                                <div className="text-gray-900 text-xs whitespace-nowrap mx-5 my-0.5" key={d._id}>
                                                    <Link to={'/lawyers/view/' + d.lawyer._id} className="font-bold">{d.lawyer.name}</Link><br></br>
                                                    <AHrefJavascript onClick={() => window.open('https://wa.me/' + d.lawyer.phone, '_blank').focus()} href='#!'>{d.lawyer.phone}</AHrefJavascript>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-center">
                                            {'Rp. ' + d.nominal.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}
                                        </td>
                                        <td className="text-center">
                                            {d.rekening.bank_name}<br></br>{d.rekening.account_number}
                                        </td>
                                        <td className="status text-center">
                                            {getStatus(d.status)}
                                        </td>
                                        <td className="table-report__action">
                                            <div className="flex justify-center items-center">
                                                <Link className="flex items-center mr-3" to={"/transactions/settlement/view/" + d._id}> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-external-link w-4 h-4 mr-1"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg> View </Link>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {/* <!-- END: Users Layout -->
                    <!-- BEGIN: Pagination --> */}
                <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage.number}
                        totalCount={docsCounts}
                        pageSize={pageSize}
                        onPageChange={page => setCurrentPage({ number: page })}
                    />
                    <div className='w-full'>
                        <div className="hidden md:block mx-auto text-gray-600">Showing {((currentPage.number * pageSize) - pageSize) + 1} to {(currentPage.number * pageSize)} of {docsCounts} entries</div>
                    </div>
                    <select defaultValue={10} onChange={(e) => setPageSize(parseInt(e.target.value))} style={{ width: '100px' }} className="form-select box mt-3 sm:mt-0">
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={35}>35</option>
                        <option value={50}>50</option>
                    </select>
                </div>
                {/* <!-- END: Pagination --> */}

            </div>
        </div>
    )
}

export default Transaksi

