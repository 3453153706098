import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import './index.css';
import App from './App';
import './css/app.css';
import './css/custom.css';
import 'react-toastify/dist/ReactToastify.css';


  ReactDOM.render(
    <BrowserRouter>
    <ToastContainer/>
      <App/>
    </BrowserRouter>,
    document.getElementById('root')
  );
