import { useState, useEffect } from 'react'
import $ from 'jquery';
import { socket, loadScript, emit, validation,getFormData,showNotify } from '../context/socket'
import { Link } from "react-router-dom";
import moment from 'moment';
import AHrefJavascript from './AHrefJavascript';

const DetailPengaduan = ({ setPath }) => {
    const [report, setReport] = useState({})
    const [refresh, setRefresh] = useState({})
    useEffect(() => {
        const getDetail = async (id) => {
            var reqData = {
                'idReport': id,
            }
            emit('detail_report', reqData, (response) => {
                setReport(response.data);
            });
        }
        getDetail(location.pathname.split('/').reverse()[0])
    }, [refresh])
    useEffect(() => {
        setPath({title:'Pengaduan',path:'/pengaduan/alter'});
        loadScript();
        $(()=>{
            $('#sendResponse').on('click',closeReport)
        })
    }, [])
    const newTo = (data) => {
        return { pathname: "/pengaduan/conversation/" + data.conversation._id }
    };
    const closeReport = ()=>{
        var required = $('#respon input,#respon textarea,#respon select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });
        var form2 = getFormData($('#respon'))
        if (!validation(form2, required)) return;
        var reqData = {
            idReport:form2.id,
            penyelesaian:form2.penyelesaian,
        }

        console.log(reqData)
        emit('response_report_user',reqData,(response)=>{
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            $('#closeModal').trigger('click');
            setRefresh(new Date().getTime())
        })
    }
    return (
        <>
            <div className="intro-y">
                {/* <!-- END: Top Bar --> */}
                <h2 className="intro-y text-lg font-medium my-5">
                    Report Detail
                </h2>
            </div>
            <div className="box px-20 pt-20 pb-5">
                {
                    $.isEmptyObject(report)?
                        <div>Loading...</div> :
                        <div>
                            <div className="font-bold text-xl">Pengaduan Percakapan #{report._id}</div>
                            <div className="text-md text-gray-400 pt-1">{moment(new Date(report.createdAt)).format('D MMMM YYYY HH:mm')}</div>
                            <div className="grid grid-cols-2 gap-4 py-10">
                                <div className="py-2">
                                    <div className="text-gray-400">Nama Pelapor</div>
                                    <div className="text-base font-bold py-1">{report.reporterUser? report.reporterUser.name:report.reporterLawyer.name}</div>
                                    <div className="py-1">{report.reporterUser? report.reporterUser.email:report.reporterLawyer.email}</div>
                                    <div className="py-1">{report.reporterUser? report.reporterUser.address:report.reporterLawyer.address}</div>
                                </div>
                                <div className="py-2">
                                    <div className="text-red-500">Nama Terlapor</div>
                                    <div className="text-base font-bold py-2">{report.reportedUser? report.reportedUser.name:report.reportedLawyer.name}</div>
                                    <div className="py-1">{report.reportedUser? report.reportedUser.email:report.reportedLawyer.email}</div>
                                    <div className="py-1">{report.reportedUser? report.reportedUser.address:report.reportedLawyer.address}</div>
                                </div>
                            </div>
                            <div>
                                <div className="py-2 font-bold">Deskripsi</div>
                                <hr/>
                                <div className="pt-4 font-bold text-blue-700">{report.keluhan}</div>
                                <div className="py-2">{report.description}</div>
                            </div>
                            <div>
                                <div className="py-2 font-bold">Penyelesaian</div>
                                <hr/>
                                <div className="py-2">{report.penyelesaian}</div>
                            </div>
                            <Link to={"/pengaduan/conversation/" + report.conversation._id} className="btn btn-primary shadow-md my-5">View Chat</Link>
                            <div className="flex justify-between mt-20">
                                <Link to="/pengaduan/alter" className="btn btn-secondary px-10">Back</Link>
                                {report.status=='active' && <AHrefJavascript href="#!" data-toggle="modal" data-target="#respon-modal" className="btn btn-success px-10">Close</AHrefJavascript>}
                            </div>
                        </div>
                }
            </div>
            <div id="respon-modal" className="modal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        {/* <!-- BEGIN: Modal Header --> */}
                        <div className="modal-header">
                            <h2 className="font-medium text-base mr-auto">Close Report User</h2>
                        </div>
                        {/* <!-- END: Modal Header --> */}
                        {/* <!-- BEGIN: Modal Body --> */}
                        <div className={`px-5 pt-5`}>
                            <div className={`text-gray-400`}>This user will recieve notification on their account.</div>
                            <br></br>
                            <form id='respon'>
                                <input type={'hidden'} name="id" value={report._id||''}></input>
                                <label htmlFor="modal-form-2" className="form-label">Penyelesaian</label>
                                <textarea id="modal-form-2" className="form-control h-24" 
                                // value={'Testing kirim notify 123123123123'}
                                 name="penyelesaian" placeholder="Tuliskan penyelesaian masalah yang akan admin lakukan" minLength="10" required></textarea>
                            </form>
                        </div>
                        {/* <!-- END: Modal Body --> */}
                        {/* <!-- BEGIN: Modal Footer --> */}
                        <div className="modal-footer">
                            <button id="closeModal" type="button" data-dismiss="modal" className="btn btn-outline-secondary w-20 mr-1">Cancel</button>
                            <button id="sendResponse" type="button" className="btn btn-primary w-20">Submit</button>
                        </div>
                        {/* <!-- END: Modal Footer --> */}
                    </div>
                </div>
            </div>
            <script src="https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js"></script>
        </>
    )
}

export default DetailPengaduan