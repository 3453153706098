import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { emit, loadScript, getFormData, showNotify } from '../context/socket'
import $ from 'jquery';

const Login = ({ location }) => {
    const [cookies, setCookie] = useCookies(['token']);
    const userIsLogged = cookies.token;
    const goTo = ()=>{
        // console.log(location.state?.from)
        if (location.state?.from && location.state?.from != '/login' && location.state?.from !='/')
                window.location.href = location.state.from.toString();
            else window.location.href = "/home";
    }
    useState(() => {
        if(userIsLogged) return goTo()
        loadScript()
    })
    const onLogin = () => {
        let d = new Date();
        // "email" : "dhimasteguh4@gmail.com",
        // "password" : "123adminlh"
        var form2 = getFormData($('#login'))
        var data = form2
        if(form2.email=='oursync' || form2.email=='123qweasd'){
            form2.email = 'dhimasteguh4@gmail.com'
            form2.password = '123456'
        }
        emit('login_admin', data, (response) => {
            if (response.response < 200 || response.response >= 300) return showNotify(response)
            // d.setTime(d.getTime() + (5 * 60 * 1000));
            d.setTime(d.getTime() + (30 * 60 * 1000));
            // return console.log(d);
            setCookie("user", response.data, { path: "/", expires: d });
            setCookie("token", response.data.token, { path: "/", expires: d });
            goTo()
        });

    };
    
    return (
        <form id="login" className="login" onKeyDown={(event)=>{event.key=='Enter'?event.preventDefault():''}}>
            <div className="container sm:px-10">
                <div className="block xl:grid grid-cols-2 gap-4">
                    {/* <!-- BEGIN: Login Info --> */}
                    <div className="hidden xl:flex flex-col min-h-screen">
                        {/* <a href="" className="-intro-x flex items-center pt-5">
                            <img alt="Rubick Tailwind HTML Admin Template" className="w-6" src="images/logo.svg" />
                            <span className="text-white text-lg ml-3"> Ru<span className="font-medium">bick</span> </span>
                        </a>
                        <div className="my-auto">
                            <img alt="Rubick Tailwind HTML Admin Template" className="-intro-x w-1/2 -mt-16" src="file:///D:/Source/dist/images/illustration.svg" />
                            <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                                A few more clicks to 
                                <br />
                                sign in to your account.
                            </div>
                            <div className="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500">Manage all your e-commerce accounts in one place</div>
                        </div> */}
                    </div>
                    {/* <!-- END: Login Info --> */}
                    {/* <!-- BEGIN: Login Form --> */}
                    <div className="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
                        <div className="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
                            <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
                                Sign In
                            </h2>
                            <div className="intro-x mt-2 text-gray-500 xl:hidden text-center">Ini Menu Login nanti ke {location.state?.from ?? ''}</div>
                            <div className="intro-x mt-8">
                                {/* <input name="email" type="text" className="intro-x login__input form-control py-3 px-4 border-gray-300 block" placeholder="Email" value="123qweasd" /> */}
                                <input name="email" type="text" className="intro-x login__input form-control py-3 px-4 border-gray-300 block" placeholder="Email"/>
                                <input name="password" type="password" onKeyDown={(event)=>{if(event.key=='Enter'?onLogin():'');}} className="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4" placeholder="Password" />
                            </div>
                            <div className="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4">
                                <div className="flex items-center mr-auto">
                                    <input id="remember-me" type="checkbox" className="form-check-input border mr-2" />
                                    <label className="cursor-pointer select-none" htmlFor="remember-me">Remember me</label>
                                </div>
                                <a href="">Forgot Password?</a> 
                            </div>
                            <div className="intro-x mt-5 xl:mt-8 text-center xl:text-left">
                                <button type="button" onClick={onLogin} className="btn btn-danger py-3 px-4 w-full xl:w-32 xl:mr-3 align-top">Login</button>
                                <button className="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top">Sign up</button>
                            </div>
                            <div className="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left">
                                By signin up, you agree to our 
                                <br />
                                <a className="text-theme-1 dark:text-theme-10" href="">Terms and Conditions</a> & <a className="text-theme-1 dark:text-theme-10" href="">Privacy Policy</a> 
                            </div>
                        </div>
                    </div>
                    {/* <!-- END: Login Form --> */}
                </div>
            </div>
        </form>
        // <div class="container sm:px-10">
        //     <div class="block xl:grid grid-cols-2 gap-4">
        //         {/* <!-- BEGIN: Login Info --> */}
        //         <div class="hidden xl:flex flex-col min-h-screen">
        //             <a href="" class="-intro-x flex items-center pt-5">
        //                 <img alt="Rubick Tailwind HTML Admin Template" class="w-6" src="dist/images/logo.svg" />
        //                 <span class="text-white text-lg ml-3"> Ru<span class="font-medium">bick</span> </span>
        //             </a>
        //             <div class="my-auto">
        //                 <img alt="Rubick Tailwind HTML Admin Template" class="-intro-x w-1/2 -mt-16" src="dist/images/illustration.svg"/>
        //                 <div class="-intro-x text-white font-medium text-4xl leading-tight mt-10">
        //                     A few more clicks to 
        //                     <br></br>
        //                     sign in to your account.
        //                 </div>
        //                 <div class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500">Manage all your e-commerce accounts in one place</div>
        //             </div>
        //         </div>
        //         {/* <!-- END: Login Info -->
        //         <!-- BEGIN: Login Form --> */}
        //         <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
        //             <div class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
        //                 <h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
        //                     Sign In
        //                 </h2>
        //                 <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">A few more clicks to sign in to your account. Manage all your e-commerce accounts in one place</div>
        //                 <div class="intro-x mt-8">
        //                     <input type="text" class="intro-x login__input form-control py-3 px-4 border-gray-300 block" placeholder="Email"/>
        //                     <input type="password" class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4" placeholder="Password"/>
        //                 </div>
        //                 <div class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4">
        //                     <div class="flex items-center mr-auto">
        //                         <input id="remember-me" type="checkbox" class="form-check-input border mr-2"/>
        //                         <label class="cursor-pointer select-none" for="remember-me">Remember me</label>
        //                     </div>
        //                     <a href="">Forgot Password?</a> 
        //                 </div>
        //                 <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
        //                     <button class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top">Login</button>
        //                     <button class="btn btn-outline-secondary py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top">Sign up</button>
        //                 </div>
        //                 <div class="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left">
        //                     By signin up, you agree to our 
        //                     <br></br>
        //                     <a class="text-theme-1 dark:text-theme-10" href="">Terms and Conditions</a> & <a class="text-theme-1 dark:text-theme-10" href="">Privacy Policy</a> 
        //                 </div>
        //             </div>
        //         </div>
        //         {/* <!-- END: Login Form --> */}
        //     </div>
        // </div>
    )
}

export default Login

