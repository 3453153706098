import React, { FC } from "react";
import { Route, Redirect } from "react-router-dom";
import { useCookies } from 'react-cookie';
const RouteRequiresLogin = props => {
   const [cookies, setCookie] = useCookies(['token']);
   const userIsLogged = cookies.token;
   // console.log(props);
   return (
      <Route {...props}>{userIsLogged ?
         props.children :
         <Redirect to={{ pathname: '/login', state: { from: location.pathname } }} />}
      </Route>
   );
};

export default RouteRequiresLogin;