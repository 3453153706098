import React from 'react'
import AHrefJavascript from './AHrefJavascript';

const CustomDialog = () => {
    return (
    <>
        <div id="custom-modal" className="modal" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    {/* <!-- BEGIN: Modal Header --> */}
                    <div className="modal-header">
                        <h2 id="title" className="font-medium text-base mr-auto">Title</h2>
                    </div>
                    {/* <!-- END: Modal Header --> */}
                    {/* <!-- BEGIN: Modal Body --> */}
                    <div id="subtitle" className='modal-header'>Subtitle</div>
                    {/* <!-- END: Modal Body --> */}
                    {/* <!-- BEGIN: Modal Footer --> */}
                    <div className="modal-footer">
                        <button id="closeDialog" type="button" data-dismiss="modal" className="btn btn-outline-secondary w-20 mr-1">Cancel</button>
                        <button id="submitDialog" type="button" className="btn btn-primary w-20">Submit</button>
                    </div>
                    {/* <!-- END: Modal Footer --> */}
                </div>
            </div>
        </div>
        <AHrefJavascript id="toggle-custom-modal" href="#!" data-toggle="modal" data-target="#custom-modal" className="hidden">toggle dialog</AHrefJavascript>
    </>
    )
}

export default CustomDialog