import { useState, useEffect, useContext } from 'react'
import { AppStateContext } from "../context/AppStateContext";
import { socket, loadScript, emit, showNotify } from '../context/socket'
import moment from 'moment';
import Pagination from './Pagination';
import $ from 'jquery';
import chart, { helpers } from "chart.js";
import { Link } from "react-router-dom";

export const Home = ({ setPath }) => {
    const [report, setReport] = useState([])
    const [general, setGeneral] = useState({})
    const [online, setOnline] = useState({})
    const [pendapatan, setPendapatan] = useState({})
    const [lawyer, setLawyer] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [status, setStatus] = useState('Tahun Ini')
    const [pageSize, setPageSize] = useState(5)
    const [currentPage, setCurrentPage] = useState({
        number:0,
        time:new Date().getTime()
    })
    const [docsCounts, setDocsCounts] = useState(0)
    const { toggle } = useContext(AppStateContext);

    useEffect(() => {
        
        const getGeneral = async () => {
            emit('general_report', {}, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setGeneral(response.data)
            });
        }
        const getTopLawyer = async () => {
            emit('top_lawyers', {}, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setLawyer(response.data)
            });
        }
        const getOnline = async () => {
            emit('online_users', {}, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setOnline(response.data)
                createBarOnline()
            });
        }

        getGeneral()
        getTopLawyer()
        getOnline()
    }, [refresh])
    useEffect(() => {
        const getPendapatan = async () => {
            emit('pendapatan', { status: status }, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setPendapatan(response.data)
                createPendapatanChart(response.data)
            });
        }
        getPendapatan()
    }, [status])
    useEffect(() => {
        setPath({title:'Dashboard',path:'/home'});
        loadScript();
    }, [])
    const getReport = async (page) => {
        var reqData = {
            'name': "",
            'page': page.number,
            'size': pageSize,
            'status': "active"
        }
        emit('list_reports', reqData, (response) => {
            if (response.response < 200 || response.response >= 300) return showNotify(response)
            setReport(response.data.docs)
            setDocsCounts(response.data.counts)
        });
    }
    useEffect(() => {
        setCurrentPage({number:1})
    }, [pageSize])
    useEffect(() => {
        if(currentPage.number!=0)
        getReport(currentPage)
    }, [currentPage])
    const btnRefresh = () => {
        setRefresh(!refresh)
    }
    const updateStatus = (data) => {
        console.log(data.target.value)
        setStatus(data.target.value)
    }
    const createBarOnline = () => {
        let reportBarChartData = [3]

        // Fake visitor bar color
        let reportBarChartColor = [color(3)]

        let ctx = $("#report-bar-chart-test2")[0].getContext("2d");
        let myBarChart = new chart(ctx, {
            type: "bar",
            data: {
                labels: reportBarChartData,
                datasets: [
                    {
                        label: "Html Template",
                        barPercentage: 1,
                        categoryPercentage: 1,
                        barThickness: 6,
                        maxBarThickness: 8,
                        minBarLength: 2,
                        data: reportBarChartData,
                        backgroundColor: reportBarChartColor,
                    },
                ],
            },
            options: {
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                display: false,
                            },
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                display: false,
                            },
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                },
            },
        });
        function color(data) {
            if (data >= 8 && data <= 14) {
                return "#1c3faaa6";
            } else if (data >= 15) {
                return "#1C3FAA";
            }

            return "#1c3faa59";
        }
        socket.on('online', (response) => {
            // response  = Math.ceil(Math.random() * (0 - 20) + 20);
            console.log('Ada yang online ', response, reportBarChartData[reportBarChartData.length - 1])
            if (response == reportBarChartData[reportBarChartData.length - 1]) return;
            if (reportBarChartData.length >= 40)
                reportBarChartData.shift();
            reportBarChartData.push(response);
            if (reportBarChartData.length >= 40)
                reportBarChartColor.shift();
            reportBarChartColor.push(color(response));
            setOnline(p => ({
                ...p, users: response
            }))
            myBarChart.update();
        })
    }
    const createPendapatanChart = (data) => {
        console.log('create chart', data)
        let ctx = $("#report-bar-chart-test")[0].getContext("2d");
        let myChart = new chart(ctx, {
            type: "bar",
            data: {
                labels: data.summary.map((e) => e.tanggal + `(${e.jumlah})`),
                datasets: [
                    {
                        label: "Pendapatan",
                        borderWidth: 2,
                        borderColor: "transparent",
                        barPercentage: 0.5,
                        barThickness: 8,
                        maxBarThickness: 6.5,
                        minBarLength: 2,
                        data: data.summary.map((e) => e.total),
                        backgroundColor: "#F51903",
                    },
                ],
            },
            options: {
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                fontSize: 11,
                                fontColor: "#718096",
                            },
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                display: false,
                            },
                            gridLines: {
                                color: "#D8D8D8",
                                zeroLineColor: "#D8D8D8",
                                borderDash: [2, 2],
                                zeroLineBorderDash: [2, 2],
                                drawBorder: false,
                            },
                        },
                    ],
                },
            },
        });
    }
    const responsive2xl = (type) => {
        return toggle ? '2xl:' + type : 'xl:' + type;
    }
    const responsiveXl = (type) => {
        return toggle ? 'xl:' + type : 'lg:' + type;
    }
    return (
        <div className="grid grid-cols-12 gap-6">
            <div className={`col-span-12 ${responsive2xl('col-span-9')}`}>
                <div className="grid grid-cols-12 gap-6">
                    <div className="col-span-12 mt-8">
                        <div className="intro-y flex items-center h-10">
                            <h2 className="text-lg font-medium truncate mr-5">
                                General Report
                            </h2>
                            <a onClick={btnRefresh} href="" className="ml-auto flex items-center text-theme-1 dark:text-theme-10"> <i data-feather="refresh-ccw" className="w-4 h-4 mr-3"></i> Reload Data </a>
                        </div>
                        <div className="grid grid-cols-12 gap-6 mt-5">
                            <Link to="/users" className={`col-span-12 sm:col-span-6 ${responsiveXl('col-span-3')} intro-y`}>
                                <div className="report-box zoom-in">
                                    <div className="box p-5">
                                        <div className="flex">
                                            <i data-feather="users" className="report-box__icon text-theme-10"></i>
                                            {/* <div className="ml-auto">
                                            <div className="report-box__indicator bg-theme-9 cursor-pointer" title="33% Higher than last month"> 33% <i data-feather="chevron-up" className="w-4 h-4 ml-0.5"></i> </div>
                                        </div> */}
                                        </div>
                                        <div className="text-3xl font-medium leading-8 mt-6">{general.totalUser ?? 0}</div>
                                        <div className="text-base text-gray-600 mt-1">Registered User</div>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/lawyers" className={`col-span-12 sm:col-span-6 ${responsiveXl('col-span-3')} intro-y`}>
                                <div className="report-box zoom-in">
                                    <div className="box p-5">
                                        <div className="flex">
                                            <i data-feather="briefcase" className="report-box__icon text-theme-11"></i>
                                            {/* <div className="ml-auto">
                                            <div className="report-box__indicator bg-theme-6 cursor-pointer" title="2% Lower than last month"> 2% <i data-feather="chevron-down" className="w-4 h-4 ml-0.5"></i> </div>
                                        </div> */}
                                        </div>
                                        <div className="text-3xl font-medium leading-8 mt-6">{general.totalLawyer ?? 0}</div>
                                        <div className="text-base text-gray-600 mt-1">Total Lawyer</div>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/transactions" className={`col-span-12 sm:col-span-6 ${responsiveXl('col-span-3')} intro-y`}>
                                <div className="report-box zoom-in">
                                    <div className="box p-5">
                                        <div className="flex">
                                            <i data-feather="shopping-cart" className="report-box__icon text-theme-12"></i>
                                            {/* <div className="ml-auto">
                                            <div className="report-box__indicator bg-theme-9 cursor-pointer" title="12% Higher than last month"> 12% <i data-feather="chevron-up" className="w-4 h-4 ml-0.5"></i> </div>
                                        </div> */}
                                        </div>
                                        <div className="text-3xl font-medium leading-8 mt-6">{general.totalTransaksi ?? 0}</div>
                                        <div className="text-base text-gray-600 mt-1">Total Transaksi</div>
                                    </div>
                                </div>
                            </Link>
                            <Link to={{pathname:"/lawyers",state: { online: true }}} className={`col-span-12 sm:col-span-6 ${responsiveXl('col-span-3')} intro-y`}>
                                <div className="report-box zoom-in">
                                    <div className="box p-5">
                                        <div className="flex">
                                            <i data-feather="user" className="report-box__icon text-theme-9"></i>
                                            {/* <div className="ml-auto">
                                            <div className="report-box__indicator bg-theme-9 cursor-pointer" title="22% Higher than last month"> 22% <i data-feather="chevron-up" className="w-4 h-4 ml-0.5"></i> </div>
                                        </div> */}
                                        </div>
                                        <div className="text-3xl font-medium leading-8 mt-6">{general.lawyerOnline ?? 0}</div>
                                        <div className="text-base text-gray-600 mt-1">Lawyer Online Saat Ini</div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    {/* <!-- END: General Report --> */}
                    {/* <!-- BEGIN: Weekly Top Products --> */}
                    {/* <td>
                </div>
                {/* <!-- END: General Report --> */}
                    {/* <!-- BEGIN: Weekly Top Products --> */}
                    <div className="col-span-12 mt-6">
                        <div className="intro-y block sm:flex items-center h-10">
                            <h2 className="text-lg font-medium truncate mr-5">
                                Report List
                            </h2>
                        </div>
                        <div className={`intro-y overflow-auto ${responsiveXl('overflow-visible')} mt-8 sm:mt-0`}>
                            <table className="table table-report sm:mt-2">
                                <thead>
                                    <tr>
                                        <th className="whitespace-nowrap">Nama Pelapor</th>
                                        {/* <th className="whitespace-nowrap">Alasan</th> */}
                                        <th className="text-center whitespace-nowrap">Nama Terlapor</th>
                                        <th className="text-center whitespace-nowrap">Waktu</th>
                                        <th className="text-center whitespace-nowrap">ACTIONS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {report.map((d, i) => {
                                        i *= 1
                                        var data = d.reportedLawyer ? d.reportedLawyer : d.reportedUser;
                                        var data2 = d.reporterLawyer ? d.reporterLawyer : d.reporterUser;
                                        return (
                                            <tr key={i} className="intro-x">
                                                <td className="w-1">
                                                    <div className="flex">
                                                        <div className="w-10 h-10 image-fit zoom-in">
                                                            <img data-placement="right" className="rounded-full" src={data2.fullPhoto || "https://cdn-icons-png.flaticon.com/512/149/149071.png"} title="Foto" />
                                                        </div>
                                                        <div className="text-gray-900 text-xs whitespace-nowrap mx-5 my-0.5" key={data2._id}>
                                                            <div className="font-bold">{data2.name}</div>
                                                            <div>{data2.email}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                {/* <td>
                                        <div>{d.keluhan}</div>
                                        <div>{d.description}</div>
                                    </td> */}
                                                <td className="w-1">
                                                    <div className="flex">
                                                        <div className="w-10 h-10 image-fit zoom-in">
                                                            <img className="rounded-full" src={data.fullPhoto || "https://cdn-icons-png.flaticon.com/512/149/149071.png"} title="Foto" />
                                                        </div>
                                                        <div className="text-gray-900 text-xs whitespace-nowrap mx-5 my-0.5" key={data._id}>
                                                            <div className="font-bold">{data.name}</div>
                                                            <div>{data.email}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="w-40">
                                                    <div className="flex items-center justify-center">
                                                        {moment(new Date(d.createdAt)).format('L')}
                                                    </div>
                                                </td>
                                                <td className="table-report__action w-56">
                                                    <div className="flex justify-center items-center">
                                                        <Link to={"/pengaduan/view/" + d._id} className="flex items-center mr-3" href=""> <i data-feather="check-square" className="w-4 h-4 mr-1"></i> View </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="intro-y flex flex-wrap sm:flex-row sm:flex-nowrap items-center mt-3">
                            <Pagination
                                className="pagination-bar"
                                currentPage={currentPage.number}
                                totalCount={docsCounts}
                                pageSize={5}
                                onPageChange={page => setCurrentPage({number:page})}
                            />
                        </div>
                    </div>
                    <div className="col-span-12 mt-6">
                        <div className="intro-y block sm:flex items-center h-10">
                            <h2 className="text-lg font-medium truncate mr-5">
                                Top Lawyer
                            </h2>
                        </div>
                        <div className="grid grid-cols-10 gap-5 mt-5 ">
                            {lawyer.map((e) => {
                                return (
                                    <Link to={'/lawyers/view/' + e._id} key={e._id} className="col-span-3 lg:col-span-2 box p-5 cursor-pointer zoom-in">
                                        <img style={{ aspectRatio: '1 / 1', objectFit: 'cover' }} className="w-full rounded-full" src={e.fullPhoto || "https://cdn-icons-png.flaticon.com/512/149/149071.png"} title="Foto" />
                                        <br></br>
                                        <div className="font-medium text-center text-lg">{e.name}</div>
                                        <div className="text-gray-600 text-center">{e.address}</div>
                                        <div className='flex justify-center items-center'>
                                            <span className="material-icons text-red-500">star_border</span>
                                            <div className='text-lg'>{(e.rating ?? 0).toFixed(2)}</div>
                                        </div>
                                        <br></br>
                                        <div className='text-lg text-center' style={{ color: '#7FBE1D' }}>Rp {((e.saldo ?? 0) / 1000).toFixed(0).toLocaleString()}K</div>
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                    {/* <!-- END: Weekly Top Products --> */}
                </div>
            </div>
            <div className={`col-span-12 ${responsive2xl('col-span-3')}`}>
                <div className={`${responsive2xl('border-l')} ${responsive2xl('pl-6')} border-theme-5 -mb-10 pb-10`}>
                    <div className="intro-x flex items-center h-10">
                        <h2 className="text-lg font-medium truncate">
                            Total Pemasukan
                        </h2>
                    </div>
                    <br></br>
                    <div className="box row-start-2 p-4">
                        <div className="flex flex-wrap items-center">
                            <select onChange={updateStatus} className="form-select bg-transparent border-black border-opacity-10 dark:bg-transparent dark:border-opacity-100 dark:border-dark-5 mx-auto sm:mx-0 py-1.5 px-3 w-auto mt-2 -mb-2">
                                <option value="tahun ini">Tahun Ini</option>
                                <option value="bulan ini">Bulan Ini</option>
                                <option value="minggu ini">Minggu Ini</option>
                                <option value="bulan lalu">Bulan Lalu</option>
                                <option value="tahun lalu">Tahun Lalu</option>
                            </select>
                        </div>
                        <div className="mt-10 text-gray-700 dark:text-gray-500">
                            <div className={`relative text-xl ${responsive2xl('text-2xl')} font-medium leading-6 ${responsive2xl('leading-5')} pl-3.5 ${responsive2xl('pl-4')}`}><span className="">Rp. {(pendapatan.total ?? 0).toLocaleString()}</span> </div>
                            <div className={`${responsive2xl('text-xs')} py-2`}>Total pendapatan pada {pendapatan.type?.toLowerCase()}</div>
                        </div>
                        <canvas className="mt-6" id="report-bar-chart-test" height="267"></canvas>
                    </div>
                </div>
                <div className={`${responsive2xl('border-l')} ${responsive2xl('pl-6')} border-theme-5`}>
                    {/* <!-- BEGIN: Transactions --> */}
                    <div className={`col-span-12 md:col-span-6  ${responsiveXl('col-span-4')} ${responsive2xl('col-span-12')} mt-3 ${responsive2xl('mt-8 ')}`}>
                        <div className="intro-x flex items-center h-10">
                            <h2 className="text-lg font-medium truncate">
                                Online User
                            </h2>
                        </div>
                        <div className="mt-5">
                            <div className="report-box-2 intro-y mt-5">
                                <div className="box p-5">
                                    <div className="text-2xl font-medium mt-2">{online.users}</div>
                                    <div className="mt-2 border-b broder-gray-200">
                                        <div className="-mb-1.5 -ml-2.5">
                                            <canvas id="report-bar-chart-test2" height="111"></canvas>
                                        </div>
                                    </div>
                                    <div className="text-gray-600 dark:text-gray-600 text-xs border-b border-gray-200 flex mb-2 pb-2 mt-4">
                                        <div>Top Category Kasus</div>
                                        <div className="ml-auto">Konsultasi</div>
                                    </div>
                                    {online.page?.slice(0, 5).map((e, i) => {
                                        return (
                                            <div key={i} className="flex mt-1.5">
                                                <div>{e.name}</div>
                                                <div className="ml-auto">{e.jumlah}</div>
                                            </div>
                                        )
                                    })}
                                    <Link to='/kategorikasus' className="btn btn-outline-secondary border-dashed w-full py-1 px-2 mt-4">Lihat Semua</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- END: Transactions -->*/}
                </div>
            </div>
        </div>
    )
}
export default Home;
