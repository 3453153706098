import { useState, useEffect, useContext } from 'react'
import { AppStateContext } from "../context/AppStateContext";
import { Link } from "react-router-dom";
import { socket, loadScript, emit, validation, getFormData, debounce, showNotify } from '../context/socket'
import Pagination from './Pagination';
import AHrefJavascript from './AHrefJavascript';
import $ from 'jquery';
import { reportLawyer } from '../context/common_function';
import TomSelect from 'tom-select';
import moment from 'moment';
// import * as  excel4node from 'excel4node';
import { CSVLink, CSVDownload } from "react-csv";
const Lawyer = ({ setPath, location }) => {
    const { notify, setNotify } = useContext(AppStateContext);
    const [users, setUsers] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState({
        number: 0,
        time: new Date().getTime()
    })
    const [docsCounts, setDocsCounts] = useState(0)
    const [text, setText] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [filter, setFilter] = useState(location.state?.online ? 'available' : 'createdAt')
    const [type, setType] = useState(-1)
    useEffect(() => {
        setCurrentPage({ number: 1 })
    }, [pageSize, text, filter, type, refresh])
    useEffect(() => {
        // if(location.state?.online){

        // }
        const getUser = async () => {
            var reqData = {
                'text': text,
                'page': currentPage.number,
                'size': pageSize,
                'filter': filter,
                'type': parseInt(type.toString()),
            }
            emit('get_lawyers', reqData, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setUsers(response.data.docs);
                setDocsCounts(response.data.counts)
            });
        }
        if (currentPage.number != 0)
            getUser()
    }, [currentPage])
    useEffect(() => {
        setPath({ title: 'Lawyer', path: '/lawyers' });
        loadScript();
        $(() => {
            $('#saveRekomen').on('click', () => {
                saveRekomen()
            })
        })
    }, [])
    const searchUser = (data) => {
        console.log(data.target.value)
        debounce(() => setText(data.target.value), 2000)
    }
    const newTo = (data) => {
        return { pathname: "/lawyers/view/" + data._id }
    };

    const saveRekomen = () => {
        const hasDuplicates = (arr) => arr.length !== new Set(arr).size;
        var form2 = getFormData($('#advocat'))
        console.log(form2)
        var ids = Object.keys(form2).map((k) => form2[k])
        console.log(hasDuplicates(ids))
        if (hasDuplicates(ids)) return showNotify({ status: 'error', msg: 'Lawyer Tidak Boleh Double' })
        emit('set_recomend_lawyer', { ids: ids }, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            $('#closeModal').trigger('click');
            setRefresh(!refresh)
        });
    }
    const createSelect = () => {
        [1, 2, 3, 4].forEach((e) => {
            var select = document.getElementById('advocat' + e)
            if (!select) return;
            var control = select.tomselect;
            if (control) control.destroy();
            new TomSelect('#advocat' + e, {
                valueField: '_id',
                labelField: 'name',
                searchField: ['name', 'email'],
                onInitialize: function (params) {
                    emit('get_rekomend_lawyer', {}, (response) => {
                        if (response.data.docs.length < e) return;
                        var select = document.getElementById('advocat' + e)
                        var control = select.tomselect;
                        control.addOption(response.data.docs[e - 1]);
                        control.addItem(response.data.docs[e - 1]._id);
                    })
                },
                // fetch remote data
                load: function (query, callback) {
                    var reqData = {
                        'name': query,
                        'page': 1,
                        'size': 1000,
                    }
                    emit('search_lawyer', reqData, (response) => {
                        if (response.response < 200 || response.response >= 300) return showNotify(response)
                        console.log(response.data.docs)
                        callback(response.data.docs);
                    });
                },
                // custom rendering functions for options and items
                render: {
                    option: function (item, escape) {
                        return `<div class="py-2 flex">
                                <div class="icon mr-3">
                                    <img style="aspect-ratio: 1 / 1" class="aspect-square object-cover image-fit rounded-full w-10" src="${item.fullPhoto}" />
                                </div>
                                <div>
                                    <div class="mb-1">
                                        <span class="h4">
                                            ${escape(item.name)}
                                        </span>
                                        <span class="text-muted">by ${escape(item.email)}</span>
                                    </div>
                                     <div class="description">${escape(item.phone)}</div>
                                </div>
                            </div>`;
                    },
                    item: function (item, escape) {
                        return `<div class="py-2 w-full items-center" style="display:flex !important">
                                    <div class="icon mr-3">
                                        <img style="aspect-ratio: 1 / 1" class="image-fit object-cover  rounded-full w-14" src="${item.fullPhoto}" />
                                    </div>
                                    <div class="w-full mr-auto">
                                        <div class="">
                                            <span class="h4">
                                                ${escape(item.name)}, ${escape(item.email)}
                                            </span>
                                        </div>
                                        <div class="description">Rp. ${(item.price ?? 0).toLocaleString()} (${escape(item.phone)})</div>
                                    </div>
                                    <span onlick="document.getElementById('advocat' + ${e}).tomselect.removeItem('${item._id}');document.getElementById('advocat' + ${e}).value = null;" id="remove:${e}" class="material-icons cursor-pointer">cancel</span>
                                </div>`;
                    }
                },
            });
        })
        emit('get_rekomend_lawyer', {}, (response) => {

        })
    }
    
    const exportUser = () => {
        // var xl = excel4node();
        var reqData = {
            'text': text,
            'page': currentPage.number,
            'size': pageSize,
            'filter': filter,
            'type': parseInt(type.toString()),
        }
        emit('get_lawyers', reqData, (response) => {
            if (response.response < 200 || response.response >= 300) return showNotify(response)
            reportLawyer(response.data.docs);
        });
    }
    return (
        <>
            <div className="intro-y">
                {/* <!-- END: Top Bar --> */}
                <div className='flex justify-between mt-5'>
                    <h2 className="intro-y text-lg font-medium ">
                        Daftar Lawyer
                    </h2>
                    <button onClick={exportUser} className="btn shadow-md btn-success">Export</button>
                </div>

                <div className="grid grid-cols-12 gap-6 mt-5">
                    <div className="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
                        <Link to="/lawyers/add">
                            <button className="btn shadow-md mr-2 bg-white">Tambah Lawyer</button>
                        </Link>
                        <AHrefJavascript onClick={createSelect} href="#!" data-toggle="modal" data-target="#recomend-modal" className="btn bg-white shadow-md mr-2">Rekomendasi Lawyer</AHrefJavascript>
                        <div className="ml-auto sm:w-auto flex mt-4 sm:mt-0">
                            <div onChange={(e) => setFilter(e.target.value)} className="dropdown mr-2 w-56 relative text-gray-700 dark:text-gray-300">
                                <select className="tom-select sm:mr-2" defaultValue={filter} placeholder="Urut Berdasarkan">
                                    <option value={'lastLogin'}>Terakhir Login</option>
                                    <option value={'name'}>Nama</option>
                                    <option value={'createdAt'}>Tanggal Registrasi</option>
                                    <option value={'rating'}>Rating</option>
                                    <option value={'price'}>Harga Konsultasi</option>
                                    <option value={'available'}>Online</option>
                                </select>
                            </div>
                            <div onChange={(e) => setType(e.target.value)} className="dropdown mr-2 relative text-gray-700 dark:text-gray-300">
                                <select className="tom-select sm:mr-2" defaultValue={type} placeholder="Urutan">
                                    <option value={1}>A to Z</option>
                                    <option value={-1}>Z to A</option>
                                </select>
                            </div>
                            <div className="w-56 relative text-gray-700 dark:text-gray-300">
                                <input type="text" onChange={searchUser} className="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Cari Nama User/ Email" />
                                <i className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" data-feather="search"></i>
                            </div>
                        </div>
                    </div>
                    {/* <!-- BEGIN: Data List --> */}
                    <div className="intro-y col-span-12 overflow-auto">
                        <table className="table table-report -mt-2">
                            <thead>
                                <tr>
                                    <th className="whitespace-nowrap">No.</th>
                                    <th className="whitespace-nowrap" colSpan="2">Nama Lawyer</th>
                                    <th className="text-center whitespace-nowrap">Kategori</th>
                                    <th className="text-center whitespace-nowrap">Tanggal Registrasi</th>
                                    <th className="text-center whitespace-nowrap">Harga Konsultasi</th>
                                    <th className="text-center whitespace-nowrap">Rating</th>
                                    <th className="text-center whitespace-nowrap">ACTIONS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.length == 0 && <tr><th colSpan="100%"><div className='text-center w-full'>Data Tidak Ditemukan</div></th></tr>}
                                {users.map((d, i) => {
                                    i *= 1
                                    ++i
                                    return (
                                        <tr className={"intro-x"} key={i}>
                                            <td className="w-1">
                                                <div className="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                                                    {d.pinned ? <span className="material-icons text-red-400">push_pin</span> : i}

                                                </div>
                                            </td>
                                            <td className="w-1">
                                                <div className="flex">
                                                    <div className="w-10 h-10 flex-none image-fit zoom-in mr-1">
                                                        <img className="tooltip rounded-full" src={d.fullPhoto || "https://cdn-icons-png.flaticon.com/512/149/149071.png"} title="Foto" />
                                                        <div className={"w-3 h-3 absolute right-0 bottom-0 rounded-full border-2 border-white" + (d.hasConv ? ' bg-yellow-500 ' : d.available ? ' bg-green-500 ' : ' bg-red-500 ')}></div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="text-gray-600 text-xs whitespace-nowrap mt-0.5" key={d.name}>
                                                    {d.name}
                                                </div>
                                            </td>
                                            <td className="text-center">{d.specialist}</td>
                                            <td className="text-center">{moment(d.createdAt).format('L')}</td>
                                            <td className="text-center">
                                                {'Rp. ' + (d.price ?? 0).toLocaleString()}
                                            </td>
                                            <td className="text-center">{d.rating.toFixed(2)}</td>
                                            <td className="table-report__action">
                                                <div className="flex justify-center items-center">
                                                    <Link to={newTo(d)} className="flex items-center mr-3">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-square w-4 h-4 mr-1"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>
                                                        View
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    {/* <!-- BEGIN: Pagination --> */}
                    <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage.number}
                            totalCount={docsCounts}
                            pageSize={pageSize}
                            onPageChange={page => setCurrentPage({ number: page })}
                        />
                        <div className='w-full'>
                            <div className="hidden md:block mx-auto text-gray-600">Showing {((currentPage.number * pageSize) - pageSize) + 1} to {(currentPage.number * pageSize)} of {docsCounts} entries</div>
                        </div>
                        <select defaultValue={10} onChange={(e) => setPageSize(parseInt(e.target.value))} style={{ width: '100px' }} className="w-20 form-select box mt-3 sm:mt-0">
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={35}>35</option>
                            <option value={50}>50</option>
                        </select>
                    </div>
                    {/* <!-- END: Pagination --> */}

                </div>
            </div>
            <div id="recomend-modal" className="modal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        {/* <!-- BEGIN: Modal Body --> */}
                        <div className={`px-5 pt-5`}>
                            <form id='advocat'>
                                <label htmlFor="advocat1" className="form-label">Advocate 1</label>
                                <select id="advocat1" name='idLawyer1' className="mb-4" required placeholder="Cari Lawyer">
                                </select>
                                <label htmlFor="advocat2" className="form-label">Advocate 2</label>
                                <select id="advocat2" name='idLawyer2' className="mb-4" required placeholder="Cari Lawyer">
                                </select>
                                <label htmlFor="advocat3" className="form-label">Advocate 3</label>
                                <select id="advocat3" name='idLawyer3' className="mb-4" required placeholder="Cari Lawyer">
                                </select>
                                <label htmlFor="advocat4" className="form-label">Advocate 4</label>
                                <select id="advocat4" name='idLawyer4' className="mb-4" required placeholder="Cari Lawyer">
                                </select>
                                {/* <label htmlFor="modal-form-2" className="form-label">Description</label>
                                <textarea id="modal-form-2" className="form-control"
                                    // value={'Testing kirim notify 123123123123'}
                                    name="description" placeholder="Max 120 Character" minLength="10" maxLength={120} required></textarea> */}
                            </form>
                        </div>
                        {/* <!-- END: Modal Body --> */}
                        {/* <!-- BEGIN: Modal Footer --> */}
                        <div className="modal-footer">
                            <button id="closeModal" type="button" data-dismiss="modal" className="btn btn-outline-secondary w-20 mr-1">Cancel</button>
                            <button id="saveRekomen" type="button" className="btn btn-primary w-20">Send</button>
                        </div>
                        {/* <!-- END: Modal Footer --> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Lawyer

