import { useState, useEffect, useContext } from 'react'
import { socket, loadScript, emit,debounce, validation, getFormData, showNotify } from '../context/socket'
import Pagination from './Pagination';
import AHrefJavascript from './AHrefJavascript';
import moment from 'moment';
import { Link } from "react-router-dom";

import $ from 'jquery';
const Admin = ({ setPath }) => {
    const [admins, setAdmins] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState({
        number:0,
        time:new Date().getTime()
    })
    const [docsCounts, setDocsCounts] = useState(0)
    const [text, setText] = useState('')
    const getUser = async (page) => {
        var reqData = {
            'text': text,
            'page': page.number,
            'size': pageSize,
        }
        emit('list_admin', reqData, (response) => {
        if (response.response < 200 || response.response >= 300) return showNotify(response)
        setAdmins(response.data.docs);
            setDocsCounts(response.data.counts)
        });
    }
    useEffect(() => {
        setCurrentPage({number:1})
    }, [pageSize, text])
    useEffect(() => {
        if(currentPage.number!=0)
        getUser(currentPage)
    }, [currentPage])
    useEffect(() => {
        setPath({title:'Admin',path:'/admins'});
        // setPath('/admins');
        loadScript();
        $(() => {

        })
    }, [])
    const searchUser = (data) => {
        console.log(data.target.value)
        debounce(() => setText(data.target.value), 2000)
    }
    return (
        <>
            <div className="intro-y">
                {/* <!-- END: Top Bar --> */}
                <h2 className="intro-y text-lg font-medium mt-10">
                    {'User & Roles'}
                </h2>
                <div className="grid grid-cols-12 gap-6 mt-5">
                    <div className="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
                        <Link to="/admins/create">
                            <button className="btn shadow-md mr-2 bg-white">Add New User</button>
                        </Link>
                        <div className="hidden md:block mx-auto text-gray-600">Showing {((currentPage.number * pageSize) - pageSize) + 1} to {(currentPage.number * pageSize)} of {docsCounts} entries</div>

                        <div className="w-full sm:w-auto flex mt-4 sm:mt-0">
                            <div className="w-56 relative text-gray-700 dark:text-gray-300">
                                <input type="text" onChange={searchUser} className="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Cari Nama User/ Email" />
                                <i className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" data-feather="search"></i>
                            </div>
                        </div>
                    </div>
                    {/* <!-- BEGIN: Data List --> */}
                    {admins.map((d, i) => {
                        return (
                            <div key={i} className="intro-y p-3 flex box col-span-6">
                                <div className="w-10 h-10 image-fit">
                                    <img alt="Rubick Tailwind HTML Admin Template" className="rounded-full" src={d.fullPhoto || 'https://cdn-icons-png.flaticon.com/512/149/149071.png'} />
                                </div>
                                <div className="text-gray-900 mr-auto text-xs whitespace-nowrap mx-5 my-0.5" key={d._id}>
                                    <div className="font-bold">{d.name} <span className='text-gray-400'>({d.email})</span></div>
                                    <div className='text-gray-400'>{d.role}</div>
                                </div>
                                <div className='flex items-center'>
                                    <Link to={"/admins/edit/"+d._id} type="button" className="btn border py-1 rounded-md hover:bg-gray-300 mr-2">Edit</Link>
                                    <button type="button" className="btn btn-danger border py-1 hover:bg-red-800 rounded-md">Delete</button>
                                </div>
                            </div>
                        );
                    })}
                    {/* <!-- BEGIN: Pagination --> */}
                    <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
                        <Pagination
                            className="pagination-bar"
                            currentPage={currentPage.number}
                            totalCount={docsCounts}
                            pageSize={pageSize}
                            onPageChange={page => setCurrentPage({number:page})}
                        />
                        <div className='w-full'></div>
                        <select defaultValue={10} onChange={(e) => setPageSize(parseInt(e.target.value))} style={{ width: '100px' }} className="w-20 form-select box mt-3 sm:mt-0">
                            <option value={10}>10</option>
                            <option value={25}>25</option>
                            <option value={35}>35</option>
                            <option value={50}>50</option>
                        </select>
                    </div>
                    {/* <!-- END: Pagination --> */}

                </div>
            </div>
        </>
    )
}

export default Admin