import { useState, useEffect } from 'react'
import { setSelectValue, loadScript, emit, validation, getFormData, showNotify } from '../context/socket'
import { ConvertIsoDate, getBase64 } from '../context/common_function'
import { useHistory } from "react-router-dom";
import Switch from './Switch';
import AHrefJavascript from './AHrefJavascript';
import $ from 'jquery';

const TransaksiOptionEdit = ({setPath,location}) => {
    const history = useHistory();
    const [metode, setMetode] = useState({})
    const [idMethod, setIdMethod] = useState({})
    const [changePhoto, setChangePhoto] = useState(false)
    useEffect(() => {
        const getUser = async (id) => {
            var reqData = {
                'id': id,
                'text': '',
                'page': 1,
                'size': 1,
            }
            emit('get_payment_method_admin', reqData, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                var idx = response.data.docs[0].options.findIndex((e)=>e._id == id)
                if(idx==-1)return alert('Data Tidak Ditemukan')
                setMetode(response.data.docs[0].options[idx])
                setIdMethod(response.data.docs[0]._id)
            });
        }
        getUser(location.pathname.split('/').reverse()[0])
        setPath({title:'Metode Transaksi',path:'/transactions/metode'});
        loadScript();
    }, [])
    const toggle = (data) => {
        var status = data.status =='active'?'inactive':'active'
        setMetode({ ...data, status: status })
    }
    const editMetode =async (data)=>{
        var required = $('input,textarea,select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });

        var form2 = getFormData($('#editOptions'))
        if (!validation(form2, required)) return;
        if (changePhoto)
            form2['icon'] = await getBase64('photo-user')
        form2.description ={
            id:form2.id,
            en:form2.en,
        }
        form2.status = metode.status;
        delete form2.id
        delete form2.en
        console.log(form2);
        emit('edit_payment_option', form2, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            history.push('/transactions/metode')
        });
    }
    const handleChange = (e) => {
        var uploadField = document.getElementById("actual-btn");
        if(!uploadField.files[0])return;
        console.log(uploadField.files[0].size);
        if (uploadField.files[0].size > 6552000) {
            alert("File is too big!");
            uploadField.value = "";
        } else {
            var url = URL.createObjectURL(uploadField.files[0]);
            $('#photo-user').attr("src", url);
            setChangePhoto(true);
        };
    }
  return (
    <>
            <div className="intro-y flex items-center mt-8">
                <h2 className="text-lg font-medium mr-auto">
                    Edit Options Pembayaran
                </h2>
            </div>
            <form id="editOptions">
                <input type={'hidden'} name="idPayment" value={idMethod||''}></input>
                <input type={'hidden'} name="idOption" value={metode._id||''}></input>
                <div className="grid grid-cols-12 gap-6 mt-5">
                    <div className="col-span-12 lg:col-span-4 2xl:col-span-3 flex lg:block flex-col-reverse">
                        <div className="intro-y box mt-5 lg:mt-0">
                            <div className="relative flex items-center p-5">
                                <div className="mr-auto">
                                    <div className="font-medium text-base">Gambar</div>
                                </div>
                            </div>
                            <div className="border-t border-slate-200/60 dark:border-darkmode-400">
                                <div id="single-file-upload" className="p-5">
                                    <div className="text-slate-500 mb-5">Gambar Metode Pembayaran *</div>
                                    <div className="preview">
                                        <div className="fallback bg-gray-200 ">
                                            <input name="file" type="file" id="actual-btn" onChange={(e) => { handleChange(e) }} accept="image/png, image/gif, image/jpeg" hidden />
                                            <img htmlFor="actual-btn" id="photo-user" className="mx-auto rounded-md" src={metode.fullIcon||"https://fontawesomeicons.com/images/svg/cloud-upload-outline.svg"} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-5 border-t border-slate-200/60 dark:border-darkmode-400 flex justify-center">
                                <label htmlFor="actual-btn" type="button" className="btn py-1 px-10 mx-auto">Unggah Foto</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-12 lg:col-span-8 2xl:col-span-9">
                        <div className="grid ">
                            <div className="intro-y box col-span-12 2xl:col-span-6">
                                <div className="flex items-center px-5 py-5 sm:py-3 border-b border-slate-200/60 dark:border-darkmode-400">
                                    <h2 className="font-medium text-base mr-auto">Detail Metode Pembayaran</h2>
                                    <Switch
                                        isOn={metode.status =='active'}
                                        onColor="#1be708"
                                        handleToggle={() => toggle(metode)}
                                    />
                                </div>
                                <div className="p-5">
                                    <div className="mb-5">
                                        <label htmlFor="regular-form-1" className="form-label">Nama Metode Pembayaran <b className="wajib">*</b></label>
                                        <input id="regular-form-1" name="name" required defaultValue={metode.name} type="text" className="form-control" placeholder="" />
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="regular-form-1" className="form-label">Bank (Midtrans Setting) <b className="wajib">*</b></label>
                                        <input id="regular-form-1" name="bank" required defaultValue={metode.bank} type="text" className="form-control" placeholder="" />
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="regular-form-1" className="form-label">Deskripsi Metode Pembayaran</label>
                                        <textarea name='id' required defaultValue={metode.description?.id} className="form-control h-28" placeholder=""></textarea>
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="regular-form-1" className="form-label">Deskripsi Metode Pembayaran <span className='text-red-500 font-bold'>(ENG)</span> <b className="wajib">*</b></label>
                                        <textarea name='en' className="form-control h-28" defaultValue={metode.description?.en} placeholder=""></textarea>
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="regular-form-1" className="form-label">Cara Pembayaran <b className="wajib">*</b></label>
                                        <textarea name='tutorial' className="form-control h-28" defaultValue={metode.tutorial} placeholder=""></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-right mt-8">
                            <button type="button" onClick={editMetode} className="btn btn-success shadow-md">Simpan Perubahan</button>
                        </div>
                    </div>

                </div>
            </form>
        </>
  )
}

export default TransaksiOptionEdit