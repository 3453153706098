import { useState, useEffect, useContext } from 'react'
import { socket, loadScript, emit, validation, getFormData, showNotify } from '../context/socket'
import { Link } from "react-router-dom";
import moment from 'moment';
import { ConvertIsoDate } from '../context/common_function'
import AHrefJavascript from './AHrefJavascript';
import Pagination from './Pagination';
import $ from 'jquery';

const DetailLawyer = ({ setPath }) => {
    const [lawyer, setLawyer] = useState({})
    const [clients, setClients] = useState([])
    const [reviews, setReviews] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [docsCounts, setDocsCounts] = useState(0)
    const [currentPage2, setCurrentPage2] = useState(1)
    const [docsCounts2, setDocsCounts2] = useState(0)
    useEffect(() => {
        const getLawyer = async (id) => {
            emit('get_detail_lawyer', { idLawyer: id }, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setLawyer(response.data[0]);
            });
        }
        getLawyer(location.pathname.split('/').reverse()[0])
    }, [])
    useEffect(() => {
        const getClient = async (id) => {
            var reqData = {
                'idLawyer': id,
                'page': currentPage,
                'size': 5,
            }
            emit('list_lawyer_client', reqData, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setClients(response.data.docs);
                setDocsCounts(response.data.counts)
            });
        }
        getClient(location.pathname.split('/').reverse()[0])
    }, [currentPage])
    useEffect(() => {
        const getReview = async (id) => {
            var reqData = {
                'idLawyer': id,
                'page': currentPage2,
                'size': 5,
            }
            emit('list_lawyer_rating', reqData, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setReviews(response.data.docs);
                setDocsCounts2(response.data.counts)
            });
        }
        getReview(location.pathname.split('/').reverse()[0])
    }, [currentPage2])
    useEffect(() => {
        setPath({title:'Lawyer',path:'/lawyers'});
        loadScript();
        $(() => {
            $('#sendNotify').on('click', () => {
                sendNotification()
            })
            $('#bannedUser').on('click', () => {
                bannedUser()
            })
        })
    }, [])
    const sendNotification = async () => {
        console.log('Kirim Notip')
        var required = $('#notify input,textarea,select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });
        var form2 = getFormData($('#notify'))
        if (!validation(form2, required)) return;
        console.log(form2)
        emit('send_notification', form2, (response) => {
            showNotify(response);
            if (response.response < 200 || response.response >= 300) return;
            $('#closeModal').trigger('click');
        });
    }
    const bannedUser = async () => {
        console.log('Banned User')
        var required = $('#banned input,textarea,select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });
        var form2 = getFormData($('#banned'))
        if (!validation(form2, required)) return;
        console.log(form2)
        emit('banned_user', form2, (response) => {
            showNotify(response);
            if (response.response < 200 || response.response >= 300) return;
            $('#closeModalBanned').trigger('click');
        });
    }
    const newTo = (data) => {
        return { pathname: "/lawyers/edit/" + data._id }
    };
    const sisaWaktu = (data) => {
        if (!data) return 'Menunggu Response Lawyer';
        var date = new Date().getTime()
        var time = new Date(data);
        time.setMinutes(time.getMinutes()+30);
        var remaining =  time.getTime() - date;
        console.log(remaining)
        return Object.entries(moment.duration(remaining, 'milliseconds')._data).reverse()
            .flatMap(([unit, value]) => value !== 0 && unit!='milliseconds'? `${value} ${unit}` : []).join(' ') + ' left'
    };
    const getLastOnline = (date) => {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const lastLoginDate = new Date(date);
        const todaysDate = new Date(); // Current date
        const diffDays = Math.abs((lastLoginDate - todaysDate) / oneDay);
        if (diffDays < 1) {
            if ((diffDays * 24) < 1) return Math.round(diffDays * 24 * 60) + ' minutes ago'
            return Math.round(diffDays * 24) + ' hours ago'
        }
        return Math.round(diffDays) + ' days ago'
    }
    return (
        <>
            {/* <div>LawyerView {lawyer.name ?? 'Loading..'}</div>
            <Link to={newTo(lawyer)} className="flex items-center mr-3">
                <i data-feather="check-square" className="w-4 h-4 mr-1"></i> Edit
            </Link> */}
            <div className="intro-y">
                {/* <!-- END: Top Bar --> */}
                <div className="intro-y flex items-center mt-8">
                    <h2 className="text-lg font-medium mr-auto">
                        Profile Layout
                    </h2>
                </div>
                <div className="grid grid-cols-12 gap-2 xl:gap-6 mt-5">
                    {/* <!-- BEGIN: Profile Menu --> */}
                    <div className="col-span-12 lg:col-span-5 xl:col-span-3 lg:block flex-col-reverse">
                        <div className="intro-y box mt-5 lg:mt-0">
                            <div className="relative flex items-center p-5">
                                <div className="w-12 image-fit">
                                </div>
                                <div className="ml-4 mr-auto">
                                </div>
                                <div className="dropdown">
                                    <Link to={newTo(lawyer)} aria-expanded="false" className="dropdown-toggle w-5 h-5 block">Edit </Link>
                                </div>
                            </div>
                            <div className="block w-40 mx-auto pb-2">
                                <img style={{ aspectRatio: '1 / 1', objectFit: 'cover' }} className="tooltip w-full rounded-full" src={lawyer.lawyerPhoto || "https://cdn-icons-png.flaticon.com/512/149/149071.png"} title="Foto" />
                            </div>
                            <div>
                                <div className='text-center text-lg font-semibold'>{lawyer.name}</div>
                                <div className='text-center text-sm text-gray-400'>{lawyer.specialist}</div>
                                <div className='text-center text-sm text-gray-400'>Last Online {getLastOnline(lawyer.lastLogin)}</div>
                                <br></br>
                                <div className='px-5'>
                                    <div className=''>{lawyer.email}</div>
                                    <div className=''>{lawyer.phone}</div>
                                    <div className=''>{moment(new Date(lawyer.birth_date)).format('LL')}</div>
                                    <div className=''>Join {moment(new Date(lawyer.createdAt)).format('LL')}</div>
                                </div>
                                <div className='flex p-5 justify-between items-center '>
                                    <AHrefJavascript href="#!" data-toggle="modal" className="btn btn-danger shadow-md mr-2" data-target="#banned-modal">Ban Lawyer</AHrefJavascript>
                                    <AHrefJavascript href="#!" data-toggle="modal" data-target="#notify-modal" style={{ color: '#1B389F' }}>Send Notification</AHrefJavascript>
                                </div>
                            </div>
                        </div>
                        <div className="intro-y my-5 box w-full flex flex-col" style={{ maxHeight: '20vh' }}>
                            <div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5">
                                <h2 className="font-medium text-base mr-auto">
                                    Keahlian Kategori Kasus
                                </h2>
                            </div>
                            <div className="p-3 overflow-y-scroll text-sm h-full">
                                {lawyer.cats?.map((e) => {
                                    return (
                                        <div key={e._id} className='py-1'>{e.name}</div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="intro-y box p-5 bg-theme-1 mt-5">
                            <div className="flex items-center">
                                <div className="font-medium text-lg">Balance</div>
                                <Link to={"/lawyers/history/"+lawyer._id} className="text-xs bg-white dark:bg-theme-1 dark:text-white text-gray-800 px-1 rounded-md ml-auto">History</Link>
                            </div>
                            <div className="mt-4 text-3xl font-medium">Rp. {(lawyer.saldo ?? 0).toLocaleString()}</div>
                        </div>
                        <div className="intro-y my-5 box w-full flex flex-col">
                            <div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5">
                                <h2 className="font-medium text-base mr-auto">
                                    Konsultasi berlangsung
                                </h2>
                            </div>
                            <div className="p-3 text-sm h-full">
                                {clients.filter((e) => e.id_conversation.status == 'active' || e.id_conversation.status == 'waiting').map((e, i) => {
                                    return (
                                        <div key={e.createdAt} className={"relative flex text-sm  items-center " + (i != 0 ? "mt-5" : '')}>
                                            <div className="w-12 h-12 flex-none image-fit">
                                                <img style={{ aspectRatio: '1 / 1', objectFit: 'cover' }} className="tooltip w-full rounded-full" src={e.id_user.fullPhoto || "https://cdn-icons-png.flaticon.com/512/149/149071.png"} title="Foto" />
                                            </div>
                                            <div className="ml-4 mr-auto">
                                                <Link to={"/users/profile/" + e.id_user._id} className="font-medium">{e.id_user.name} {e.rate}</Link>
                                                <div className="text-gray-600 mr-5 sm:mr-5">{sisaWaktu(e.id_conversation.startTime)}</div>
                                                {/* <div className="text-gray-600 mr-5 sm:mr-5">{sisaWaktu(new Date().setMinutes(new Date().getMinutes() + 10))}</div> */}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    {/* <!-- END: Profile Menu --> */}
                    <div className="col-span-12 lg:col-span-5 xl:col-span-9">
                        <div className="intro-y box w-full flex flex-col" style={{ maxHeight: '60vh' }}>
                            <div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5">
                                <h2 className="font-medium text-base mr-auto">
                                    Daftar Klien
                                </h2>
                                <h2 className="font-medium text-base">
                                    {lawyer.transCount}
                                </h2>
                            </div>
                            <div className="p-3 text-sm h-full">
                                {clients?.map((e, i) => {
                                    return (
                                        <div key={e.createdAt} className={"relative flex items-center " + (i != 0 ? "mt-5" : '')}>
                                            <div className="w-12 h-12 flex-none image-fit">
                                                <img style={{ aspectRatio: '1 / 1', objectFit: 'cover' }} className="tooltip w-full rounded-full" src={e.id_user.fullPhoto || "https://cdn-icons-png.flaticon.com/512/149/149071.png"} title="Foto" />
                                            </div>
                                            <div className="ml-4 flex flex-col mr-auto">
                                                <Link to={"/users/view/" + e.id_user._id} className="font-medium">{e.id_user.name}</Link>
                                                <Link to={"/transactions/view/" + e._id} className="text-gray-600" style={{ fontSize: '10px' }}>{e.invoice}</Link>
                                            </div>
                                            <div className="font-medium text-gray-700 dark:text-gray-500">{e.payment_type.name} - Rp. {(e.lawyer_profit ?? 0).toLocaleString()}</div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="intro-y pb-2 col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={docsCounts}
                                    pageSize={5}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                        <br></br>
                        <div className="intro-y box w-full flex flex-col " style={{ maxHeight: '60vh' }}>
                            <div className="flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5">
                                <h2 className="font-medium text-base mr-auto">
                                    Last Review
                                </h2>
                            </div>
                            <div className="p-3 ">
                                {reviews?.map((e, i) => {
                                    return (
                                        <div key={e.createdAt} className={"relative flex text-sm  items-center " + (i != 0 ? "mt-5" : '')}>
                                            <div className="w-12 h-12 flex-none image-fit">
                                                <img style={{ aspectRatio: '1 / 1', objectFit: 'cover' }} className="tooltip w-full rounded-full" src={e.id_user.fullPhoto || "https://cdn-icons-png.flaticon.com/512/149/149071.png"} title="Foto" />
                                            </div>
                                            <div className="ml-4 mr-auto">
                                                <Link to={"/users/profile/" + e.id_user._id} className="font-medium">{e.id_user.name} {e.rate}</Link>
                                                <div className="text-gray-600 mr-5 sm:mr-5">{e.pujian?.toString()}<br></br>{e.comment}</div>
                                            </div>
                                            {Array.apply(null, { length: 5 }).map((a, i) => {
                                                var color = i < e.rate ? '#FFCC00' : '#D9D9D9'
                                                return (
                                                    <span key={i} className={"material-icons "} style={{ color: color }}>
                                                        star_outline
                                                    </span>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                                <div className="intro-y py-2 col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage2}
                                        totalCount={docsCounts2}
                                        pageSize={5}
                                        onPageChange={page => setCurrentPage2(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 lg:col-span-5 xl:col-span-4">

                    </div>
                </div>
                <div id="notify-modal" className="modal" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            {/* <!-- BEGIN: Modal Header --> */}
                            <div className="modal-header">
                                <h2 className="font-medium text-base mr-auto">Send Push Notification</h2>
                            </div>
                            {/* <!-- END: Modal Header --> */}
                            {/* <!-- BEGIN: Modal Body --> */}
                            <div className={`px-5 pt-5`}>
                                <div className={`text-gray-400`}>This user will recieve notification on their account.</div>
                                <br></br>
                                <form id='notify'>
                                    <input type="hidden" name='idUser' required value={lawyer._id ?? ''} />
                                    <label htmlFor="modal-form-1" className="form-label">Title</label>
                                    <input id="modal-form-1" type="text"
                                        // value={'Testing kirim notify'}
                                        name='title' className="form-control mb-4" required minLength="10" maxLength="40" placeholder="Max 40 Character" />
                                    <label htmlFor="modal-form-2" className="form-label">Description</label>
                                    <textarea id="modal-form-2" className="form-control"
                                        // value={'Testing kirim notify 123123123123'}
                                        name="description" placeholder="Max 120 Character" minLength="10" maxLength={120} required></textarea>
                                </form>
                            </div>
                            {/* <!-- END: Modal Body --> */}
                            {/* <!-- BEGIN: Modal Footer --> */}
                            <div className="modal-footer">
                                <button id="closeModal" type="button" data-dismiss="modal" className="btn btn-outline-secondary w-20 mr-1">Cancel</button>
                                <button id="sendNotify" type="button" className="btn btn-primary w-20">Send</button>
                            </div>
                            {/* <!-- END: Modal Footer --> */}
                        </div>
                    </div>
                </div>
                <div id="banned-modal" className="modal" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            {/* <!-- BEGIN: Modal Header --> */}
                            <div className="modal-header">
                                <h2 className="font-medium text-base mr-auto">Send Push Notification</h2>
                            </div>
                            {/* <!-- END: Modal Header --> */}
                            {/* <!-- BEGIN: Modal Body --> */}
                            <div className={`px-5 pt-5`}>
                                <div className={`text-gray-400`}>This user will recieve notification on their account.</div>
                                <br></br>
                                <form id='banned'>
                                    <input type="hidden" name='idUser' required value={lawyer._id ?? ''} />
                                    <label htmlFor="modal-form-1" className="form-label">Until Date</label>
                                    <input id="modal-form-1" type="date" name="ban_date" required defaultValue={ConvertIsoDate(Date())} className="datepicker form-control col-span-4" data-single-mode="true" data-format="YYYY-MM-DD" placeholder="Select Date" />
                                    <label htmlFor="modal-form-2" className="form-label">Reason Banned</label>
                                    <textarea id="modal-form-2" className="form-control"
                                        // value={'Testing kirim notify 123123123123'}
                                        name="description" placeholder="Max 120 Character" minLength="10" maxLength={120} required></textarea>
                                </form>
                            </div>
                            {/* <!-- END: Modal Body --> */}
                            {/* <!-- BEGIN: Modal Footer --> */}
                            <div className="modal-footer">
                                <button id="closeModalBanned" type="button" data-dismiss="modal" className="btn btn-outline-secondary w-20 mr-1">Cancel</button>
                                <button id="bannedUser" type="button" className="btn btn-primary w-20">Banned</button>
                            </div>
                            {/* <!-- END: Modal Footer --> */}
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default DetailLawyer