import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { socket, loadScript, emit, validation, getFormData, showNotify } from '../context/socket'
import { LoremIpsum, Avatar, fullname, username } from "react-lorem-ipsum";
import { useHistory } from "react-router-dom";
import { ConvertIsoDate, getBase64 } from '../context/common_function'
import Switch from './Switch';
import $ from 'jquery';
import moment from 'moment';
const AddPromo = ({ setPath }) => {
    const history = useHistory();
    const [changePhoto, setChangePhoto] = useState(false)
    const [isAvailable, setIsAvailable] = useState(true)
    const [type, setType] = useState('percentage')
    useEffect(() => {
        setPath({ title: 'Promo / Voucher', path: '/promo' });
        loadScript();
    }, [])
    const handleChange = (e) => {
        var uploadField = document.getElementById("actual-btn");
        if (!uploadField.files[0]) return;
        console.log(uploadField.files[0].size);
        if (uploadField.files[0].size > 6552000) {
            alert("File is too big!");
            uploadField.value = "";
        } else {
            var url = URL.createObjectURL(uploadField.files[0]);
            $('#photo-user').attr("src", url);
            setChangePhoto(true);
        };
    }
    const createPromo = async (data) => {
        var required = $('input,textarea,select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });

        var form2 = getFormData($('#addPromo'))
        if (!validation(form2, required)) return;
        form2.type = type
        if (changePhoto)
            form2['banner'] = await getBase64('photo-user')
        console.log(form2);
        emit('create_new_promo', form2, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            history.push('/promo')
        });
    }
    return (
        <>
            <div className="intro-y flex items-center mt-8">
                <h2 className="text-lg font-medium mr-auto">
                    Tambah Promo
                </h2>
            </div>
            <form id="addPromo">
                <div className="grid grid-cols-12 gap-6 mt-5">
                    <div className="col-span-12 lg:col-span-4 2xl:col-span-3 flex lg:block flex-col-reverse">
                        <div className="intro-y box mt-5 lg:mt-0">
                            <div className="relative flex items-center p-5">
                                <div className="mr-auto">
                                    <div className="font-medium text-base">Banner Promo</div>
                                </div>
                            </div>
                            <div className="border-t border-slate-200/60 dark:border-darkmode-400">
                                <div id="single-file-upload" className="p-5">
                                    <div className="text-slate-500 mb-5">Gambar Promo *</div>
                                    <div className="preview">
                                        <div className="fallback bg-gray-200 ">
                                            <input name="file" type="file" id="actual-btn" onChange={(e) => { handleChange(e) }} accept="image/png, image/gif, image/jpeg" hidden />
                                            <img htmlFor="actual-btn" id="photo-user" className="mx-auto rounded-md" src="https://fontawesomeicons.com/images/svg/cloud-upload-outline.svg" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-5 border-t border-slate-200/60 dark:border-darkmode-400 flex justify-center">
                                <label htmlFor="actual-btn" type="button" className="btn py-1 px-10 mx-auto">Unggah Foto</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-span-12 lg:col-span-8 2xl:col-span-9">
                        <div className="grid ">
                            <div className="intro-y box col-span-12 2xl:col-span-6">
                                <div className="flex items-center px-5 py-5 sm:py-3 border-b border-slate-200/60 dark:border-darkmode-400">
                                    <h2 className="font-medium text-base mr-auto">Detail Promo</h2>
                                    {/* <Switch
                                        isOn={isAvailable}
                                        onColor="#1be708"
                                        handleToggle={() => setIsAvailable(!isAvailable)}
                                    /> */}
                                </div>
                                <div className="p-5">
                                    <div className="mb-5">
                                        <label htmlFor="regular-form-1" className="form-label">Nama Promo <b className="wajib">*</b></label>
                                        <input id="regular-form-1"required name="title" type="text" className="form-control" placeholder="Masukan Judul Promo" />
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="regular-form-1" className="form-label">Kode Kupon <b className="wajib">*</b></label>
                                        <input id="regular-form-1"required  name='code' type="text" className="form-control" placeholder="Masukan Kode Promo" />
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="regular-form-1" className="form-label">Tanggal Promo <b className="wajib">*</b></label>
                                        <div className="grid grid-cols-12 gap-2">
                                            <input type="date" required name="startDate" className="datepicker form-control col-span-4" data-format="YYYY-MM-DD" data-single-mode="true" placeholder="Tanggal Mulai" />
                                            <b className="mx-auto my-auto">s/d</b>
                                            <input type="date" required name="endDate" className="datepicker form-control col-span-4" data-format="YYYY-MM-DD" data-single-mode="true" placeholder="Tanggal Berakhir" />
                                        </div>
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="regular-form-1" className="form-label">Deskripsi Promo <b className="wajib">*</b></label>
                                        <textarea name='description' className="form-control h-28" required placeholder="Deskripsi Promo"></textarea>
                                    </div>
                                    <div className="mb-5">
                                        <label htmlFor="regular-form-1" className="form-label">Syarat dan Ketentuan Promo <b className="wajib">*</b></label>
                                        <textarea className="form-control h-28" name='syarat' required placeholder="Syarat dan Ketentuan Promo"></textarea>
                                    </div>
                                    <div className="mb-5 w-1/3">
                                        <label htmlFor="regular-form-1" className="form-label">Limit User Claim <b className="wajib">*</b></label>
                                        <input id="regular-form-1"required name="maxUsed" type="number" className="form-control " placeholder="Max User" />
                                    </div>
                                    <div className="mb-5 w-1/3">
                                        <label htmlFor="regular-form-1" className="form-label">Tipe Potongan <b className="wajib">*</b></label>
                                        {<select data-placeholder="Tipe Potongan" onChange={(e) => setType(e.target.value)} defaultValue={type} name="type" id="type" required className="tom-select w-full">
                                            {['Percentage', 'Fixed'].map(e =>
                                                <option key={e} value={e.toLowerCase()}>{e}</option>
                                            )}
                                        </select>}
                                    </div>
                                    {type == 'percentage' ? <>
                                        <div className="mb-5 w-1/3">
                                            <label htmlFor="regular-form-1" className="form-label">Jumlah Persentase <b className="wajib">*</b></label>
                                            <input id="regular-form-1" name="value" required type="number" className="form-control " placeholder="Ex: 10%" />
                                        </div>
                                        <div className="input-group w-1/3">
                                            <div className="input-group-text">Rp.</div>
                                            <input type="number" className="form-control" name="maxValue" required placeholder="Max Discount" />
                                        </div>
                                    </> :
                                        <div className="mb-5 w-1/3">
                                            <label htmlFor="regular-form-1" className="form-label">Jumlah Promo <b className="wajib">*</b></label>
                                            <input id="regular-form-1" required name="value" type="number" className="form-control " placeholder="Ex: 10000" />
                                        </div>}
                                </div>
                            </div>
                        </div>
                        <div className="text-right mt-8">
                            <button type="button" onClick={createPromo} className="btn btn-dark shadow-md">Tambah Promo</button>
                        </div>
                    </div>

                </div>
            </form>
        </>
    )
}

export default AddPromo

