import React, { useState, useEffect, useContext } from 'react'
import { socket, loadScript, emit, validation, getFormData, showNotify } from '../context/socket'
import { ConvertIsoDate, getBase64 } from '../context/common_function'
import $ from 'jquery';
import { Link, useHistory } from "react-router-dom";

const AdminAdd = ({ setPath, location }) => {
    const [changePhoto, setChangePhoto] = useState(false)
    const history = useHistory();
    useEffect(() => {
        loadScript()
        setPath({title:'Admin',path:'/admins'});
        // setPath('/admin')
    }, [])
    const addAdmin = async () => {
        var required = $('input,textarea,select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });
        var form2 = getFormData($('#addUser'))
        if (!validation(form2, required)) return;
        if (form2['password'] != form2['re-password']) {
            $('input[name="password"]').focus();
            return alert('Password Belum Sesuai')
        }
        if (changePhoto)
            form2['photo'] = await getBase64('photo-user')
        delete form2['re-password'];
        console.log(form2);

        emit('create_admin', form2, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            history.push('/admins')
        });
    }
    const handleChange = (e) => {
        var uploadField = document.getElementById("actual-btn");
        if(!uploadField.files[0])return;
        console.log(uploadField.files[0].size);
        if (uploadField.files[0].size > 6552000) {
            alert("File is too big!");
            uploadField.value = "";
        } else {
            var url = URL.createObjectURL(uploadField.files[0]);
            $('#photo-user').attr("src", url);
            setChangePhoto(true);
        };
    }
    return (
        <>
            <div className="intro-y">
                {/* <!-- END: Top Bar --> */}
                <h2 className="intro-y text-lg font-medium mt-10">
                    Add New User
                </h2>
                <form id="addUser">
                    <div className="w-3/4 mt-5">
                        {/* <!-- BEGIN: Input --> */}
                        <div className="intro-y box">
                            <div className="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                                <h2 className="font-medium text-base mr-auto">
                                    User Information
                                </h2>
                            </div>
                            <div className="flex flex-col xl:flex-row flex-col">
                                <div className="md:w-1/3 m-6">
                                    <div className="border w-full flex-col flex shadow-sm border-gray-200 rounded-md p-5">
                                        <div style={{ aspectRatio: '1 / 1', objectFit: 'cover' }} className="h-40 relative image-fit cursor-pointer zoom-in mx-auto">
                                            <img style={{ aspectRatio: '1 / 1', objectFit: 'cover' }} id="photo-user" src={'https://cdn-icons-png.flaticon.com/512/149/149071.png'} className="rounded-md" alt="Photo User" />
                                            {/* <img className="rounded-md" src="https://cdn-icons-png.flaticon.com/512/149/149071.png"/> */}
                                            {/* <div title="Remove this profile photo?" className="w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 top-0 -mr-2 -mt-2"> x </div> */}
                                        </div>
                                        <div className="w-1/2 mx-auto cursor-pointer relative mt-5">
                                            <input type="file" id="actual-btn" onChange={(e) => { handleChange(e) }} accept="image/png, image/gif, image/jpeg" hidden />
                                            <label htmlFor="actual-btn" className="btn w-full hover:bg-blue-800 hover:text-white cursor-pointer">Change Photo</label>
                                        </div>
                                    </div>
                                </div>
                                <div id="input" className="flex-1 mt-6 xl:mt-0 p-5">
                                    <div className="preview">
                                        <div>
                                            <label htmlFor="regular-form-1" className="form-label">Full Name</label><span className="text-red-500"> *</span>
                                            <input id="regular-form-1" name="name" required type="text" className="form-control" placeholder="Fill with Full Name" />
                                        </div>
                                        <div className="mt-3">
                                            <label htmlFor="regular-form-2" className="form-label">Email</label><span className="text-red-500"> *</span>
                                            <input id="regular-form-2" name="email" required type="text" className="form-control" placeholder="Example: lorem@gmail.com" />
                                        </div>
                                        <div className="mt-3">
                                            <div className="grid grid-cols-2 gap-2">
                                                <label htmlFor="regular-form-3" className="form-label">Password <span className="text-red-500"> *</span></label>
                                                <label htmlFor="regular-form-4" className="form-label">Confirm Password <span className="text-red-500"> *</span></label>
                                            </div>
                                            <div className="grid grid-cols-8 gap-2">
                                                <input id="regular-form-3" name="password" type="password" className="form-control col-span-4" placeholder="Make a strong password" />
                                                <input id="regular-form-4" name="re-password" type="password" className="form-control col-span-4" placeholder="Confirm a strong password" />
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <label htmlFor="regular-form-5" className="form-label">Roles <span className="text-red-500"> *</span></label>
                                            {/* <!-- BEGIN: Nested Select --> */}
                                            {<select data-placeholder="Roles"  name="role" id="role" required className="tom-select w-full">
                                                {['Admin', 'Super Admin','Marketing','Finance'].map(e =>
                                                    <option key={e} value={e.toLowerCase()}>{e}</option>
                                                )}
                                            </select>}
                                            {/* <!-- END: Nested Select --> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-end mt-5'>
                            <div className="text-right">
                                <button type="button" onClick={addAdmin} className="btn btn-dark shadow-md">Save</button>
                            </div>
                        </div>
                        {/* <!-- END: Input --> */}
                    </div>
                </form>
            </div>
        </>
    )
}

export default AdminAdd