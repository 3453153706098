import { useState, useEffect, useRef } from 'react'
import { socket, loadScript, emit, setSelectValue, validation, getFormData, showNotify } from '../context/socket'
import { ConvertIsoDate, getBase64 } from '../context/common_function'
import { Link, useHistory } from "react-router-dom";
import $ from 'jquery';
import ListCategori from './ListCategori';
import Select from 'react-select'

function EditLawyer({ setPath, computedMatch, location }) {
    // const [categories, setCategories] = useState([])
    const [lawyer, setLawyer] = useState({})
    const [changePhoto, setChangePhoto] = useState(false)
    const history = useHistory();
    useEffect(() => {
        loadScript()
        const getLawyer = async (id) => {
            emit('get_detail_lawyer', { idLawyer: id }, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setLawyer(response.data[0]);
                console.log(response.data[0].specialist)
                setTimeout(() => {
                    setSelectValue('specialist', response.data[0].specialist)
                }, 500);
            });
        }
        getLawyer(location.pathname.split('/').reverse()[0])
        setPath({title:'Lawyer',path:'/lawyers'});
    }, [])
    const handleChange = (e) => {
        var uploadField = document.getElementById("actual-btn");
        if(!uploadField.files[0])return;
        console.log(uploadField.files[0].size);
        if (uploadField.files[0].size > 6552000) {
            alert("File is too big!");
            uploadField.value = "";
        } else {
            var url = URL.createObjectURL(uploadField.files[0]);
            $('#photo-user').attr("src", url);
            setChangePhoto(true);
        };
    }

    const editLawyer = async () => {
        var required = $('input,textarea,select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });
        //aditional jika tidak masuk ke filter
        required.push('categories');

        var form2 = getFormData($('#editLawyer'))
        if (!validation(form2, required)) return;
        if (form2['password'] != form2['re-password']) {
            $('input[name="password"]').focus();
            return alert('Password Belum Sesuai')
        }
        if (changePhoto)
            form2['photo'] = await getBase64('photo-user')
        delete form2['re-password'];
        if(form2.phone[0]=='0') form2.phone = form2.phone.substring(1)
        form2.phone = `+62${form2.phone}`;
        if (form2['password'] == '')
            delete form2['password'];
        console.log(form2);

        emit('edit_lawyer', form2, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            history.push('/lawyers/view/' + form2.id)
        });
    }
    console.log(lawyer)

    return (
        <div className="intro-y">
            {/* <!-- END: Top Bar --> */}
            <h2 className="intro-y text-lg font-medium mt-10">
                Edit Lawyer
            </h2>
            <form id="editLawyer">
                <input type="hidden" name="id" value={lawyer._id || ''} />
                <div className="grid grid-cols-24 gap-6 mt-5">
                    {/* <!-- BEGIN: Input --> */}
                    <div className="intro-y box">
                        <div className="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                            <h2 className="font-medium text-base mr-auto">
                                Display Information
                            </h2>
                        </div>
                        <div className="flex flex-col-reverse xl:flex-row flex-col">
                            <div className="w-1/4 mx-auto mx-6 mt-6">
                                <div className="border w-full flex-col flex shadow-sm border-gray-200 rounded-md p-5">
                                    <div style={{ aspectRatio: '1 / 1.5', objectFit: 'cover' }} className="h-40 relative image-fit cursor-pointer zoom-in mx-auto">
                                        <img style={{ aspectRatio: '1 / 1.5', objectFit: 'cover' }} id="photo-user" src={lawyer.lawyerPhoto||'https://cdn-icons-png.flaticon.com/512/149/149071.png'} className="rounded-md" alt="Photo User" />
                                        {/* <img className="rounded-md" src="https://cdn-icons-png.flaticon.com/512/149/149071.png"/> */}
                                        {/* <div title="Remove this profile photo?" className="w-5 h-5 flex items-center justify-center absolute rounded-full text-white bg-theme-6 right-0 top-0 -mr-2 -mt-2"> x </div> */}
                                    </div>
                                    <div className="w-1/2 mx-auto cursor-pointer relative mt-5">
                                        <input type="file" id="actual-btn" onChange={(e) => { handleChange(e) }} accept="image/png, image/gif, image/jpeg" hidden />
                                        <label htmlFor="actual-btn" className="btn w-full hover:bg-blue-800 hover:text-white cursor-pointer">Upload Photo</label>
                                    </div>
                                </div>
                            </div>
                            <div id="input" className="flex-1 mt-6 xl:mt-0 p-5">
                                <div className="preview">
                                    <div>
                                        <label htmlFor="regular-form-1" className="form-label">Full Name</label><span className="text-red-500"> *</span>
                                        <input id="regular-form-1" name="name" required type="text" className="form-control" placeholder="Fill with Full Name" defaultValue={lawyer.name} />
                                    </div>
                                    <div className="mt-3">
                                        <label htmlFor="regular-form-2" className="form-label">Email</label><span className="text-red-500"> *</span>
                                        <input id="regular-form-2" name="email" readOnly required type="text" className="form-control" placeholder="Example: lorem@gmail.com" defaultValue={lawyer.email} />
                                    </div>
                                    <div className="mt-3">
                                        <div className="grid grid-cols-2 gap-2">
                                            <label htmlFor="regular-form-3" className="form-label">Password <span className="text-red-500"> *</span></label>
                                            <label htmlFor="regular-form-4" className="form-label">Confirm Password <span className="text-red-500"> *</span></label>
                                        </div>
                                        <div className="grid grid-cols-8 gap-2">
                                            <input id="regular-form-3" name="password" type="password" className="form-control col-span-4" placeholder="Make a strong password" />
                                            <input id="regular-form-4" name="re-password" type="password" className="form-control col-span-4" placeholder="Confirm a strong password" />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <label htmlFor="regular-form-5" className="form-label">Kategori {lawyer.specialist}</label>
                                        <input id="regular-form-1" name="specialist" defaultValue={lawyer.specialist} required type="text" className="form-control" placeholder="Fill with Lawyer Specialist" />
                                    
                                        {/* <!-- BEGIN: Nested Select --> */}
                                        {/* {<select data-placeholder="Pilih Kategori Hukum" defaultValue={lawyer.specialist} name="specialist" id="specialist" required className="tom-select w-full">
                                            {['Hukum Pidana', 'Hukum Perdata', 'Hukum Bisnis'].map(e =>
                                                <option key={e} value={e}>{e}</option>
                                            )}
                                        </select>} */}
                                        {/* <!-- END: Nested Select --> */}
                                    </div>
                                    <div className="mt-3">
                                        <label htmlFor="regular-form-2" className="form-label">Nomor Induk Advocat</label><span className="text-red-500"> *</span>
                                        <input id="regular-form-2" name="nia" defaultValue={lawyer.nia} required type="text" className="form-control" placeholder="Example: 2414123" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- END: Input --> */}
                </div>
                <div className="mt-10">
                    <div className="box">
                        <div className="flex-auto p-5 border-b border-gray-200 dark:border-dark-5">
                            <h2 className="font-medium text-base mr-auto">
                                Personal Information
                            </h2>
                        </div>
                        <div className="flex-auto p-5">
                            <div className="mt-3">
                                <div className="grid grid-cols-2 gap-2">
                                    <label className="form-label">Keahlian Kategori Khusus</label>
                                </div>
                                {!lawyer.cats ? <>Loading...</> : <ListCategori value={lawyer.cats?.map((e) => e._id)}></ListCategori>}
                                <div className="grid grid-cols-2 gap-2 mt-5">
                                    <label className="form-label">No. Handphone <span className="text-red-500"> *</span></label>
                                    <label className="form-label">Tanggal Lahir <span className="text-red-500"> *</span></label>
                                </div>
                                {lawyer._id && <div className="grid grid-cols-8 gap-2">
                                    <div className="input-group col-span-4">
                                        <div id="input-group-email" className="input-group-text">+62</div>
                                        <input type="number" className="form-control" name="phone" defaultValue={lawyer.phone.replace('+62','0')} required placeholder="Nomor Handphone" aria-label="Email" aria-describedby="input-group-email" />
                                    </div>
                                    {/* <input type="text" name="phone" readOnly required defaultValue={lawyer.phone} className="form-control col-span-4" placeholder="Nomor Handphone" /> */}
                                    <input type="date" name="birth_date" required defaultValue={ConvertIsoDate(lawyer.birth_date)} className="datepicker form-control col-span-4" data-single-mode="true" data-format="YYYY-MM-DD" placeholder="Birth Date" />
                                </div>}
                                {/* With TOP Margin */}
                                <div className="grid grid-cols-2 gap-2 mt-5">
                                    <label className="form-label">Alamat<span className="text-red-500"> *</span></label>
                                    <label className="form-label">Harga Konsultasi<span className="text-red-500"> *</span></label>
                                </div>
                                <div className="grid grid-cols-8 gap-2">
                                    <input type="text" name="address" required defaultValue={lawyer.address} className="form-control col-span-4" placeholder="Alamat" />
                                    <input type="number" name="price" required defaultValue={lawyer.price} className="form-control col-span-4" placeholder="Harga Konsultasi" />
                                </div>
                                <div className="grid grid-cols-2 gap-2 mt-5">
                                    <label className="form-label">Tahun Pengalaman</label>
                                    <label className="form-label">Harga Extend Konsultasi</label>
                                </div>
                                {lawyer._id && <div className="grid grid-cols-8 gap-2">
                                    <input type="number" name="experience" required defaultValue={lawyer.experience} className="form-control col-span-4" placeholder="Tahun Pengalaman" />
                                    <input type="number" className="form-control col-span-4" placeholder="Harga Extend Konsultasi" />
                                </div>}
                                <div className="grid grid-cols-2 gap-2 mt-5">
                                    {/* <label className="form-label">Nomor PERADI<span className="text-red-500"> *</span></label> */}
                                    <label className="form-label">Alumni</label>
                                </div>
                                <div className="grid grid-cols-8 gap-2">
                                    {/* <input type="number" name="peradiNumber" required defaultValue={lawyer.peradiNumber} className="form-control col-span-4" placeholder="Nomor PERADI" /> */}
                                    <input type="text" name="alumnus" defaultValue={lawyer.alumnus} className="form-control col-span-4" placeholder="Nama Universitas" />
                                </div>
                                
                            </div>
                            <div className="float-left text-left mt-10">
                                
                            </div>
                            <div className="text-right mt-10">
                                <button type="button" onClick={editLawyer} className="btn btn-dark shadow-md">Edit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default EditLawyer