import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { socket, loadScript, emit, showNotify,debounce } from '../context/socket'
import $ from 'jquery';
import Switch from './Switch';
import AHrefJavascript from './AHrefJavascript';
import Pagination from './Pagination';

const KategoriKasus = ({ setPath }) => {
    const [categories, setCategories] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState({
        number:0,
        time:new Date().getTime()
    })
    const [docsCounts, setDocsCounts] = useState(0)
    const [text, setText] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [filter, setFilter] = useState('createdAt')
    const [type, setType] = useState(-1)

    useEffect(() => {
        loadScript();
        setPath({title:'Kategori Kasus',path:'/kategorikasus'});
    }, [])

    const getUser = async (page) => {
        var reqData = {
            'name': text,
            'page': page.number,
            'size': pageSize,
            'status': null,
            'filter': filter,
            'type': parseInt(type.toString()),
        }
        emit('list_categories', reqData, (response) => {
            if (response.response < 200 || response.response >= 300) return showNotify(response)
            setCategories(response.data.docs)
            setDocsCounts(response.data.counts)
        });
    }
    useEffect(() => {
        setCurrentPage({number:1})
    }, [text,pageSize,filter,type,refresh])

    useEffect(() => {
        if(currentPage.number!=0)
        getUser(currentPage)
    }, [currentPage])

    const search = (data) => {
        console.log(data.target.value)
        debounce(() => setText(data.target.value), 2000)
    }

    const toggle = (data) => {
        var reqData = {
            idCategory:data._id,
            status: data.status =='active'?'inactive':'active',
            name:data.name,
            english:data.english,
            pinned:data.pinned,
        }
        console.log(reqData)
        emit('edit_category', reqData, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
            setRefresh(!refresh)
        });
        // setCategories({ ...data, pinned: !data.pinned})
    }
    return (
        <div className="intro-y">
            {/* <!-- END: Top Bar --> */}
            <h2 className="intro-y text-lg font-medium mt-10">
                Kategori Kasus
            </h2>
            <div className="grid grid-cols-12 gap-6 mt-5">
                <div className="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
                    <Link to="/kategorikasus/add">
                        <button className="btn shadow-md mr-2">Tambah Kategori</button>
                    </Link>
                    <div className="hidden md:block mx-auto text-gray-600">Showing {((currentPage.number * pageSize) - pageSize) + 1} to {(currentPage.number * pageSize)} of {docsCounts} entries</div>

                    <div className="w-full sm:w-auto flex mt-4 sm:mt-0">
                        {/* <div className="dropdown mr-2 w-56 relative text-gray-700 dark:text-gray-300">
                           
                        </div> */}
                        <div onChange={(e) => setFilter(e.target.value)} className="dropdown mr-2 w-56 relative text-gray-700 dark:text-gray-300">
                                <select className="tom-select sm:mr-2" defaultValue={filter} placeholder="Urut Berdasarkan">
                                    <option value={'konsultasi'}>Konsultasi</option>
                                    <option value={'createdAt'}>Tanggal Dibuat</option>
                                </select>
                            </div>
                            <div onChange={(e) => setType(e.target.value)} className="dropdown mr-2 relative text-gray-700 dark:text-gray-300">
                                <select className="tom-select sm:mr-2" defaultValue={type} placeholder="Urutan">
                                    <option value={1}>A to Z</option>
                                    <option value={-1}>Z to A</option>
                                </select>
                            </div>
                        <div className="w-56 relative text-gray-700 dark:text-gray-300">
                            <input type="text" onChange={search} className="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Search..." />
                            <i className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" data-feather="search"></i>
                        </div>
                    </div>
                </div>
                {/* <!-- BEGIN: Data List --> */}
                <div className="intro-y col-span-12 overflow-auto lg:overflow-visible">
                    <table className="table table-report -mt-2">
                        <thead>
                            <tr>
                                <th className="w-1">No.</th>
                                <th className="whitespace-nowrap">Kategori Kasus</th>
                                <th className="text-center whitespace-nowrap">Pin Kategori</th>
                                <th className="text-center whitespace-nowrap">Status</th>
                                <th className="text-center whitespace-nowrap">Konsultasi</th>
                                <th className="text-center whitespace-nowrap">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories.map((d, i) => {
                                i *= 1
                                return (
                                    <tr key={i} className="intro-x">
                                        <td className='w-1'>{i+1}</td>
                                        <td className="w-1">
                                            <div className="flex">
                                                <div className="text-gray-900 text-xs whitespace-nowrap my-0.5" key={d._id}>
                                                    <div className="font-bold">{d.name}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="w-1">
                                            <div className="flex justify-center">
                                                <div className="text-gray-900 text-xs whitespace-nowrap mx-5 my-0.5" key={d._id}>
                                                    <div className="font-bold uppercase text-center">{d.pinned?'Pinned':''}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="w-40">
                                            <div className="flex items-center justify-center">
                                                <Switch
                                                    isOn={d.status == 'active'}
                                                    onColor="#1be708"
                                                    data={d}
                                                    handleToggle={() => toggle(d)}
                                                />
                                            </div>
                                        </td>
                                        <td className="w-1">
                                            <div className="text-center">{d.konsultasi}</div>
                                        </td>
                                        <td className="table-report__action w-56">
                                            <div className="flex justify-center items-center">
                                                <Link className="flex items-center mr-3" to={'/kategorikasus/edit/'+d._id} > <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-square w-4 h-4 mr-1"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>  Edit </Link>
                                                {/* <AHrefJavascript onClick={() => hapus(d._id)} href="#!" className="flex items-center mr-3" > <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-square w-4 h-4 mr-1"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>  Hapus </AHrefJavascript> */}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {/* <!-- BEGIN: Pagination --> */}
                <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage.number}
                        totalCount={docsCounts}
                        pageSize={pageSize}
                        onPageChange={page => setCurrentPage({number:page})}
                    />
                    <div className='w-full'></div>
                    <select defaultValue={10} onChange={(e) => setPageSize(parseInt(e.target.value))} style={{width:'100px'}} className="w-20 form-select box mt-3 sm:mt-0">
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={35}>35</option>
                        <option value={50}>50</option>
                    </select>
                </div>
                {/* <!-- END: Pagination --> */}

            </div>
        </div>
    )
}

export default KategoriKasus

