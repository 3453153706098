import React, { Component, useState, useContext, useEffect } from 'react';
import { View, Text, Image, FlatList } from 'react-native';
import io from "socket.io-client";
import { Link } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { AppStateContext } from "../context/AppStateContext";

const SideBar = () => {
    const [cookies, setCookie] = useCookies(['token']);
    const {page, toggle, setToggle } = useContext(AppStateContext);
    const userIsLogged = cookies.token;
    const handle = (data) => {
        console.log('Toggle ke click', toggle)
        setToggle(!toggle)
    }
    const user = cookies.user;
    const path = page.path;
    console.log(path);
    useEffect(() => {
    }, [])
    if (!userIsLogged) return <></>
    return (
        <nav className={"side-nav w-auto"}>
            <div onClick={handle} className="intro-x cursor-pointer flex items-center pl-5 pt-4">
                {/* <img alt="Rubick Tailwind HTML Admin Template" className="w-6" src="dist/images/logo.svg"> */}
                {/* <span className={"text-white text-lg ml-3 "+(toggle?'side-menu__title':'')}> Lapor Hukum </span> */}
                <span className="material-icons cursor-pointer text-white">menu</span>
                {toggle ? <span className={"text-white text-lg ml-3 hidden lg:block"}>Lapor Hukum</span> : <></>}
            </div>
            <div className={"side-nav__devider my-6 " + (toggle ? '!block' : '')}></div>
            <ul>
                <li>
                    <Link to="/home" data-placement="right" title='Dashboard' className={"tooltip side-menu " + (path == '/home' ? 'side-menu--active' : location.pathname)}>
                        <div className=" side-menu__icon"> <i data-feather="home"></i> </div>
                        <div className={" " + (toggle ? 'side-menu__title' : 'hidden')}>
                            Dashboard
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/users" data-placement="right" title="List User" className={"side-menu " + (path == '/users' ? 'side-menu--active' : location.pathname)+' tooltip'}>
                        <div className="side-menu__icon"> <i data-feather="user"></i> </div>
                        <div className={" " + (toggle ? 'side-menu__title' : 'hidden')}> List User </div>
                    </Link>
                </li>
                <li>
                    <Link to="/lawyers" data-placement="right" title="List Lawyer" className={"side-menu " + (path == '/lawyers' ? 'side-menu--active' : location.pathname)+' tooltip'}>
                        <div className="side-menu__icon"> <i data-feather="users"></i> </div>
                        <div className={" " + (toggle ? 'side-menu__title' : 'hidden')}> List Lawyer </div>
                    </Link>
                </li>
                {/* <li>
                    <Link to="/pengaduan" data-placement="right" title="List Pengaduan" className={"side-menu " + (path == '/pengaduan' ? 'side-menu--active' : location.pathname)+' tooltip'}>
                        <div className="side-menu__icon"> <i data-feather="credit-card"></i> </div>
                        <div className={" " + (toggle ? 'side-menu__title' : 'hidden')}> List Pengaduan </div>
                    </Link>
                </li> */}
                <li>
                    <Link to="/pengaduan/alter" data-placement="right" title="Pengaduan" className={"side-menu " + (path == '/pengaduan' ? 'side-menu--active' : location.pathname)+' tooltip'}>
                        <div className="side-menu__icon"> <i data-feather="alert-triangle"></i> </div>
                        <div className={" " + (toggle ? 'side-menu__title' : 'hidden')}> Pengaduan </div>
                    </Link>
                </li>
                {/* <li>
                    <Link to="/transactions" data-placement="right" title="Daftar Transaksi" className={"side-menu " + (path == '/transactions' ? 'side-menu--active' : location.pathname)+' tooltip'}>
                        <div className="side-menu__icon"> <i data-feather="message-square"></i> </div>
                        <div className={" " + (toggle ? 'side-menu__title' : 'hidden')}> Daftar Transaksi </div>
                    </Link>
                </li> */}
                <li>
                    <Link to="/transactions" data-placement="right" title="Transaksi" className={"side-menu " + (path == '/transactions' ? 'side-menu--active' : location.pathname)+' tooltip'}>
                        <div className="side-menu__icon"> <i data-feather="dollar-sign"></i> </div>
                        <div className={" " + (toggle ? 'side-menu__title' : 'hidden')}> Transaksi</div>
                    </Link>
                </li>
                <li>
                    <Link to="/transactions/settlement" data-placement="right" title="Settlement" className={"side-menu " + (path == '/settlement' ? 'side-menu--active' : location.pathname)+' tooltip'}>
                        <div className="side-menu__icon"> <i data-feather="credit-card"></i> </div>
                        <div className={" " + (toggle ? 'side-menu__title' : 'hidden')}> Settlement</div>
                    </Link>
                </li>
                <li>
                    <Link to="/kategorikasus" data-placement="right" title="Kategori Kasus" className={"side-menu " + (path == '/kategorikasus' ? 'side-menu--active' : location.pathname)+' tooltip'}>
                        <div className="side-menu__icon"> <i data-feather="tag"></i> </div>
                        <div className={" " + (toggle ? 'side-menu__title' : 'hidden')}> Kategori Kasus</div>
                    </Link>
                </li>
                <li>
                    <Link to="/promo" data-placement="right" title="Promo" className={"side-menu " + (path == '/promo' ? 'side-menu--active' : location.pathname)+' tooltip'}>
                        <div className="side-menu__icon"> <i data-feather="percent"></i> </div>
                        <div className={" " + (toggle ? 'side-menu__title' : 'hidden')}> Promo</div>
                    </Link>
                </li>
                <li>
                    <Link to="/transactions/metode" data-placement="right" title="Metode Transaksi" className={"side-menu " + (path == '/transactions/metode' ? 'side-menu--active' : location.pathname)+' tooltip'}>
                        <div className="side-menu__icon"> <i data-feather="shopping-bag"></i> </div>
                        <div className={" " + (toggle ? 'side-menu__title' : 'hidden')}> Metode Transaksi</div>
                    </Link>
                </li>
                {(user?.role =='super admin') && <li>
                    <Link to="/admins" data-placement="right" title={"User & Roles"} className={"side-menu " + (path == '/admins' ? 'side-menu--active' : location.pathname)+' tooltip'}>
                        <div className="side-menu__icon"> <i data-feather="shield"></i> </div>
                        <div className={" " + (toggle ? 'side-menu__title' : 'hidden')}> {"User & Roles"}</div>
                    </Link>
                </li>}

                {user?.role =='super admin' &&  <li>
                    <Link to="/settings" data-placement="right" title={"Apps Settings"} className={"side-menu " + (path == '/settings' ? 'side-menu--active' : location.pathname)+' tooltip'}>
                        <div className="side-menu__icon"> <i data-feather="settings"></i> </div>
                        <div className={" " + (toggle ? 'side-menu__title' : 'hidden')}> {"Apps Settings"}</div>
                    </Link>
                </li>}
            </ul>
        </nav>
    );
}

export default SideBar