import { useState, useEffect } from 'react'
import { socket, loadScript, emit, validation,getFormData,showNotify } from '../context/socket'
import { LoremIpsum } from "react-lorem-ipsum";
import AHrefJavascript from './AHrefJavascript';
import moment from 'moment';
import $ from 'jquery';
import TomSelect from 'tom-select';
import { Link } from "react-router-dom";
const TransaksiWithdraw = ({setPath,location}) => {
    const [transactions, setTransaction] = useState([])
    const [refresh, setRefresh] = useState({})
    useEffect(() => {
        
        $(()=>{
            $('#approveTransaction').on('click',()=>{
                accTransaction()
            })
            $('#rejectTransaction').on('click',()=>{
                rejectTransaction()
            })
        })
        setPath({title:'Tarik Dana',path:'/settlement'});
        loadScript();
    }, [])
    useEffect(() => {
        getUser(location.pathname.split('/').reverse()[0])
    }, [refresh])
    const getUser = async (id) => {
        var reqData = {
            'id':id,
            'text': '',
            'page': 1,
            'size': 1,
        }
        emit('list_withdraw_admin',reqData,(response)=>{
            if (response.response < 200 || response.response >= 300) return showNotify(response)
            setTransaction(response.data.docs[0])
        });
    }
    Array.prototype.randomStatus = function(){
        return this[Math.floor(Math.random()*this.length)];
    }
    const rejectTransaction = (data)=>{
        var required = $('#reject input,#reject textarea,#reject select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });
        var form2 = getFormData($('#reject'))
        if (!validation(form2, required)) return;
        form2.notes = form2['Notes']
        delete form2['Notes'];
        console.log(form2)
        emit('decline_withdraw', form2, (response) => {
            showNotify(response);
            if (response.response < 200 || response.response >= 300) return ;
            $('#closeModal2').trigger('click');
            setRefresh(new Date().getTime())
        });
    }
    const accTransaction = (data)=>{
        console.log('accTransaction')
        var required = $('#admin input,#admin textarea,#admin select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });
        var form2 = getFormData($('#admin'))
        if (!validation(form2, required)) return;
        form2.struck = form2['No Reference']
        delete form2['No Reference'];
        form2.notes = form2['Notes']||null
        delete form2['Notes'];
        console.log(form2)
        emit('approve_withdraw', form2, (response) => {
            showNotify(response);
            if (response.response < 200 || response.response >= 300) return ;
            $('#closeModal').trigger('click');
            setRefresh(new Date().getTime())
        });
    }
    const getStatus = (data) => {
        var label = '';
        switch (data) {
            case 'waiting':
                label = <label className="text-yellow-500">Menunggu Pembayaran</label>
                break;
            case 'success':
                label = <label className="text-green-500">Selesai</label>
                break;
            case 'decline':
                label = <label className="text-red-500">Di Tolak</label>
                break;
            default:
                break;
        }
        return label;
    }
    const createBankSelect = () => {
        var select = document.getElementById('banks')
        if (!select) return;
        var control = select.tomselect;
        if (control) control.destroy();
        new TomSelect('#banks', {
            valueField: '_id',
            labelField: 'name',
            searchField: ['name'],
            plugins: {
                dropdown_input: {}
            },
            // fetch remote data
            load: function (query, callback) {
                var reqData = {
                    'text': query,
                    'page': 1,
                    'size': 1000,
                }
                emit('get_app_banks', reqData, (response) => {
                    if (response.response < 200 || response.response >= 300) return showNotify(response)
                    var result = response.data.docs.map((e) => {
                        return { name: `${e.bank_name} - ${e.account_name} -${e.account_number}`, _id: e._id }
                    })
                    callback(result);
                });
            },
        });
    }
    return (
        <div className="intro-y">
                {/* <!-- END: Top Bar --> */}
                <h2 className="intro-y text-lg font-medium mt-5">
                    Settlement Detail
                </h2>
            <div className="my-5 box pb-0.5">
                <div className=" text-red-500 text-2xl font-bold py-10 px-10">
                    Informasi
                </div>
                <div className="grid grid-cols-2 gap-4 px-10">
                    <div>
                        <div className="flex flex-nowrap my-1">
                            <div className="font-bold">Tanggal Approve :</div>
                            <div className="pl-1">{transactions.acc_date? moment(transactions.acc_date).format('LL') :'-'}</div>
                        </div>
                        <div className="flex flex-nowrap my-1">
                            <div className="font-bold">Nama Lawyer :</div>
                            <div className="pl-1">{transactions.lawyer?.name}</div>
                        </div>
                        <div className="flex flex-nowrap my-1">
                            <div className="font-bold">Di Validasi oleh :</div>
                            <div className="pl-1">{transactions.approveBy?.name || '-'}</div>
                        </div>
                        <div className="flex flex-nowrap my-1">
                            <div className="font-bold">No. Referensi :</div>
                            <div className="pl-1">{transactions.struck || '-'}</div>
                        </div>
                    </div>
                    <div>
                        <div className="flex flex-nowrap my-1">
                            <div className="font-bold">Tanggal Transfer :</div>
                            <div className="pl-1">{transactions.acc_date? moment(transactions.acc_date).format('L LT') :'-'}</div>
                        </div>
                        <div className="flex flex-nowrap my-1">
                            <div className="font-bold">Rekening Asal :</div>
                            <div className="pl-1">{transactions.fromRekening?.bank_name||'-'}</div>
                        </div>
                        <div className="flex flex-nowrap my-1">
                            <div className="font-bold">No. Rekening Asal :</div>
                            <div className="pl-1">{transactions.fromRekening?.bank_name||'-'} a/n. {transactions.fromRekening?.account_name||'-'}</div>
                        </div>
                        <div className="flex flex-nowrap my-1">
                            <div className="font-bold">Rekening Tujuan :</div>
                            <div className="pl-1">{transactions.rekening?.bank_name}</div>
                        </div>
                        <div className="flex flex-nowrap my-1">
                            <div className="font-bold">No. Rekening Tujuan :</div>
                            <div className="pl-1">{transactions.rekening?.account_number} a/n. {transactions.rekening?.account_name}</div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-5 gap-4 px-10 py-5 bg-gray-100 font-bold text-black mx-10 mt-10">
                    <div>Waktu Request</div>
                    <div>Nama Lawyer</div>
                    <div className="text-center">Jumlah Penarikan</div>
                    <div className="text-center">Nama Bank</div>
                    <div className="text-center">Status Penarikan</div>
                </div>
                <div className="grid grid-cols-5 gap-4 px-10 mx-10 my-5">
                    <div>{moment(transactions.createdAt).format('L LT')}</div>
                    <div className="flex">
                        <div style={{ aspectRatio: '1 / 1', objectFit: 'contain' }} className="w-10 h-10 image-fit zoom-in">
                            <img style={{ aspectRatio: '1 / 1', objectFit: 'cover' }} data-placement="right" className="rounded-full" src={transactions.lawyer?.fullPhoto||"https://cdn-icons-png.flaticon.com/512/149/149071.png"}title="Foto" />
                        </div>
                        <div className="text-gray-900 text-xs whitespace-nowrap mx-5 my-0.5" key="">
                            <div className="font-bold">{transactions.lawyer?.name}</div>
                            <div className="text-gray-400">{transactions.lawyer?.email || transactions.lawyer?.phone}</div>
                        </div>
                    </div>
                    <div className="text-center">Rp. {transactions.nominal?.toLocaleString()}</div>
                    <div className="text-center">{transactions.rekening?.bank_name}</div>
                    <div className="text-center">{getStatus(transactions.status)}</div>
                </div>

                <div className="flex place-content-between mt-40 mb-5 mx-10">
                    {transactions.status=='waiting' && transactions.valid && <div>
                        <AHrefJavascript href="#!" onClick={createBankSelect} data-toggle="modal" className="btn btn-success mx-2" data-target="#admin-modal">Approve</AHrefJavascript>
                        <AHrefJavascript href="#!" data-toggle="modal" className="btn btn-danger mx-2" data-target="#reject-modal">Rejected</AHrefJavascript>
                    </div>}
                    {!transactions.valid && transactions.status=='waiting' && <Link to={"/lawyers/history/"+transactions.lawyer?._id} className="btn btn-danger mx-2" >Validasi Saldo</Link>}
                    <div></div>
                    <Link to="/transactions/settlement" className="btn btn-dark" >Close</Link>
                </div>
            </div>
            <div id="admin-modal" className="modal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        {/* <!-- BEGIN: Modal Header --> */}
                        <div className="modal-header">
                            <h2 className="font-medium text-base mr-auto">Approve Transaction</h2>
                        </div>
                        {/* <!-- END: Modal Header --> */}
                        {/* <!-- BEGIN: Modal Body --> */}
                        <div className={`px-5 pt-5`}>
                            <form id='admin'>
                                <input type="hidden" name='idWithdraw' required value={transactions._id??''} />
                                <label htmlFor="modal-form-10" className="form-label">Rekening</label><label className='text-red-500'> *</label>
                                <div className="dropdown mr-2 relative text-gray-700 dark:text-gray-300">
                                    <select id="banks" name="bank" className="tom-select sm:mr-2" placeholder="Nomor Rekening">
                                        {/* <option value={'fixed'} label="Fixed">Fixed</option>
                                            <option value={'percentage'} label="Persentase">Persentase</option> */}
                                    </select>
                                </div>
                                <label htmlFor="modal-form-1" className="form-label">No. Reference</label><label className='text-red-500'> *</label>
                                <input id="modal-form-1" type="text" 
                                // value={'Testing kirim notify'}
                                 name='No Reference' className="form-control mb-4" required minLength="10" maxLength="40" placeholder="Max 40 Character" />
                                <label htmlFor="modal-form-2" className="form-label">Notes</label>
                                <textarea id="modal-form-2" className="form-control" 
                                // value={'Testing kirim notify 123123123123'}
                                 name="Notes" placeholder="Max 120 Character" minLength="10" maxLength={120}></textarea>
                            </form>
                        </div>
                        {/* <!-- END: Modal Body --> */}
                        {/* <!-- BEGIN: Modal Footer --> */}
                        <div className="modal-footer">
                            <button id="closeModal" type="button" data-dismiss="modal" className="btn btn-outline-secondary w-20 mr-1">Cancel</button>
                            <button id="approveTransaction" type="button" className="btn btn-primary w-20">Approve</button>
                        </div>
                        {/* <!-- END: Modal Footer --> */}
                    </div>
                </div>
            </div>
            <div id="reject-modal" className="modal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        {/* <!-- BEGIN: Modal Header --> */}
                        <div className="modal-header">
                            <h2 className="font-medium text-base mr-auto">Reject Transaction</h2>
                        </div>
                        {/* <!-- END: Modal Header --> */}
                        {/* <!-- BEGIN: Modal Body --> */}
                        <div className={`px-5 pt-5`}>
                            <form id='reject'>
                                <input type="hidden" name='idWithdraw' required value={transactions._id??''} />
                                <label htmlFor="modal-form-2" className="form-label">Notes</label>
                                <textarea id="modal-form-2" className="form-control" 
                                // value={'Testing kirim notify 123123123123'}
                                 name="Notes" placeholder="Max 120 Character" minLength="10" maxLength={120}></textarea>
                            </form>
                        </div>
                        {/* <!-- END: Modal Body --> */}
                        {/* <!-- BEGIN: Modal Footer --> */}
                        <div className="modal-footer">
                            <button id="closeModal2" type="button" data-dismiss="modal" className="btn btn-outline-secondary w-20 mr-1">Cancel</button>
                            <button id="rejectTransaction" type="button" className="btn btn-primary w-20">Reject</button>
                        </div>
                        {/* <!-- END: Modal Footer --> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransaksiWithdraw

