import { useState, useEffect } from 'react'
import { socket, loadScript, emit, validation, getFormData, showNotify } from '../context/socket'
import { useHistory } from "react-router-dom";
import Switch from './Switch';
import $ from 'jquery';
function AddKategoriKasus({ setPath }) {
    const history = useHistory();
    const [pinned, setPinned] = useState(false)
    useEffect(() => {
        setPath({title:'Kategori Kasus',path:'/kategorikasus'});
        loadScript()
    }, [])

    const createKategoriKasus = () => {
        var required = $('input,textarea,select').filter('[required]');
        required = $.map(required, function (value, index) {
            return value.name;
        });

        var form2 = getFormData($('#addKategoriKasus'))
        if (!validation(form2, required)) return;
        form2.pinned = pinned
        console.log(form2);
        emit('create_new_category', form2, (response) => {
            showNotify(response)
            if (response.response < 200 || response.response >= 300) return;
             history.push('/kategorikasus')
        });
    }
    return (
        <div className="intro-y">
            {/* <!-- END: Top Bar --> */}
            <h2 className="intro-y text-lg font-medium mt-10">
                Tambah Kategori Kasus
            </h2>
            <form id="addKategoriKasus">
                <div className="grid grid-cols-24 gap-6 mt-5">
                    {/* <!-- BEGIN: Input --> */}
                    <div className="intro-y box">
                        <div className="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
                            <h2 className="font-medium text-base mr-auto">
                                Tambah Kategori Kasus
                            </h2>
                            <h2 className='text-right'>
                                Pin Kasus
                                <Switch
                                    isOn={pinned}
                                    onColor="#1be708"
                                    handleToggle={() => setPinned(!pinned)}
                                />
                            </h2>
                        </div>
                        <div className="flex flex-col-reverse xl:flex-row flex-col">
                            <div id="input" className="flex-1 mt-6 xl:mt-0 p-5">
                                <div className="preview">
                                    <div>
                                        <label htmlFor="regular-form-1" className="form-label">Nama Kategori Kasus</label><span className="text-red-500"> *</span>
                                        <input id="regular-form-1" name="name" required type="text" className="form-control" />
                                    </div>
                                    <div className="mt-3">
                                        <label htmlFor="regular-form-2" className="form-label">Nama Kategori Kasus (ENG)</label><span className="text-red-500"> *</span>
                                        <input id="regular-form-2" name="english" required type="text" className="form-control" />
                                    </div>
                                    <div className="text-right mt-8">
                                        <button type="button" onClick={createKategoriKasus} className="btn btn-dark shadow-md">Tambah Kategori Kasus</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <!-- END: Input --> */}
                </div>

            </form>
        </div>
    )
}

export default AddKategoriKasus