import { useState, useEffect } from 'react'
import moment from 'moment';
import { setSelectValue, loadScript, emit, getFormData, validation, debounce, formatDate, showNotify, initPicker } from '../context/socket'
import Pagination from './Pagination';

import TomSelect from 'tom-select';
const AdminLogs = ({ setPath }) => {
    const [notify, setNotify] = useState([])
    const [currentPage, setCurrentPage] = useState({
        number:0,
        time:new Date().getTime()
    })
    const [docsCounts, setDocsCounts] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [lite, setLite] = useState(null)
    const [date, setDate] = useState(null)
    const [text, setText] = useState('')
    useEffect(() => {
        loadScript().then(()=>{
            initPicker('daterange',(data)=>setLite(data),(date1,date2)=>{
                setDate({ start: formatDate(date1), end: formatDate(date2) })
            })
            createAdminSelect()
        });

    }, [])
    useEffect(() => {
        setCurrentPage({number:1})
    }, [pageSize, text, date])
    useEffect(() => {
        if(currentPage.number!=0)
        getNotify(currentPage)
    }, [currentPage])
    
    const getNotify = (page) => {
        console.log('aaa')
        var endDate = new Date();
        var firstDay = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
        var reqData = {
            'date': {
                'start': date ? date.start : formatDate(firstDay),
                'end': date ? date.end : formatDate(endDate),
            },
            'full': true,
            'id':text,
            'page': page.number,
            'size': pageSize,
        }
        emit('get_web_notify_full', reqData, (response) => {
            if (response.response < 200 || response.response >= 300) return showNotify(response);
            setNotify(response.data.docs)
            setDocsCounts(response.data.counts)
        })
    }
    const createAdminSelect = (setting) => {
        var select = document.getElementById('admins')
        if (!select) return;
        var control = select.tomselect;
        if (control) control.destroy();
        new TomSelect('#admins', {
            valueField: '_id',
            labelField: 'name',
            searchField: ['name'],
            plugins: {
                dropdown_input: {}
            },
            // fetch remote data
            load: function (query, callback) {
                var reqData = {
                    'text': query,
                    'page': 1,
                    'size': 100,
                }
                emit('list_admin', reqData, (response) => {
                    if (response.response < 200 || response.response >= 300) return showNotify(response)
                    var result = response.data.docs.map((e) => {
                        return { name: e.name+' ('+e.email+')', _id: e._id }
                    })
                    callback(result);
                });
            },
        });
    }
    return (
        <div className="intro-y">
            {/* <!-- END: Top Bar --> */}
            <h2 className="intro-y text-lg font-medium mt-10">
                Daftar Log Admin / System
            </h2>
            <div className="grid grid-cols-12 gap-6 mt-5">
                <div className="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
                    <div className='mr-3'>Range Tanggal</div>
                    <div className="mr-auto mt-3 sm:mt-0 relative text-gray-700 dark:text-gray-300">
                        <i data-feather="calendar" className="w-4 h-4 z-10 absolute my-auto inset-y-0 ml-3 left-0"></i>
                        <input style={{ width: '115%' }} type="text" id="daterange" className="form-control box pl-10" />
                    </div>
                    <div className="hidden md:block mx-auto text-gray-600">Showing {((currentPage.number * pageSize) - pageSize) + 1} to {(currentPage.number * pageSize)} of {docsCounts} entries</div>
                    <div className="w-full sm:w-auto flex mt-4 sm:mt-0">
                        <div onChange={(e) => {setText(e.target.value)}} className="dropdown mr-2 w-72 relative text-gray-700 dark:text-gray-300">
                            <select id="admins" defaultValue={'Cari Admin'} name="admin" className="tom-select sm:mr-2" placeholder="Nama / Email Admin">
                                <option value={'Cari Admin'} disabled label="Cari Admin">Cari Admin</option>
                            </select>
                        </div>
                    </div>
                </div>
                {/* <!-- BEGIN: Data List --> */}
                <div className="intro-y col-span-12 overflow-auto">
                    <table className="table table-report -mt-2">
                        <thead>
                            <tr>
                                <th className="whitespace-nowrap">No.</th>
                                <th className="whitespace-nowrap" colSpan="2">Nama User</th>
                                <th className="text-center whitespace-nowrap">ID Logs</th>
                                <th className="text-center whitespace-nowrap">Keterangan</th>
                                <th className="text-center whitespace-nowrap">Waktu</th>
                            </tr>
                        </thead>
                        <tbody>
                            {notify.map((d, i) => {
                                i *= 1
                                return (
                                    <tr key={i} className="intro-x">
                                        <td className="w-1">
                                            <div className="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                                                {++i}
                                            </div>
                                        </td>
                                        <td className="w-1">
                                            <div className="flex">
                                                <div className="w-10 h-10 image-fit zoom-in">
                                                    <img className="tooltip rounded-full" src={d.admin?.fullPhoto ?? 'https://cdn-icons-png.flaticon.com/512/149/149071.png'} title="Foto" />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {/* <a href="" className="font-medium whitespace-nowrap">Dell XPS 13</a>  */}
                                            <div>{d.admin?.name || 'System'}</div>
                                            <div className="text-gray-600 text-xs whitespace-wrap mt-0.5" key={d._id}>
                                                {d.admin?.email || ''}
                                            </div>
                                        </td>
                                        <td className="text-center">{d._id}</td>
                                        <td className="text-center">{d.keterangan}</td>
                                        <td className="text-center">{moment(new Date(d.createdAt)).format('L LT')}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {/* <!-- BEGIN: Pagination --> */}
                <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage.number}
                        totalCount={docsCounts}
                        pageSize={pageSize}
                        onPageChange={page => setCurrentPage({number:page})}
                    />
                    <div className='w-full'></div>
                    <select defaultValue={10} onChange={(e) => setPageSize(parseInt(e.target.value))} style={{ width: '100px' }} className="form-select box mt-3 sm:mt-0">
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={35}>35</option>
                        <option value={50}>50</option>
                    </select>
                </div>
                {/* <!-- END: Pagination --> */}

            </div>
        </div>
    )
}

export default AdminLogs