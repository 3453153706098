import React, { Component, useContext } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import Home from '../components/Home'
import User from '../components/User'
import UserProfile from '../components/UserProfile'
import Pengaduan from '../components/Pengaduan'
import PengaduanAlter from '../components/PengaduanAlter'
import Lawyer from '../components/Lawyer'
import AddLawyer from '../components/LawyersAdd'
import EditLawyer from '../components/LawyerEdit'
import Login from '../components/Login'
import Transaksi from '../components/Transaksi'
import TransaksiAlter from '../components/TransaksiAlter'
import TransaksiDetail from '../components/TransaksiDetail';
import TransaksiSettle from '../components/TransaksiSettle';
import TransaksiWithdraw from '../components/TransaksiWithdraw';
import Promo from '../components/Promo'
import PromoAdd from '../components/PromoAdd'
import DetailLawyer from '../components/LawyerView'
import TransaksiMetode from '../components/TransaksiMetode'
import RouteRequiresLogin from './private'
import PengaduanDetail from '../components/PengaduanDetail';
import PengaduanConversation from '../components/PengaduanConversation';
import AddKategoriKasus from '../components/KategoriKasusAdd'
import KategoriKasus from '../components/KategoriKasus'
import EditKategoriKasus from '../components/KategoriKasusEdit';
import UserEdit from '../components/UserEdit';
import Admin from '../components/Admin';
import AdminAdd from '../components/AdminAdd';
import AdminEdit from '../components/AdminEdit';
import AppsSetting from '../components/AppsSetting';
import PageNotFound from '../components/404NotFound';
import DetailConversation from '../components/PengaduanConversation';
import DetailPengaduan from '../components/PengaduanDetail';
import PromoEdit from '../components/PromoEdit';
import TransaksiOptionEdit from '../components/TransaksiOptionEdit';
import TransaksiOptionAdd from '../components/TransaksiOptionAdd';
import TransaksiMetodeEdit from '../components/TransaksiMetodeEdit';
import TransaksiMetodeAdd from '../components/TransaksiMetodeAdd';
import LawyerHistory from '../components/LawyerHistory';
import { useCookies } from 'react-cookie';
import AdminLogs from '../components/AdminLogs';
import { AppStateContext } from "../context/AppStateContext";

const Routes = ({ setPath }) => {
    const {page, setPage, setToggle } = useContext(AppStateContext);
    const [cookies, setCookie] = useCookies(['token']);
    const user = cookies.user;
    const allow = (allowed)=>{
        return allowed.includes(user?.role)
    }
    const denied = (allowed)=>{
        return !allowed.includes(user?.role)
    }
    return (
        <>
            <RouteRequiresLogin>
                <Switch>
                    <Home path="/home" setPath={setPage} />

                    {denied(['marketing','finance']) &&<EditLawyer path="/lawyers/edit/:lawyer" setPath={setPage} />}
                    <DetailLawyer path="/lawyers/view/:lawyer" setPath={setPage} />
                    {denied(['marketing','finance']) &&<AddLawyer path="/lawyers/add" setPath={setPage} />}
                    {denied(['marketing']) &&<LawyerHistory path="/lawyers/history/:lawyer" setPath={setPage} />}
                    <Lawyer path="/lawyers" setPath={setPage} />

                    {denied(['marketing','finance']) &&<UserEdit path="/users/edit/:id/" setPath={setPage} />}
                    <UserProfile path="/users/profile/:id/" setPath={setPage} />
                    <UserProfile path="/users/profile" setPath={setPage} />
                    <AdminLogs path="/users/logs" setPath={setPage} />
                    <User path="/users" setPath={setPage} />

                    {denied(['marketing']) &&<TransaksiMetodeAdd path="/transactions/metode-add" setPath={setPage} />}
                    {denied(['marketing']) &&<TransaksiMetodeEdit path="/transactions/metode-edit/:id" setPath={setPage} />}
                    {denied(['marketing']) &&<TransaksiOptionAdd path="/transactions/metode-option-add/:id" setPath={setPage} />}
                    {denied(['marketing']) &&<TransaksiOptionEdit path="/transactions/metode-option-edit/:id" setPath={setPage} />}
                    {denied(['marketing']) &&<TransaksiMetode path="/transactions/metode" setPath={setPage} />}
                    {denied(['marketing']) &&<TransaksiDetail path="/transactions/view/:id" setPath={setPage} />}
                    {denied(['marketing']) &&<TransaksiWithdraw path="/transactions/settlement/view/:id" setPath={setPage} />}
                    {denied(['marketing']) &&<TransaksiSettle path="/transactions/settlement" setPath={setPage} />}
                    {denied(['marketing']) &&<TransaksiAlter path="/transactions" setPath={setPage} />}
                    {/* <Transaksi path="/transactions" setPath={setPage} /> */}

                    {/* <DetailConversation path="/pengaduan/conversation/:id" setPath={setPage} /> */}
                    <DetailPengaduan path="/pengaduan/view/:id" setPath={setPage} />
                    {/* <Transaksi path="/transactions" setPath={setPage} /> */}

                    {denied(['marketing','finance']) &&<PengaduanConversation path="/pengaduan/conversation/:id" setPath={setPage} />}
                    <PengaduanDetail path="/pengaduan/view/:id" setPath={setPage} />
                    <PengaduanAlter path="/pengaduan/alter" setPath={setPage} />
                    {/* <Pengaduan path="/pengaduan" setPath={setPage} /> */}


                    {<PromoEdit path="/promo/edit/:id" setPath={setPage} />}
                    {<PromoAdd path="/promo/create" setPath={setPage} />}
                    {<Promo path="/promo" setPath={setPage} />}

                    {denied(['marketing','finance']) &&<AddKategoriKasus path="/kategorikasus/add" setPath={setPage} />}
                    {denied(['marketing','finance']) &&<EditKategoriKasus path="/kategorikasus/edit/:id" setPath={setPage} />}
                    <KategoriKasus path="/kategorikasus" setPath={setPage} />
                    {/* {user?.role == 'super admin' ?
                        <AdminEdit path="/admins/edit/:id" setPath={setPage} ></AdminEdit> : null}
                    {user?.role == 'super admin' ? <AdminAdd path="/admins/create" setPath={setPage} ></AdminAdd> : null}
                    {user?.role == 'super admin' ? <Admin path="/admins" setPath={setPage} ></Admin> : null}
                    {user?.role == 'super admin' ?<AppsSetting path="/settings" setPath={setPage}></AppsSetting> : null} */}
                    <AdminEdit path="/admins/edit/:id" setPath={setPage} ></AdminEdit>
                    {allow(['super admin']) && <AdminAdd path="/admins/create" setPath={setPage} ></AdminAdd> }
                    {allow(['super admin']) && <Admin path="/admins" setPath={setPage} ></Admin> }
                    {allow(['super admin']) && <AppsSetting path="/settings" setPath={setPage}></AppsSetting> }
                    <Route path="/404" component={PageNotFound} />
                    <Route path="*" component={PageNotFound} >
                        {/* <Redirect to="/404" /> */}
                    </Route>
                    <Home path="/" setPath={setPage} />
                    <Home path="" setPath={setPage} />
                </Switch>
            </RouteRequiresLogin>
            {/* <RouteRequiresLogin path="/lawyers">
                <Lawyer
                    socket={socket}
                    loadScript={loadScript}
                />
            </RouteRequiresLogin> */}
            <Route exact path='/login' render={(props) => <Login {...props} />} />
            {/* <RouteRequiresLogin exact path="/home"><Home setPath={setPage} keyProp={'test'} key={Date.now()} /></RouteRequiresLogin> */}
            {/* <RouteRequiresLogin exact path="/"><Home setPath={setPage} keyProp={'test'} key={Date.now()} /></RouteRequiresLogin> */}
            {/* <Route path="*">
                    <Redirect to="/404" />
                </Route> */}
        </>
    );
}

export default Routes;
