import { useState, useEffect } from 'react'
import { socket, loadScript, emit, showNotify } from '../context/socket'
import { LoremIpsum } from "react-lorem-ipsum";
import moment from 'moment';
import { Link } from "react-router-dom";
const TransaksiDetail = ({ setPath, location }) => {
    const [transactions, setTransaction] = useState({})
    useEffect(() => {
        const getUser = async (id) => {
            emit('detail_transaction_admin', { idTransaction: id }, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setTransaction(response.data)
            });
        }

        setPath({title:'Transaksi',path:'/transactions'});
        loadScript();
        getUser(location.pathname.split('/').reverse()[0])
    }, [])
    const paymentName = (data) => {
        if (!data.midtrans.payment_type) return '';
        return data.midtrans.payment_type.replaceAll('_', ' ').toUpperCase() + ' - ' + data.payment_type.bank.toUpperCase();
    }
    const getStatus = (data) => {
        var label = '';
        switch (data) {
            case 'pending':
                label = <div className="btn btn-sm btn-warning mx-5">Belum Bayar</div>
                break;
            case 'success':
                label = <div className="btn btn-sm btn-success-soft mx-5">Berhasil</div>
                break;
            case 'failed':
                label = <div className="btn btn-sm btn-danger-soft mx-5">Dibatalkan</div>
                break;
            default:
                break;
        }
        return label;
    }
    const getNumber = (data) => {
        if (!data.midtrans) return ''
        if (data.midtrans.va_numbers)
            return data.midtrans.va_numbers[0].va_number
        if (data.midtrans.biller_code)
            return data.midtrans.biller_code + '-' + data.midtrans.bill_key
        if (data.midtrans.payment_type == 'gopay')
            return '-'
        return '';
    }
    const promoNominal = (data)=>{
        var promo = data.promo;
        var price = data.price
        if(promo.type=='fixed')
            return promo.value
        else return (price * promo.value / 100)
    }
    const finalPrice = (data)=>{
        var promo = data.promo;
        var price = data.price
        if(!promo) return data.price
        if(promo.type=='fixed')
            return price - promo.value
        else return price - (price * promo.value / 100)
    }
    return (
        <div className="intro-y">
            {/* <!-- END: Top Bar --> */}
            <h2 className="intro-y text-lg font-medium mt-10">
                Transaction Detail
            </h2>
            <div className="my-5 box pb-0.5">
                <div className=" text-red-500 text-lg font-bold py-10 px-10">
                    Invoice #{transactions.invoice}
                    {getStatus(transactions.status)}
                </div>
                <div className="grid grid-cols-2 gap-4 px-10">
                    <div>
                        <div className="flex flex-nowrap my-1">
                            <div className="font-bold">User :</div>
                            <div className="pl-1">{transactions.id_user?.name || ''}</div>
                        </div>
                        <div className="flex flex-nowrap my-1">
                            <div className="font-bold">Email :</div>
                            <div className="pl-1">{transactions.id_user?.email || ''}</div>
                        </div>
                        <div className="flex flex-nowrap my-1">
                            <div className="font-bold">No. HP :</div>
                            <div className="pl-1">{transactions.id_user?.phone || ''}</div>
                        </div>
                    </div>
                    <div>
                        <div className="flex flex-nowrap my-1">
                            <div className="font-bold">Payment Method :</div>
                            <div className="pl-1">{transactions.payment_type?.name || ''}</div>
                        </div>
                        <div className="flex flex-nowrap my-1">
                            <div className="font-bold">Account Number :</div>
                            <div className="pl-1">{getNumber(transactions)}</div>
                        </div>
                        <div className="flex flex-nowrap my-1">
                            <div className="font-bold">Valid Until :</div>
                            <div className="pl-1">{moment(transactions.midtrans?.transaction_time).add(10, 'minutes').format('LLL')}</div>
                        </div>
                        <div className="flex flex-nowrap my-1">
                            <div className="font-bold">Payment Date :</div>
                            <div className="pl-1">{transactions.payment_date ? moment(transactions.payment_date).format('LLL') : '-'}</div>
                        </div>
                    </div>
                </div>
                <div className='mx-10 mt-10'>
                    <table className="table table-hover table-auto">
                        <tbody>
                            <tr className='font-bold text-black'>
                                <td className="whitespace-nowrap bg-gray-100">Date</td>
                                <td className="whitespace-nowrap bg-gray-100">Advocate</td>
                                <td className="whitespace-nowrap text-center bg-gray-100">Price</td>
                            </tr>
                            <tr>
                                <td>
                                    <div>{moment(transactions.midtrans?.transaction_time).format('L LT')}</div>
                                </td>
                                <td className=" text-center font-bold">
                                    <div className="flex">
                                        <div className="w-14 h-14 image-fit">
                                            <img data-placement="right" style={{ aspectRatio: '1 / 1', objectFit: 'cover' }} className="rounded-full" src={transactions.id_lawyer?.fullPhoto || "https://cdn-icons-png.flaticon.com/512/149/149071.png"} title="Foto" />
                                        </div>
                                        <div className="text-gray-900 text-xs whitespace-nowrap mx-5 my-0.5" key="">
                                            <div className="font-bold text-left">{transactions.id_lawyer?.name}{transactions.promo? <Link to={'/promo/edit/'+transactions.promo._id} className="btn btn-sm btn-rounded btn-primary text-xs ml-5 px-5">Promo: {transactions.promo.code}</Link>:<></>}</div>
                                            <div className="text-gray-400 text-left">{transactions.id_lawyer?.email}</div>
                                        </div>
                                    </div>
                                </td>
                                <td className=" border-gray-200 text-center ">Rp. {transactions.price?.toLocaleString()}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="mx-10 my-10">
                    <table className="table table-bordered table-hover border-2 border-gray-200 table-auto">
                        <tbody>
                            <tr>
                                <td className="whitespace-nowrap"></td>
                                <td className="whitespace-nowrap border-2 border-gray-200 text-center font-bold bg-gray-400">Discount</td>
                                <td className="whitespace-nowrap border-2 border-gray-200 text-center bg-gray-300">Rp. {transactions.promo?promoNominal(transactions).toLocaleString():'0 ,-'}</td>
                            </tr>
                            <tr>
                                <td>
                                    <div className="font-bold">Laporhukum - PT Bertiga Banyak Berkat</div>
                                    <div>Infiniti Office, MTH Square Ground Floor (GF) A4 A, Jl. Letjen M.T. Haryono Kav 10, RT/RW. 006/012,</div>
                                    <div>Kelurahan Bidara Cina, Kecamatan Jatinegara, Jakarta Timur 13330</div>
                                </td>
                                <td className="border-2 border-gray-200 text-center font-bold bg-gray-400">PPN 0%</td>
                                <td className="border-2 border-gray-200 text-center bg-gray-300">Rp. 0 ,-</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td className="border-2 border-gray-200 text-center font-bold bg-gray-400">Total</td>
                                <td className="font-bold border-2 border-gray-200 text-center bg-gray-300">Rp. {finalPrice(transactions)?.toLocaleString()}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="flex place-content-end my-5 mx-10">
                    <Link to="/transactions" className="btn btn-dark" >Close</Link>
                </div>
            </div>
        </div>
    )
}

export default TransaksiDetail

