import { useState, useEffect } from 'react'
import { socket, loadScript, emit } from '../context/socket'
import $ from 'jquery';
import { Link } from "react-router-dom";
import moment from 'moment';

const DetailConversation = ({ setPath }) => {
    const [detail, setDetail] = useState({})
    const [user, setUser] = useState({})
    useEffect(() => {
        const getConversation = async (id) => {
            emit('view_chat', { idConv: id }, (response) => {
                var data = {};
                data[response.data.conversation.id_lawyer._id] = response.data.conversation.id_lawyer;
                data[response.data.conversation.id_user._id] = response.data.conversation.id_user;
                setUser(data)
                setDetail(response.data);
            });
        }
        getConversation(location.pathname.split('/').reverse()[0])
    }, [])
    useEffect(() => {
        setPath({ title: 'Pengaduan', path: '/pengaduan/alter' });
        loadScript();
    }, [])
    return (
        <>
            <div className="intro-y">
                {/* <!-- END: Top Bar --> */}
                <h2 className="intro-y text-lg font-medium my-5">
                    Detail Conversation
                </h2>
            </div>
            <div className="intro-y chat grid grid-cols-12 gap-5 mt-5">
                <div className="col-span-12 lg:col-span-4 2xl:col-span-3">
                    <div id="profile" className="tab-pane" role="tabpanel" aria-labelledby="profile-tab">
                        <div className="pr-1">
                            <div className="box">
                                <div className='p-2 font-bold'>Profile User</div>
                                <hr></hr>
                                <div className='px-5 py-5'>
                                    <div className="w-20 h-20 flex-none image-fit rounded-full overflow-hidden mx-auto">
                                        <img alt="Photo User" src={detail.conversation?.id_user?.fullPhoto} />
                                    </div>
                                    <div className="text-center mt-3">
                                        <div className="font-medium text-lg">{detail.conversation?.id_user?.name}</div>
                                        <div className="text-slate-500 mt-1">{detail.conversation?.id_user?.email || detail.conversation?.id_user?.phone}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="box bg-primary text-white mt-5">
                                <div className='p-2 font-bold'>Profile Advocate</div>
                                <hr></hr>
                                <div className='px-5 py-5'>
                                    <div className="w-20 h-20 flex-none image-fit rounded-full overflow-hidden mx-auto">
                                        <img alt="Photo Advocate" src={detail.conversation?.id_lawyer?.fullPhoto} />
                                    </div>
                                    <div className="text-center mt-3">
                                        <div className="font-medium text-lg">{detail.conversation?.id_lawyer?.name}</div>
                                        <div className="text-white mt-1">{detail.conversation?.id_lawyer?.email || detail.conversation?.id_user?.phone}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Link to="/pengaduan/alter" className="btn btn-secondary px-10 my-5">Back</Link>
                </div>
                <div className="intro-y col-span-12 lg:col-span-8 2xl:col-span-9">
                    <div className="intro-y col-span-12 lg:col-span-8 2xl:col-span-9">
                        <div className="chat__box box">
                            {/* <!-- BEGIN: Chat Active --> */}
                            <div className="h-full flex flex-col">
                                <div className="flex flex-col sm:flex-row border-b border-slate-200/60 dark:border-darkmode-400 px-5 py-4">
                                    <div className="flex items-center">
                                        {/* <div className="w-10 h-10 sm:w-12 sm:h-12 flex-none image-fit relative">
                                            <img alt="Midone - HTML Admin Template" className="rounded-full" src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"}/>
                                        </div> */}
                                        <div className="ml-3 mr-auto">
                                            <div className="font-medium text-base">Pengaduan Percakapan #{detail.conversation?._id}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="overflow-y-scroll scrollbar-hidden px-5 pt-5 h-full">

                                    {$.isEmptyObject(detail) ?
                                        <div>Loading...</div> :
                                        detail.chats.map((d) => {
                                            var isLawyer = detail.conversation.id_user._id == d.sender
                                            if (d.sender == null)
                                                return (
                                                    <div key={d._id + '1'}>
                                                        <div key={d._id} title={d._id} className={"chat__box__text-box flex items-end float-left mb-4"}>
                                                            <div className="w-10 h-10 hidden sm:block flex-none image-fit relative mr-5">
                                                                <img alt="Photo System" className="rounded-full" src={"https://cdn-icons-png.flaticon.com/512/149/149071.png"} />
                                                            </div>
                                                            <div className="bg-slate-100 dark:bg-darkmode-400 px-4 py-3 text-slate-500 rounded-r-md rounded-t-md">
                                                                {d['dencryptedText'] ?? 'Kosong'}
                                                                <div className="mt-1 text-xs text-slate-500">{d.sender ? user[d.sender].name : 'System'}</div>
                                                            </div>
                                                        </div>
                                                        <div key={d._id + '3'} title={d._id + '3'} className="clear-both"></div>
                                                    </div>
                                                )
                                            else
                                                return (
                                                    <div key={d._id + '1'}>
                                                        <div key={d._id} title={d._id} className={"chat__box__text-box flex items-end float-left mb-4" + (isLawyer ? '' : ' hidden ')}>
                                                            <div className="w-10 h-10 hidden sm:block flex-none image-fit relative mr-5">
                                                                <img alt="Photo Advocate" className="rounded-full" src={user[d.sender].fullPhoto} />
                                                            </div>
                                                            <div className="bg-slate-100 dark:bg-darkmode-400 px-4 py-3 text-slate-500 rounded-r-md rounded-t-md">
                                                                {d.fullFile ?
                                                                    <div>
                                                                        <div className="block w-40 mx-auto">
                                                                            <img style={{ aspectRatio: '1 / 1', objectFit: 'cover' }} className="tooltip w-full" src={d.fullFile} title="Foto" />
                                                                            <div>{d['dencryptedText'] ?? 'Kosong'}</div>
                                                                        </div>
                                                                    </div> : <div>{d['dencryptedText'] ?? 'Kosong'}</div>}
                                                                {/* <div className="mt-1 text-xs text-slate-500">{d.sender ? user[d.sender].name : 'System'}</div> */}
                                                                <div className="mt-1 text-xs text-slate-500">{d.send_time ? moment(d.send_time).format('LLL') : '-'}</div>
                                                            </div>
                                                        </div>
                                                        <div key={d._id + '2'} title={d._id + '2'} className={"chat__box__text-box flex items-end float-right mb-4" + (isLawyer ? ' hidden ' : ' ')}>
                                                            <div className="bg-primary px-4 py-3 text-white rounded-l-md rounded-t-md">
                                                                {d['dencryptedText'] ?? 'Kosong'}
                                                                {/* <div className="mt-1 text-xs text-white">{d.sender ? user[d.sender].name : 'System'}</div> */}
                                                                <div className="mt-1 text-xs text-white">{d.send_time ? moment(d.send_time).format('LLL') : '-'}</div>
                                                            </div>
                                                            <div className="w-10 h-10 hidden sm:block flex-none image-fit relative ml-5">
                                                                <img alt="Photo User" className="rounded-full" src={user[d.sender].fullPhoto} />
                                                            </div>
                                                        </div>
                                                        <div key={d._id + '3'} title={d._id + '3'} className="clear-both"></div>
                                                    </div>
                                                )
                                        })
                                    }
                                </div>
                            </div>
                            {/* <!-- END: Chat Active --> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailConversation