import { useState, useEffect,useContext } from 'react'
import { Link } from 'react-router-dom';
import { socket,loadScript,emit } from '../context/socket'

const PageNotFound = () => {
    useEffect(() => {
        loadScript();
    }, [])
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            minHeight: '100vh'
        }}>
            <div className="w-full">
                <div className="flex justify-center">
                    <div className="text-4xl w-1/2">
                        <svg className="ml-auto" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="482.307" height="417.338" viewBox="0 0 482.307 417.338">
                            <defs>
                                <linearGradient id="linear-gradient" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
                                    <stop offset="0" stopColor="gray" stopOpacity="0.251"></stop>
                                    <stop offset="0.54" stopColor="gray" stopOpacity="0.122"></stop>
                                    <stop offset="1" stopColor="gray" stopOpacity="0.102"></stop>
                                </linearGradient>
                                <linearGradient id="linear-gradient-2" x1="-0.512" y1="7.758" x2="-0.512" y2="6.717" xlinkHref="#linear-gradient"></linearGradient>
                                <linearGradient id="linear-gradient-3" x1="-1.386" y1="7.85" x2="-1.386" y2="6.809" xlinkHref="#linear-gradient"></linearGradient>
                                <linearGradient id="linear-gradient-5" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                                    <stop offset="0" stopColor="#8b9ffe"></stop>
                                    <stop offset="1" stopColor="#9d59e2"></stop>
                                </linearGradient>
                            </defs>
                            <g id="undraw_Taken_if77" transform="translate(0 -0.001)">
                                <g id="Group_1" data-name="Group 1" transform="translate(163.95 10.824)" opacity="0.8">
                                    <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="84.094" cy="65.146" rx="84.094" ry="65.146" fill="url(#linear-gradient)"></ellipse>
                                </g>
                                <ellipse id="Ellipse_2" data-name="Ellipse 2" cx="82.228" cy="63.699" rx="82.228" ry="63.699" transform="translate(165.816 12.271)" fill="#565472"></ellipse>
                                <path id="Path_1" data-name="Path 1" d="M482.235,178.6c0-35.18,36.81-63.7,82.222-63.7a100.791,100.791,0,0,1,40.973,8.458c-14.452-9.761-33.36-15.692-54.053-15.692-45.411,0-82.228,28.519-82.228,63.7,0,23.622,16.6,44.24,41.26,55.241C493.145,214.932,482.235,197.758,482.235,178.6Z" transform="translate(-303.127 -95.554)" fill="#fff" opacity="0.1"></path>
                                <path id="Path_2" data-name="Path 2" d="M296.792,176.9l91.156,157.887L479.1,492.667H114.48l91.156-157.881Z" transform="translate(-48.748 -75.328)" fill="#ededf4" opacity="0.2"></path>
                                <g id="Group_2" data-name="Group 2" transform="translate(195.842 256.83)" opacity="0.3">
                                    <path id="Path_3" data-name="Path 3" d="M594.915,602.151l7.08-21.009a35.978,35.978,0,0,0-13.321-40.7l.057-.034a35.3,35.3,0,0,0-9.066-4.593h0l-.1-.034-.109-.034h0a35.342,35.342,0,0,0-9.7-1.826,34.039,34.039,0,0,0-3.508,0,35.786,35.786,0,0,0-6.023.833c-.574.126-1.148.27-1.671.425s-1.108.322-1.654.5c-1.091.362-2.165.769-3.215,1.234-.528.23-1.051.471-1.562.729a35.287,35.287,0,0,0-4.45,2.618c-.477.327-.942.666-1.4,1.011a35.6,35.6,0,0,0-12.2,17l-11.128,33.1a35.518,35.518,0,0,0-1.539,16.146c.115.833.258,1.659.425,2.48s.373,1.631.574,2.435c.155.534.316,1.068.494,1.6.264.787.574,1.573.867,2.343.212.511.436,1.022.672,1.527.465,1,.982,1.987,1.539,2.945a31.086,31.086,0,0,0,1.814,2.8c.482.678.993,1.344,1.522,1.987a29.991,29.991,0,0,0,2.251,2.486,35.489,35.489,0,0,0,12.058,7.975h0l.2.08a12.424,12.424,0,0,1,.8-1.246c1.723-2.36,4.72-4.019,7.573-3.4.207.046.413.109.62.172,1.447.511,2.871,1.424,4.266,1.872a3.824,3.824,0,0,0,3.221-.04c2.791-1.487,1.757-6.178,4.151-8.234a3.72,3.72,0,0,1,.35-.264,1.079,1.079,0,0,1-.092-.132c1.849-1.148,4.41-.385,6.54.356,2.3.81,5.294,1.355,6.89-.522,1.079-1.286,1-3.244,2.027-4.593,1.194-1.55,3.445-1.722,5.392-1.447l.1.029c3.732,1.539,7.809-1.016,7.522-5.041a31.443,31.443,0,0,1,1.734-11.524Z" transform="translate(-521.084 -533.871)" fill="url(#linear-gradient-2)"></path>
                                </g>
                                <path id="Path_4" data-name="Path 4" d="M546.466,634.579c1.677-2.3,4.553-3.9,7.309-3.279s5.369,3.25,7.82,1.935c3.353-1.791.976-8.377,6.419-8.865a7.145,7.145,0,0,1,3.313.574c2.3.936,5.891,1.992,7.66-.115,1.039-1.24.965-3.129,1.958-4.415,1.148-1.493,3.336-1.682,5.2-1.4l.092.029c3.6,1.487,7.539-.982,7.258-4.863a30.029,30.029,0,0,1,1.636-11.093l6.821-20.274a34.669,34.669,0,0,0-21.732-43.8h0a34.669,34.669,0,0,0-43.792,21.738l-10.754,31.884a34.675,34.675,0,0,0,19.993,43.149,12.31,12.31,0,0,1,.8-1.206Z" transform="translate(-326.436 -278.469)" fill="#fff"></path>
                                <circle id="Ellipse_3" data-name="Ellipse 3" cx="3.738" cy="3.738" r="3.738" transform="translate(229.004 275.358)" opacity="0.2"></circle>
                                <circle id="Ellipse_4" data-name="Ellipse 4" cx="3.738" cy="3.738" r="3.738" transform="translate(250.26 282.506)" opacity="0.2"></circle>
                                <ellipse id="Ellipse_5" data-name="Ellipse 5" cx="5.604" cy="3.738" rx="5.604" ry="3.738" transform="matrix(0.319, -0.948, 0.948, 0.319, 233.287, 300.995)" fill="#e0e0e0"></ellipse>
                                <circle id="Ellipse_6" data-name="Ellipse 6" cx="5.604" cy="5.604" r="5.604" transform="translate(311.645 274.318)" fill="#fff"></circle>
                                <circle id="Ellipse_7" data-name="Ellipse 7" cx="2.802" cy="2.802" r="2.802" transform="translate(332.2 268.307)" fill="#fff"></circle>
                                <g id="Group_4" data-name="Group 4" transform="translate(203.306 31.179)" opacity="0.5">
                                    <g id="Group_3" data-name="Group 3" opacity="0.3">
                                        <path id="Path_5" data-name="Path 5" d="M607.915,209.151l7.08-21.009a35.978,35.978,0,0,0-13.321-40.7l.057-.034a35.3,35.3,0,0,0-9.066-4.593h0l-.1-.034-.109-.034h0a35.341,35.341,0,0,0-9.7-1.826,34.038,34.038,0,0,0-3.508,0,35.783,35.783,0,0,0-6.023.833c-.574.126-1.148.27-1.671.425s-1.108.322-1.654.5c-1.091.362-2.165.769-3.215,1.234-.528.23-1.051.471-1.562.729a35.3,35.3,0,0,0-4.45,2.618c-.477.327-.942.666-1.4,1.011a35.6,35.6,0,0,0-12.2,17l-11.128,33.1a35.517,35.517,0,0,0-1.539,16.146c.115.833.258,1.659.425,2.48s.373,1.631.574,2.435c.155.534.316,1.068.494,1.6.264.787.574,1.573.867,2.343.212.511.436,1.022.672,1.527.465,1,.982,1.987,1.539,2.946a31.028,31.028,0,0,0,1.814,2.8c.482.678.993,1.344,1.522,1.987.712.861,1.458,1.694,2.251,2.486a35.49,35.49,0,0,0,12.058,7.975h0l.2.08a12.414,12.414,0,0,1,.8-1.246c1.722-2.36,4.72-4.019,7.573-3.4.207.046.413.109.62.172,1.447.511,2.871,1.424,4.266,1.872a3.824,3.824,0,0,0,3.221-.04c2.79-1.487,1.757-6.178,4.151-8.234a3.746,3.746,0,0,1,.35-.264,1.094,1.094,0,0,1-.092-.132c1.849-1.148,4.41-.385,6.54.356,2.3.81,5.294,1.355,6.89-.522,1.079-1.286,1-3.244,2.027-4.593,1.194-1.55,3.445-1.723,5.391-1.447h.1c3.732,1.539,7.809-1.016,7.522-5.041A31.378,31.378,0,0,1,607.915,209.151Z" transform="translate(-534.084 -140.871)" fill="url(#linear-gradient-3)"></path>
                                    </g>
                                    <path id="Path_6" data-name="Path 6" d="M559.466,241.579c1.677-2.3,4.553-3.9,7.309-3.279s5.368,3.25,7.82,1.935c3.353-1.791.976-8.377,6.419-8.865a7.143,7.143,0,0,1,3.313.574c2.3.936,5.891,1.992,7.659-.115,1.039-1.24.965-3.129,1.958-4.415,1.148-1.493,3.336-1.682,5.2-1.4l.092.029c3.6,1.487,7.539-.982,7.258-4.863a30.027,30.027,0,0,1,1.636-11.093l6.821-20.274a34.669,34.669,0,0,0-21.733-43.8h0a34.669,34.669,0,0,0-43.792,21.738l-10.754,31.884a34.674,34.674,0,0,0,19.993,43.149,12.313,12.313,0,0,1,.8-1.206Z" transform="translate(-535.278 -142.299)" fill="#fff"></path>
                                    <circle id="Ellipse_8" data-name="Ellipse 8" cx="3.738" cy="3.738" r="3.738" transform="translate(33.162 18.528)" opacity="0.2"></circle>
                                    <circle id="Ellipse_9" data-name="Ellipse 9" cx="3.738" cy="3.738" r="3.738" transform="translate(54.418 25.676)" opacity="0.2"></circle>
                                </g>
                                <path id="Path_7" data-name="Path 7" d="M631.345,186a50.246,50.246,0,0,1,1.6,12.632c0,35.18-36.81,63.7-82.222,63.7-37.166-.023-68.568-19.12-78.725-45.325,7.545,29.151,40.766,51.1,80.62,51.1,45.411,0,82.228-28.519,82.228-63.7A50.619,50.619,0,0,0,631.345,186Z" transform="translate(-304.341 -128.909)" opacity="0.1"></path>
                                <g id="Group_5" data-name="Group 5" transform="translate(77.984 59.296)" opacity="0.8">
                                    <ellipse id="Ellipse_10" data-name="Ellipse 10" cx="170.059" cy="44.447" rx="170.059" ry="44.447" fill="url(#linear-gradient)"></ellipse>
                                </g>
                                <path id="Path_8" data-name="Path 8" d="M490.035,192.4c-90.823,0-164.455,19.241-164.455,42.983H654.49C654.49,211.641,580.858,192.4,490.035,192.4Z" transform="translate(-241.992 -131.634)" fill="#fff"></path>
                                <path id="Path_9" data-name="Path 9" d="M490.035,310.237c90.823,0,164.455-19.241,164.455-42.977H325.58C325.58,291,399.212,310.237,490.035,310.237Z" transform="translate(-241.992 -163.512)" fill="#fff"></path>
                                <path id="Path_10" data-name="Path 10" d="M490.035,310.237c90.823,0,164.455-19.241,164.455-42.977H325.58C325.58,291,399.212,310.237,490.035,310.237Z" transform="translate(-241.992 -163.512)" opacity="0.05" fill="url(#linear-gradient-5)"></path>
                                <rect id="Rectangle_1" data-name="Rectangle 1" width="156.979" height="22.427" rx="11.214" transform="translate(169.554 92.529)" opacity="0.05"></rect>
                                <path id="Path_11" data-name="Path 11" d="M308.657,141.481a2.113,2.113,0,0,1-1.177-2.549,1.068,1.068,0,0,0,.046-.235h0a1.056,1.056,0,0,0-1.9-.7h0a1.043,1.043,0,0,0-.115.207,2.107,2.107,0,0,1-2.549,1.177,1.149,1.149,0,0,0-.235-.046h0a1.056,1.056,0,0,0-.7,1.9h0a.931.931,0,0,0,.207.121,2.113,2.113,0,0,1,1.177,2.549,1.087,1.087,0,0,0-.046.23h0a1.056,1.056,0,0,0,1.9.706h0a.948.948,0,0,0,.115-.212,2.107,2.107,0,0,1,2.549-1.148,1.149,1.149,0,0,0,.235.046h0a1.056,1.056,0,0,0,.7-1.9h0A.932.932,0,0,0,308.657,141.481Z" transform="translate(-231.781 -108.287)" fill="#fff" opacity="0.5"></path>
                                <path id="Path_12" data-name="Path 12" d="M817.679,447.481a2.113,2.113,0,0,1-1.148-2.549,1.063,1.063,0,0,0,.046-.235h0a1.057,1.057,0,0,0-1.9-.7h0a1.045,1.045,0,0,0-.115.207,2.107,2.107,0,0,1-2.549,1.177,1.146,1.146,0,0,0-.235-.046h0a1.056,1.056,0,0,0-.7,1.9h0a.933.933,0,0,0,.207.121,2.113,2.113,0,0,1,1.148,2.549,1.088,1.088,0,0,0-.046.23h0a1.056,1.056,0,0,0,1.9.706h0a.944.944,0,0,0,.115-.212,2.107,2.107,0,0,1,2.549-1.148,1.147,1.147,0,0,0,.235.046h0a1.056,1.056,0,0,0,.7-1.9h0A.928.928,0,0,0,817.679,447.481Z" transform="translate(-448.547 -238.59)" fill="#fff" opacity="0.5"></path>
                                <path id="Path_13" data-name="Path 13" d="M255.657,589.481a2.113,2.113,0,0,1-1.177-2.549,1.069,1.069,0,0,0,.046-.235h0a1.057,1.057,0,0,0-1.9-.7h0a1.044,1.044,0,0,0-.115.207,2.107,2.107,0,0,1-2.549,1.177,1.145,1.145,0,0,0-.235-.046h0a1.056,1.056,0,0,0-.7,1.9h0a.929.929,0,0,0,.207.121,2.113,2.113,0,0,1,1.177,2.549,1.084,1.084,0,0,0-.046.23h0a1.056,1.056,0,0,0,1.9.706h0a.947.947,0,0,0,.115-.212,2.107,2.107,0,0,1,2.549-1.148,1.145,1.145,0,0,0,.235.046h0a1.056,1.056,0,0,0,.7-1.9h0A.932.932,0,0,0,255.657,589.481Z" transform="translate(-209.212 -299.057)" fill="#fff" opacity="0.5"></path>
                                <path id="Path_14" data-name="Path 14" d="M200.678,258.481a2.113,2.113,0,0,1-1.148-2.549,1.068,1.068,0,0,0,.046-.235h0a1.056,1.056,0,0,0-1.9-.7h0a1.045,1.045,0,0,0-.115.207,2.107,2.107,0,0,1-2.549,1.177,1.15,1.15,0,0,0-.235-.046h0a1.056,1.056,0,0,0-.7,1.9h0a.929.929,0,0,0,.207.121,2.113,2.113,0,0,1,1.177,2.549,1.085,1.085,0,0,0-.046.23h0a1.056,1.056,0,0,0,1.9.706h0a.947.947,0,0,0,.115-.212,2.107,2.107,0,0,1,2.549-1.148,1.152,1.152,0,0,0,.235.046h0a1.056,1.056,0,0,0,.7-1.9h0A.931.931,0,0,0,200.678,258.481Z" transform="translate(-185.813 -158.109)" fill="#fff" opacity="0.5"></path>
                                <path id="Path_15" data-name="Path 15" d="M807.568,90.481a2.113,2.113,0,0,1-1.148-2.549,1.073,1.073,0,0,0,.046-.235h0a1.056,1.056,0,0,0-1.9-.7h0a1.046,1.046,0,0,0-.115.207,2.107,2.107,0,0,1-2.549,1.148,1.149,1.149,0,0,0-.235-.046h0a1.056,1.056,0,0,0-.729,1.958h0a.927.927,0,0,0,.207.121,2.113,2.113,0,0,1,1.148,2.549,1.083,1.083,0,0,0-.046.23h0a1.056,1.056,0,0,0,1.941.678h0a.945.945,0,0,0,.115-.212,2.107,2.107,0,0,1,2.549-1.148,1.148,1.148,0,0,0,.235.046h0a1.056,1.056,0,0,0,.7-1.9h0a.933.933,0,0,0-.218-.144Z" transform="translate(-444.179 -86.57)" fill="#fff" opacity="0.5"></path>
                                <g id="Group_6" data-name="Group 6" transform="translate(59.14 148.052)" opacity="0.5">
                                    <rect id="Rectangle_2" data-name="Rectangle 2" width="1.723" height="9.761" transform="translate(4.019)" fill="#fff"></rect>
                                    <rect id="Rectangle_3" data-name="Rectangle 3" width="1.723" height="9.761" transform="translate(9.761 4.019) rotate(90)" fill="#fff"></rect>
                                </g>
                                <g id="Group_7" data-name="Group 7" transform="translate(472.546 102.118)" opacity="0.5">
                                    <rect id="Rectangle_4" data-name="Rectangle 4" width="1.723" height="9.761" transform="translate(4.019)" fill="#fff"></rect>
                                    <rect id="Rectangle_5" data-name="Rectangle 5" width="1.723" height="9.761" transform="translate(9.761 4.019) rotate(90)" fill="#fff"></rect>
                                </g>
                                <g id="Group_8" data-name="Group 8" transform="translate(415.128 262.312)" opacity="0.5">
                                    <rect id="Rectangle_6" data-name="Rectangle 6" width="1.723" height="9.761" transform="translate(4.019)" fill="#fff"></rect>
                                    <rect id="Rectangle_7" data-name="Rectangle 7" width="1.723" height="9.761" transform="translate(9.761 4.019) rotate(90)" fill="#fff"></rect>
                                </g>
                                <circle id="Ellipse_11" data-name="Ellipse 11" cx="3.445" cy="3.445" r="3.445" transform="translate(411.683 43.552)" fill="#fff" opacity="0.5"></circle>
                                <circle id="Ellipse_12" data-name="Ellipse 12" cx="3.445" cy="3.445" r="3.445" transform="translate(0 3.934)" fill="#fff" opacity="0.5"></circle>
                                <circle id="Ellipse_13" data-name="Ellipse 13" cx="3.445" cy="3.445" r="3.445" transform="translate(103.351 202.024)" fill="#fff" opacity="0.5"></circle>
                                <ellipse id="Ellipse_14" data-name="Ellipse 14" cx="19.522" cy="4.019" rx="19.522" ry="4.019" transform="translate(114.835 84.651)" opacity="0.05"></ellipse>
                                <ellipse id="Ellipse_15" data-name="Ellipse 15" cx="19.522" cy="4.019" rx="19.522" ry="4.019" transform="translate(114.835 115.082)" opacity="0.05"></ellipse>
                                <ellipse id="Ellipse_16" data-name="Ellipse 16" cx="19.522" cy="4.019" rx="19.522" ry="4.019" transform="translate(337.04 84.651)" opacity="0.05"></ellipse>
                                <ellipse id="Ellipse_17" data-name="Ellipse 17" cx="19.522" cy="4.019" rx="19.522" ry="4.019" transform="translate(337.04 115.082)" opacity="0.05"></ellipse>
                                <ellipse id="Ellipse_18" data-name="Ellipse 18" cx="19.522" cy="4.019" rx="19.522" ry="4.019" transform="translate(228.521 67.426)" opacity="0.05"></ellipse>
                                <ellipse id="Ellipse_19" data-name="Ellipse 19" cx="19.522" cy="4.019" rx="19.522" ry="4.019" transform="translate(242.876 25.511)" opacity="0.05"></ellipse>
                                <ellipse id="Ellipse_20" data-name="Ellipse 20" cx="19.522" cy="4.019" rx="19.522" ry="4.019" transform="translate(205.554 42.736)" opacity="0.05"></ellipse>
                                <ellipse id="Ellipse_21" data-name="Ellipse 21" cx="19.522" cy="4.019" rx="19.522" ry="4.019" transform="translate(277.326 50.201)" opacity="0.05"></ellipse>
                                <ellipse id="Ellipse_22" data-name="Ellipse 22" cx="19.522" cy="4.019" rx="19.522" ry="4.019" transform="translate(228.521 127.14)" opacity="0.05"></ellipse>
                            </g>
                        </svg>
                    </div>
                    <div className="px-10"></div>
                    <div className="w-1/2 flex flex-col justify-center">
                        <div className="text-6xl font-bold text-left py-4">404</div>
                        <div className="text-xl font-medium text-left pb-2">Oops. This page has gone missing.</div>
                        <div className="text-base text-left">You may have mistyped the address or the page may have moved.</div>
                        <div className="py-8 flex justify-start">
                            <Link type="button" to={'/home'} className="rounded border px-4 py-2 border-black bg-transparent inline-block hover:bg-white hover:text-custom-primary">Back To Home</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound