import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { debounce, capitalizeFirstLetter, showNotify, loadScript, emit } from '../context/socket'
import { LoremIpsum, Avatar, fullname, username } from "react-lorem-ipsum";
import Pagination from './Pagination';
import Switch from './Switch';
import AHrefJavascript from './AHrefJavascript';
import $ from 'jquery';
import moment from 'moment';
const Promo = ({ setPath }) => {
    const [promo, setPromo] = useState([])
    const [pageSize, setPageSize] = useState(10)
    const [currentPage, setCurrentPage] = useState({
        number: 0,
        time: new Date().getTime()
    })
    const [docsCounts, setDocsCounts] = useState(0)
    const [filter, setFilter] = useState('createdAt')
    const [type, setType] = useState(-1)
    const [text, setText] = useState('')
    const [isAvailable, setIsAvailable] = useState(true)
    useEffect(() => {
        const getPromos = async () => {
            var reqData = {
                'text': text,
                'page': currentPage.number,
                'size': pageSize,
                'isAvailable': isAvailable,
                'filter': filter,
                'type': parseInt(type.toString()),
            }
            emit('get_list_promo', reqData, (response) => {
                if (response.response < 200 || response.response >= 300) return showNotify(response)
                setPromo(response.data.docs);
                setDocsCounts(response.data.counts)
            });
        }

        if (currentPage.number != 0)
            getPromos()
    }, [currentPage])
    useEffect(() => {
        setCurrentPage({ number: 1 })
    }, [pageSize, text, filter, type, isAvailable])
    useEffect(() => {
        setPath({ title: 'Promo / Voucher', path: '/promo' });
        loadScript();
    }, [])
    const toggle = (data) => {
        var reqData = { idPromo: data._id, status: data.status == 'active' ? 'inactive' : 'active' };
        emit('set_status_promo', reqData, (res) => {
            if (res.status != 'error') setPromo(promo.map(item =>
                item._id === data._id
                    ? { ...item, status: item.status == 'active' ? 'inactive' : 'active' }
                    : item))
            else alert(res.msg)
        })

    }
    const search = (data) => {
        console.log(data.target.value)
        debounce(() => setText(data.target.value), 2000)
    }
    const getType = (data) => {
        var label = '';
        switch (data.type) {
            case 'fixed':
                label = `${capitalizeFirstLetter(data.type)} (Rp. ${data.value.toLocaleString()})`
                break;
            case 'percentage':
                label = `${capitalizeFirstLetter(data.type)} (${data.value}%)`
                break;
            case 'failed':
                label = <label className="text-red-500">Dibatalkan</label>
                break;
            default:
                break;
        }
        return label;
    }
    const sendNotify = (e,item)=>{
        e.preventDefault();
        $('#custom-modal').find('#submitDialog').off('click')
        $('#custom-modal').find('#submitDialog').on('click',()=>{
            console.log('Kirimmm',item)
            emit('send_notify_promo',{idPromo:item._id},(response)=>{
                showNotify(response)
                if (response.response < 200 || response.response >= 300) return;
                $('#custom-modal').find('#closeDialog').trigger('click')
            })
        })
        $('#custom-modal').find('#title').html('Notify Promo')
        $('#custom-modal').find('#subtitle').html('Anda Yakin ingin mengirimkan notifikasi tentang promo '+item.code+' ini ke semua user?')
        document.getElementById('toggle-custom-modal').click();

    }
    return (
        <div className="intro-y">
            {/* <!-- END: Top Bar --> */}
            <h2 className="intro-y text-lg font-medium mt-10">
                Promo
            </h2>
            <div className="grid grid-cols-12 gap-6 mt-5">
                <div className="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2 justify-between">
                    <Link to="/promo/create">
                        <button className="btn btn-primary shadow-md mr-2">Tambah Promo</button>
                    </Link>
                    <div className="intro-y pr-1">
                        <div className="boxed-tabs nav nav-tabs justify-center bg-theme-8 bg-opacity-50 text-gray-600 dark:bg-dark-2 dark:text-gray-500 rounded-md p-1 mx-auto" role="tablist">
                            <AHrefJavascript onClick={() => setIsAvailable(true)} data-toggle="tab" data-target="#active-users" href="#!" className="btn flex-1 w-80 border-0 shadow-none py-1.5 px-2 active" id="active-users-tab" role="tab" aria-controls="active-users" aria-selected="true">Berlangsung</AHrefJavascript>
                            <AHrefJavascript onClick={() => setIsAvailable(false)} data-toggle="tab" data-target="#inactive-users" href="#!" className="btn flex-1 border-0 shadow-none py-1.5 px-2" id="inactive-users-tab" role="tab" aria-selected="false">Expired</AHrefJavascript>
                        </div>
                    </div>
                    <div className="w-full sm:w-auto flex mt-4 sm:mt-0">
                        <div onChange={(e) => setFilter(e.target.value)} className="dropdown mr-2 w-56 relative text-gray-700 dark:text-gray-300">
                            <select className="tom-select sm:mr-2" defaultValue={filter} placeholder="Urut Berdasarkan">
                                <option value={'konsultasi'}>Konsultasi</option>
                                <option value={'endDate'}>Tanggal Berakhir</option>
                                <option value={'createdAt'}>Tanggal Dibuat</option>
                            </select>
                        </div>
                        <div onChange={(e) => setType(e.target.value)} className="dropdown mr-2 relative text-gray-700 dark:text-gray-300">
                            <select className="tom-select sm:mr-2" defaultValue={type} placeholder="Urutan">
                                <option value={1}>A to Z</option>
                                <option value={-1}>Z to A</option>
                            </select>
                        </div>
                        <div className="w-56 relative text-gray-700 dark:text-gray-300">
                            <input type="text" onChange={search} className="form-control w-56 box pr-10 placeholder-theme-13" placeholder="Cari Kode Promo" />
                            <i className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0" data-feather="search"></i>
                        </div>
                    </div>
                </div>
                {/* <!-- BEGIN: Data List --> */}
            </div>
            <div className='grid grid-cols-12 gap-6 mt-5' style={{ gridAutoRow: '1fr' }}>
                {promo.map((d, i) => {
                    i *= 1
                    return (
                        <Link to={'/promo/edit/' + d._id} key={i} className="h-full intro-y col-span-12 md:col-span-6 xl:col-span-4">
                            <div className="box h-full">
                                <div className="flex flex-col lg:flex-row items-center border-b-2 border-gray-500 dark:border-dark-5 px-3">
                                    <div className="lg:mr-auto text-center lg:text-left">
                                        <div className="text-gray-800 font-bold text-xs mt-0.5">{d.title}</div>
                                        <div className="font-medium">{d.code}</div>
                                    </div>
                                    <div className="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
                                    </div>
                                    <div className="lg:ml-2 lg:mr-auto text-center lg:text-left">
                                    </div>
                                    <div className="flex justify-end">
                                        <div onClick={(e) => { e.preventDefault(); toggle(d) }} className="flex justify-center items-center">
                                            <Switch
                                                isOn={d.status == 'active'}
                                                onColor="#1be708"
                                                data={d}
                                                handleToggle={() => { }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='p-3'>
                                    <div className="flex items-center w-full">
                                        <div className="">
                                            <div className="flex text-left">
                                                <div href="" className="mr-1 font-medium">Used : </div>
                                                <div className="text-gray-600 text-xs mt-0.5">{d.users.length}</div>
                                            </div>
                                        </div>
                                        <div className="mx-auto">
                                            <div className="flex lg:text-left">
                                                <div href="" className="mr-1 font-medium">Type : </div>
                                                <div className="text-gray-600 text-xs mt-0.5">{getType(d)}</div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="flex text-left">
                                                <div href="" className="mr-1 font-medium">End : </div>
                                                <div className="text-gray-600 text-xs mt-0.5">{moment(new Date(d.endDate)).format('L')}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex pt-3 flex-nowrap items-left">
                                        <p>{d.description}
                                            {/* <LoremIpsum
                                            avgWordsPerSentence={4}
                                            avgSentencesPerParagraph={5}
                                            startWithLoremIpsum={false}
                                        /> */}
                                        </p>
                                    </div>
                                    <div className='flex justify-end'>
                                        <button onClick={(e)=>sendNotify(e,d)}>
                                            <span className="material-icons text-red">
                                                email
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    )
                })}
                {/* <!-- BEGIN: Pagination --> */}
                <div className="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
                    <Pagination
                        className="pagination-bar"
                        currentPage={currentPage.number}
                        totalCount={docsCounts}
                        pageSize={pageSize}
                        onPageChange={page => setCurrentPage({ number: page })}
                    />
                    <div className='w-full'></div>
                    <select defaultValue={10} onChange={(e) => setPageSize(parseInt(e.target.value))} style={{ width: '100px' }} className="form-select box mt-3 sm:mt-0">
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={35}>35</option>
                        <option value={50}>50</option>
                    </select>
                </div>
                {/* <!-- END: Pagination --> */}
            </div>
        </div>
    )
}

export default Promo

